import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from 'react-redux'
// import { addEmployee } from "../../features/employee/employeeSlice";

import { db, auth, analytics } from '../../services/firebase';
import { query, collection, getDocs, where, writeBatch, doc, setDoc, arrayUnion, Timestamp, orderBy } from "firebase/firestore";

import { checkEditReviewDate, classNames, getColorByPoint, getEmployeeReviewBg, getFullMonth, getMonthAddMonth, getSorttEmployeeByTeam, getTitleByPoint, getYearAddMonth, sumArray, writeExcel } from "../../helpers";
// import { Dialog, Transition } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/outline";

import NoData from "../../components/NoData"

// import axios from "axios";
// import ReviewDetail from "./ReviewDetail";
// import ReviewOne from "./ReviewOne";
import { getApproveReviewObj } from "../../app/services";
import { logEvent } from "firebase/analytics";

const ReviewApproved = () => {
    // const reviewDefines = useSelector((state) => state.config.reviewDefines);
    const calledOnce = useRef(false);
    const appConfigs = useSelector(state => state.config).appConfigs;

    let params = useParams();
    const [employies, setEmployies] = useState(null);
    const [employiesReview, setEmployiesReview] = useState([]);

    // const [employeeReview, setEmployeeReview] = useState({});
    // const [employeeData, setEmployeeData] = useState({});
    // const [teams, setTeams] = useState([]);
    // const [users, setUsers] = useState([]);

    // const [showImport, setShowImport] = useState(false);

    const [loading, error] = useAuthState(auth);
    const user = useSelector((state) => state.user);

    // const [name, setName] = useState("");

    // const [open, setOpen] = useState(false);

    // const [selectedFile, setSelectedFile] = useState("");
    const [departments, setDepartments] = useState([]);

    // const [searchTeam, setSearchTeam] = useState(-1);

    // const cancelButtonRef = useRef(null);

    const navigate = useNavigate();

    // const [mnv, setMNV] = useState("");
    // const [tenNV, setTenNV] = useState("");

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));

    const teamReducer = useSelector((state) => state.team);

    useEffect(() => {
        // console.log('ok', calledOnce);
        if (calledOnce.current) {
            return;
        }
        if (user && user.hpUser && user.email && user.hpUser.Role) {
            // console.log('ok2', user);
            // console.log('I run only once if toggle is false.');
            calledOnce.current = true;
            searchEmployee();
        }
    }, [user]);


    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }
    const getQuery = (isGetMore) => {
        if (!user.hpUser || !user.hpUser.Role) return null;

        // let q = query(collection(db, "hp-employee"));

        let q = query(collection(db, "hp-employee"), where("Level" + params.level, "==", user.hpUser.EmployeeID));


        q = query(q, where("Status", "==", 1));

        if (isGetMore && employies.length > 0) {
            console.log(employies[employies.length - 1]);
            q = query(q, where("EmployeeID", ">", employies[employies.length - 1].EmployeeID));
        }
        q = query(q, orderBy("WorkPlace"));
        return q;
    }
    const fetchEmployee = async (isGetMore) => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = getQuery(isGetMore);
            // console.log('fetch employee q: ', q);
            if (!q) return;

            const docs = await getDocs(q);
            docs.forEach((doc) => {
                setEmployies((employies) => [...employies, doc.data()]);
                handleSearchReview(doc.data().EmployeeID);
                // console.log(doc.id, doc.data());
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        // setEmployies([]);
        // fetchEmployee();

        // fetchTeams();
        // fetchUsers();
        // searchEmployee();
    }, [user, loading]);

    const findDep = (depName) => {
        let dep = departments.find(d => d.ShortName === depName);
        // console.log(dep);
        if (dep) return dep.id;
        return "";
    }

    const searchEmployee = () => {
        setEmployies([]);
        setEmployiesReview([]);
        fetchEmployee(false);
    }

    const handleSearchReview = async (maNhanVien) => {
        try {
            // console.log(maNhanVien, getFullMonth(month, year));
            const q = query(collection(db, "hp-review"),
                where("EmployeeID", "==", maNhanVien),
                where("FullMonth", "==", getFullMonth(month, year))
            );
            // console.log(q);

            const querySnapshot = await getDocs(q);
            if (querySnapshot.size == 0) {
                createReview(maNhanVien);
            } else {
                querySnapshot.forEach((doc) => {
                    let empReview = Object.assign({ uid: doc.id }, doc.data());
                    // console.log(empReview);
                    setEmployiesReview((employiesReview) => [...employiesReview, empReview]);
                    return;
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const createReview = async (maNhanVien) => {
        console.log('crate');
        // if (!maNhanVien || parseInt(maNhanVien) <= 0) return;
        if (!maNhanVien) return;
        const empRef = doc(db, "hp-review", getFullMonth(month, year) + "-" + maNhanVien + "-001");
        await setDoc(empRef, {
            EmployeeID: maNhanVien,
            FullMonth: getFullMonth(month, year),
            Level1Point: 0,
            Level1Approved: false,
            Level2Point: 0,
            Level2Approved: false,
            Month: month.value,
            Year: year.value,
            Points1: [],
            Points2: [],
        })
        // alert("Cập nhật dữ liệu thành công");
        handleSearchReview(maNhanVien);
    }

    const getEmployeeReview = (employee) => {
        return employiesReview.find(f => f.EmployeeID == employee.EmployeeID);
    }

    const getPoint = (employee, level, group) => {
        try {
            let empReview = getEmployeeReview(employee);
            if (!empReview) return "";
            // console.log(employee, "Points" + level, group, empReview);
            if (!group) return empReview["Level" + level + "Point"];
            return sumArray(empReview["Points" + level].filter(w => w.Group == group), "Point");
        }
        catch (e) {
            console.log(e);
            return "";
        }
    }

    const alert2Manager = (empList) => {
        // if (params.level == 1) {
        // tao thong bao cho quan ly
        // ...
        // ...
        // cap duoi bao cap tren, cap tren bao cho cap duoi
        let managersID = [...new Set(empList.map(m => m["Level" + (params.level == 1 ? 2 : 1)]))].filter(f => f !== user.uid && f != "");
        // let managers = employies
        console.log(managersID);
        if (!managersID || managersID.length == 0) return;

        const batch = writeBatch(db);

        var batPro = new Promise((resolve, reject) => {
            managersID.forEach((mID, index) => {
                let userRef = doc(db, "hp-user", mID);

                // neu level1 duyet xong thi level vao duyet
                let actionLink = "/app/reports/review/month";
                // let message = 
                if (params.level == 1) {
                    // neu level2 duyet xong thi level 1 vao xem bao cao
                    actionLink = '/app/reviews/multi/2';// + params.level;
                }
                batch.update(userRef, {
                    noties: arrayUnion({
                        CreatedAt: Timestamp.fromDate(new Date()),
                        Message: `${user.hpUser.FirstName} ${user.hpUser.LastName}  đã duyệt xong đánh giá tháng ${month.value} năm ${year.value}. Vui lòng kiểm tra`,
                        ActionLink: actionLink,
                        ActionType: "duyet",
                        Status: 1
                    })
                });
                if (index === managersID.length - 1) resolve();
            });
        });

        batPro.then(async () => {
            // Commit the batch
            await batch.commit();

            alert("Gửi thông báo thành công thành công");

            window.location.reload(false);
        });
        // }
    }

    const approveAll = () => {
        approveList(employies, employiesReview);
    }

    const exportExcel = () => {
        writeExcel("danh-gia-thang", `danh-gia-thang-${month.value}-${year.value}`);
    }

    const approveGroup = (event, empList) => {
        event.preventDefault();
        // let empList = employies.filter(f => f.TeamID === team.uid);
        let empListID = empList.map(m => m.EmployeeID);
        let empReviewsList = employiesReview.filter(f => empListID.includes(f.EmployeeID));
        // console.log(empList, empListID, employiesReview);
        approveList(empList, empReviewsList);
    }

    const approveList = (empList, empReviewsList) => {
        // console.log(empList, empReviewsList)
        if (!empList || empList.length == 0 || !empReviewsList || empReviewsList.length == 0) return;
        if (empList.length != empReviewsList.length) {
            alert("Có " + (empList.length - empReviewsList.length) + " nhân viên chưa đánh giá. Vui lòng đánh giá trước khi duyệt");
            return;
        }
        if (params.level == 2) {
            let unApproveds = empReviewsList.filter(f => f.Level1Approved == false);
            if (unApproveds.length > 0) {
                alert("Có " + (unApproveds.length) + " nhân viên chưa duyệt cấp 1. Vui lòng duyệt sau khi cấp 1 đã duyệt hết duyệt");
                return;
            }
        }
        let unApproved = empReviewsList.filter(emp =>
            emp[`Level${params.level}Approved`] == false
        );
        logEvent(analytics, 'ReviewApproved', {
            unApproved: unApproved.length,
            empReviewsList: empReviewsList.length,
            empList: empList.length,
            user: user.email,
        });

        if (unApproved.length == 0) {
            // if (params.level == 2) {
            //     alert("Bạn đã duyệt hết nhân viên");
            //     return;
            // }
            if (window.confirm(`Bạn đã duyệt hết (${empList.length}) nhân viên. Bạn muốn báo cho quản lý cấp ${params.level == 2 ? 'dưới' : 'trên'}?`)) {
                alert2Manager(empList);
            }
        } else {
            if (window.confirm("Bạn muốn duyệt " + unApproved.length + '/' + empList.length + " nhân viên?")) {

                const chunkSize = 200;
                let doAlertManager = false;
                if (window.confirm(`Bạn muốn báo cho quản lý cấp ${params.level == 2 ? 'dưới' : 'trên'}?`)) {
                    doAlertManager = true;
                }

                let count = 0;
                for (let i = 0; i < unApproved.length; i += chunkSize) {
                    const chunk = unApproved.slice(i, i + chunkSize);
                    count += chunk.size;
                    console.log("chunk", chunk.length, i);

                    const batch = writeBatch(db);

                    let batPro = new Promise((resolve, reject) => {
                        chunk.forEach((empReview, index) => {
                            let empRef = doc(db, "hp-review", empReview.uid);
                            let upData = getApproveReviewObj(user, empReview, params.level);
                            // upData[`Level${params.level}Approved`] = true;
                            batch.update(empRef, upData);
                            if (index === chunk.length - 1) resolve();
                        });
                    });

                    batPro.then(async () => {
                        // Commit the batch
                        await batch.commit();
                        // alert("Duyệt thành công");
                        // if (count == unApproved.size) {
                        //     if (doAlertManager) {
                        //         alert2Manager(empList);
                        //     }
                        //     window.location.reload(false);
                        // }
                    });
                }
                alert2Manager(empList);
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            }
        }
    }

    return <div className="py-6">
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
        </div> */}
        <div className="max-w-7xl mx-auto  min-w-full w-full">
            {/* Replace with your content */}
            {/* <div className="bg-white shadow sm:rounded-lg ">
                <div className="px-4 py-5 sm:p-6">
                    <div className="mt-8 flex flex-col"> */}
            {/* <div className="bg-white px-4 py-5 sm:px-6"> */}
            {/* <div className="-ml-4 -mt-2 flex flex-wrap sm:flex-nowrap">
                            <div className="w-full sm:max-w-xs pr-4 px-4">
                                <label htmlFor="email" className="sr-only">
                                    Tháng
                                </label>
                                <select
                                    id="location"
                                    name="location"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                    value={month.value + ""}
                                    onChange={changeMonth}
                                >
                                    {getMonths().map(m => {
                                        return <option key={m.value} value={m.value}>
                                            {m.label}
                                        </option>
                                    })}
                                </select>
                            </div>
                            <div className="w-full sm:max-w-xs px-4 pr-4">
                                <label htmlFor="email" className="sr-only">
                                    Năm
                                </label>
                                <select
                                    id="location"
                                    name="location"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                    value={year.value}
                                    onChange={changeYear}
                                >
                                    {getYears().map(y => { return <option value={y.value} key={y.value}>{y.label}</option> })}
                                </select>
                            </div>
                            <div className="w-full sm:max-w-xs px-4 pr-4">
                                <label htmlFor="email" className="sr-only">
                                    Năm
                                </label>
                                <button
                                    type="button" onClick={searchEmployee}
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Tìm
                                </button>
                            </div>
                        </div> */}
            {/* </div> */}
            {/* </div>
                </div>
            </div> */}
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        {employies && employies.length > 0 && <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg py-4 bg-white">
                            <table className="min-w-full" id="danh-gia-thang">

                                <thead className="bg-white">
                                    <tr>
                                        <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Mã NV
                                        </th>
                                        <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Họ
                                        </th>
                                        <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tên
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                                        <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Chức danh
                                        </th>
                                        <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Nơi công tác
                                        </th>
                                        <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Đội/ Cụm
                                        </th>
                                        <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Quản lý trực tiếp
                                        </th>
                                        {params.level == 2 && <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Quản lý cao hơn
                                        </th>}
                                        {/* <th rowSpan={2} scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Đánh giá</span>
                                        </th> */}
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Hoàn thành công việc
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Thái độ nghiêm túc
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Thái độ tích cực
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tổng điểm
                                        </th>
                                        {params.level == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Hoàn thành công việc
                                        </th>}
                                        {params.level == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Thái độ nghiêm túc
                                        </th>}
                                        {params.level == 2 &&
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Thái độ tích cực
                                            </th>}
                                        {params.level == 2 &&
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Tổng điểm
                                            </th>}
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {getSorttEmployeeByTeam(getTeams(), employies).map((team) => (
                                        <Fragment key={team.Code}>
                                            <tr className="border-t border-gray-200">
                                                <th
                                                    colSpan={params.level == 2 ? 10 : 6}
                                                    scope="colgroup"
                                                    className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                                >
                                                    {team.Name}
                                                </th>
                                                <th colSpan={2}>
                                                    {checkEditReviewDate(appConfigs) &&
                                                        <a href="!#"
                                                            disabled={team.List.length != team.List.length}
                                                            type="button" onClick={(event) => approveGroup(event, team.List)}
                                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                                        >
                                                            Duyệt nhóm
                                                        </a>
                                                    }
                                                </th>
                                            </tr>
                                            {team.List.map((employee, idx) => (
                                                <tr
                                                    key={idx}
                                                    className={classNames(
                                                        idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {employee.EmployeeID}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {employee.FirstName}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Area}</td>
                                                    <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 1, "hoanthanh")}</td>
                                                    <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 1, "nghiemtuc")}</td>
                                                    <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 1, "tichcuc")}</td>
                                                    <td title={getTitleByPoint(getPoint(employee, 1, ""))} className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), getColorByPoint(getPoint(employee, 1, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 1, "")}</td>
                                                    {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 2, "hoanthanh")}</td>
                                                    }
                                                    {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 2, "nghiemtuc")}</td>
                                                    }
                                                    {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPoint(employee, 2, "tichcuc")}</td>
                                                    }
                                                    {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), getColorByPoint(getPoint(employee, 2, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 2, "")}</td>
                                                    }
                                                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> */}

                                                    {/* <a href="#" onClick={(event) => reviewEmployee(event, employee, employeeReview)}
                                                            className="text-indigo-600 hover:text-indigo-900">
                                                            Chi tiết<span className="sr-only">, {employee.Name}</span>
                                                        </a> */}
                                                    {/* </td> */}
                                                </tr>
                                            ))}
                                        </Fragment>
                                    ))}

                                </tbody>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}></td>
                                        <td>Tổng</td>
                                        <td>Chưa duyệt</td>
                                        <td>{employies.length - sumArray(employiesReview, "Level1Approved")}</td>
                                        <td>Đã duyệt</td>
                                        <td>{sumArray(employiesReview, "Level1Approved")}</td>
                                        {params.level == 2 && <><td>Chưa duyệt</td>
                                            <td>{employies.length - sumArray(employiesReview, "Level2Approved")}</td>
                                            <td>Đã duyệt</td>
                                            <td>{sumArray(employiesReview, "Level2Approved")}</td>
                                        </>}
                                    </tr>
                                </tbody>
                            </table>
                            <div className="w-full sm:max-w-xs py-4 px-4">
                                {checkEditReviewDate(appConfigs) &&
                                    <button
                                        disabled={employies.length != employiesReview.length}
                                        type="button" onClick={approveAll}
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                    >
                                        Duyệt tất cả
                                    </button>
                                }
                                <button
                                    disabled={employies.length != employiesReview.length}
                                    type="button" onClick={exportExcel}
                                    className="inline-flex items-center mx-4 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Xuất Excel
                                </button>
                            </div>
                        </div>
                        }
                        {employies && employies.length == 0 && <NoData />}
                    </div>
                </div>
            </div>
            {/* /End replace */}
        </div>
    </div >;
};
export default ReviewApproved;