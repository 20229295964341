import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { utils, writeFileXLSX } from 'xlsx';
import sha256 from 'crypto-js/sha256';


export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


// export function getLevel(hpUser) {
//     console.log(hpUser);
//     if (!hpUser) return 0;



//     if (!hpUser.Role || hpUser.Role.length == 0) return 0;

//     if (hpUser.Role.includes("level2")) return 2;
//     if (hpUser.Role.includes("level1")) return 1;
//     if (hpUser.Role.includes("quantri")) return 99;
//     return 0;
// }

export function hasRole(roles, currRole) {

    try {
        // console.log(roles, currRole);
        if (!roles || !currRole) return false;
        if (roles.length == 0) return;

        let res = currRole.find(role => roles.find(f => f === role) != null) != null;
        return res;
    }
    catch (e) {
        console.log(roles, currRole);
        console.log(e);
        return false;
    }
}

// export function hasRoleData(roles, currRole) {
//     // console.log(roles, currRole);
//     if (!roles || !currRole) return false;
//     return currRole.every(role => roles.includes(role));
// }

export function getUnique(array) {
    let src = [...array];
    return [...new Set(array.map(item => item.uid))].map(m => src.find(f => f.uid === m));
}

export function getDefines(reviewDefines, teamID) {
    return [
        {
            Name: "Điểm hoàn thành công việc",
            Data: reviewDefines.filter(f => f.TeamID === teamID && f.Group === "hoanthanh"),
            Group: "hoanthanh",
            TeamID: teamID,
        },
        {
            Name: "Thái độ nghiêm túc",
            Data: reviewDefines.filter(f => f.TeamID === teamID && f.Group === "nghiemtuc"),
            Group: "nghiemtuc",
            TeamID: teamID,
        },
        {
            Name: "Thái độ tích cực",
            Data: reviewDefines.filter(f => f.TeamID === teamID && f.Group === "tichcuc"),
            Group: "tichcuc",
            TeamID: teamID,
        }
    ]
}

export const getDays = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map((m) => {
        return { value: m, label: "Ngày " + m };
    });
};

export const getMonthAddMonth = (curr) => {
    if (!curr) curr = 0;

    let date = moment(new Date()).add(curr, "months");
    return {
        value: date.month() + 1,
        label: "Tháng " + (date.month() + 1)
    }
}

export const getYearAddMonth = (curr) => {
    if (!curr) curr = 0;

    let date = moment(new Date()).add(curr, "months");
    return {
        value: date.year(),
        label: "Tháng " + date.year()
    }
}


export const getMonths = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => {
        return { value: m, label: "Tháng " + (m) };
    });
};
export const getYears = () => {
    // return [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    return [-1, 0, 1, 2, 3, 4, 5]
        .map((m) => {
            return new Date().getFullYear() + m;
        })
        .map((m2) => {
            return { value: m2, label: "Năm " + m2 };
        });
};

export const getFullMonth = (month, year) => {
    return year.value + (month.value < 10 ? "0" : "") + month.value;
};

export const sumArray = (arr, field) => {
    return arr.map(m => m[field] || 0).reduce((a, b) => a + b, 0);
}


export const removeAccents = (str) => {
    var AccentsMap = [
        "aàảãáạăằẳẵắặâầẩẫấậ",
        "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
        "dđ", "DĐ",
        "eèẻẽéẹêềểễếệ",
        "EÈẺẼÉẸÊỀỂỄẾỆ",
        "iìỉĩíị",
        "IÌỈĨÍỊ",
        "oòỏõóọôồổỗốộơờởỡớợ",
        "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
        "uùủũúụưừửữứự",
        "UÙỦŨÚỤƯỪỬỮỨỰ",
        "yỳỷỹýỵ",
        "YỲỶỸÝỴ"
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
        var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
        var char = AccentsMap[i][0];
        str = str.replace(re, char);
    }
    return str;
}

export const removeAccAndLower = (str) => {
    return removeAccents(str).toLocaleLowerCase();
}

export const getNameSearch = (Ho, Ten) => {
    let res = [...removeAccAndLower(Ho + " " + Ten).split(' '),
    removeAccAndLower(Ho),
    removeAccAndLower(Ten),
    removeAccAndLower(Ho + " " + Ten)];

    let hoArry = Ho.split(' ');
    if (hoArry.length > 0) {
        res = [...res, removeAccAndLower(hoArry[hoArry.length - 1] + ' ' + Ten)];
    }

    return res;
}

export const date2DateStr = (src) => {
    return moment(src).format('DD-MM-YYYY');
}

export const date2DateTimeStr = (src) => {
    return moment(src).format('HH:mm:ss DD-MM-YYYY');
}

// export const timeStampToDateString = (src) => {
//     return timeStampToDate(src);
// }

export const getEmployeeReviewBg = (employee, level) => {
    // console.log(employee, level);
    if (!employee || !employee[`Level${level}Approved`]) return '';

    if (employee[`Level${level}Approved`] == true) return 'bg-green-100';
    return '';
}


export const getColorByPoint = (point) => {
    if (point < 75) return 'text-red-600';
    if (point < 85) return 'text-amber-600';
    if (point < 90) return 'text-yellow-600';
    if (point < 95) return 'text-lime-600';
    return 'text-green-600';
}

export const getTitleByPoint = (point) => {
    // if(!point  || )
    if (point < 75) return 'C';
    if (point < 85) return 'B-';
    if (point < 90) return 'B';
    if (point < 95) return 'B+';
    return 'A';
}


export const writeExcel = (tabelID, fileName) => {
    let dateStr = moment(new Date()).format('YYYYMMDD-HHmmss');
    writeFileXLSX(
        utils.table_to_book(document.getElementById(tabelID)),
        `${fileName}_${dateStr}.xlsx`
    );
}

// export const getChatID = (userID1, userID2) => {
//     if (userID1 > userID2) return `${userID2}-${userID1}`;
//     return `${userID1}-${userID2}`;
// }

export const obj2array = (src) => {
    if (!src) return [];
    return Object.keys(src).map((k) => Object.assign(src[k], { uid: k }));
}

export const getMSNV = (src) => {
    return src ? (src + "").trim() : "";
}

export const checkUserRoleLoad = (user) => {
    return (!user || !user.uid || !user.hpUser || !user.hpUser.Role);
}

export const checkPageRole = (hpUser, roles) => {

    // const navigate = useNavigate();

    if (hasRole(roles, hpUser.Role) == false) {
        window.location.href = "/no-permission";
    }
}

export const checkFunctionRole = (hpUser, roleCodes) => {

    // const navigate = useNavigate();

    if (hasRole(roleCodes, hpUser.Role) == false) {
        window.location.href = "/no-permission";
    }
}

export const checkEditReviewDate = (appConfigs) => {
    if (!appConfigs || !appConfigs.Review) return false;

    if (appConfigs.Review.ApprovedLimitEdit < moment(new Date()).date()) return false;

    return true;
}

export const simpleCompare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);

}

export const getSorttEmployeeByTeam = (teams, employies) => {
    return teams.map(team => {
        return {
            Name: team.Name,
            Code: team.Code,
            List: getSortEmployeeList(employies).filter(emp => emp.TeamID === team.uid)
        }
    }).filter(f => f.List.length > 0);
}


export const getSortEmployeeList = (employies) => {
    return [...employies]
        .sort((a, b) =>
                // ((a.Area || "").toLocaleLowerCase() > (b.Area || "").toLocaleLowerCase() ? 1 : -1) ||
                ((a.WorkPlace || "").toLocaleLowerCase() > (b.WorkPlace || "").toLocaleLowerCase() ? -1 : 1),
        )
        .sort((a, b) =>
            ((a.Area || "").toLocaleLowerCase() > (b.Area || "").toLocaleLowerCase() ? 1 : -1) 
            // ((a.WorkPlace || "").toLocaleLowerCase() > (b.WorkPlace || "").toLocaleLowerCase() ? -1 : 1),
        );
    // .sort((a, b) =>
    //     a.WorkPlace > b.WorkPlace ? -1 : 1,
    // );;
}

export const groupBy = (data, field) => {
    // let ar = [];
    let newarr = [];
    data.map(x => {
        // console.log(field, x, x[field]);
        if (newarr.indexOf(x[field]) === -1 && x[field] && x[field].length > 0) {
            newarr.push(x[field]);
            // ar.push(x[field]);
        }
    });
    return newarr;
}


export const hashWithLength = (str) => {
    return sha256(str).toString();//.substr(0, 10);
}

export const removeDuplicate = (data) => {
    return data.filter((f, index) => data.indexOf(f) == index);
}