export const NOTE_MAX_LENGTH = 500;
export const LIMIT_GET_DATA = 10;

export const MAX_POINT = 100;

export const RECORD_BO_API = "https://acbh-acbd.record.vn";

export const WEB_VERSION = "1.1.6";

export const DB_PREFIX = `hp_${
  process.env.DB_PREFIX ||
  process.env.DB_PREFIX_PRODUCT ||
  process.env.DB_PREFIX_SANDBOX ||
  process.env.DB_PREFIX_DEVELOP ||
  "dev"
}_`;
