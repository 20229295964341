/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { collection, doc, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import { classNames, getColorByPoint, getFullMonth, getMonths, getSorttEmployeeByTeam, getTitleByPoint, getYears, hasRole, sumArray, writeExcel } from '../../helpers';
import { db } from '../../services/firebase';


import { read, writeFileXLSX, utils } from "xlsx";

export default function ReviewYearReport() {

  const [employies, setEmployies] = useState(null);
  const [employiesReview, setEmployiesReview] = useState([]);

  const user = useSelector((state) => state.user);

  const [year, setYear] = useState({
    value: new Date().getFullYear(),
    label: "Năm " + new Date().getFullYear(),
  });

  const [isView, setIsView] = useState(false);

  const [isSearch, setIsSearch] = useState(true);



  const teamReducer = useSelector((state) => state.team);
  const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }

  const changeYear = (event) => {
    // setYear(event);
    setYear(getYears().find(f => f.value === parseInt(event.target.value)));
  };

  useEffect(() => {
    setEmployies([]);
    setEmployiesReview([]);
    // fetchEmployee(false);
    setIsSearch(false);
  }, [])

  useEffect(() => {
    if (employies && employies.length > 0) {
      fetchEmployee(true);
      // console.log('employiesReview: ', employies?.length);
      searchReviews();
    }
  }, [employies])

  const exportYear = (file) => {
    localStorage.setItem('isexport', file);
  

    setIsSearch(true);
    setEmployies([]);
    setEmployiesReview([]);
    fetchEmployee(false);

    if (!file) {
      setIsView(!file);
    }
    // if (file) {
    //   // setTimeout(() => {
    //   writeExcel("table-report-year", "report-year-" + year.value);
    // }
  }

  const getQuery = (isGetMore) => {

    let q = query(collection(db, "hp-employee"));

    if (isGetMore && employies.length > 0) {
      // console.log(employies[employies.length - 1]);
      q = query(q, where("EmployeeID", ">", employies[employies.length - 1].EmployeeID));
    }


    if (hasRole(["level1", "level2"], user.hpUser.Role)) {

      q = query(q, where("Level", "array-contains", user.hpUser.EmployeeID));
    }

    // q = query(q, limit(500));
    q = query(q, orderBy("EmployeeID"));
    return q;
  }
  const fetchEmployee = async (isGetMore) => {
    try {
      setIsSearch(true);
      // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
      const q = getQuery(isGetMore);
      // console.log('fetch employee q: ', q);

      if (!q) return;

      const docs = await getDocs(q);
      // console.log(docs.size);
      // if(doc)
      docs.forEach((doc) => {
        setEmployies((employies) => [...employies, doc.data()]);

        // tam thoi khong search o day
        // handleSearchReview(doc.data().EmployeeID);

      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSearch(false);
    }
  };

  const searchReviews = async () => {
    const chunkSize = 10;

    for (let i = 0; i < employies.length; i += chunkSize) {
      const chunk = employies.slice(i, i + chunkSize);

      // chunk.forEach(async (msnv, index, array) => {
      //   const Version = "001";
      //   const empRef = await db.collection("hp-review").doc(FullMonth + "-" + msnv + "-" + Version);
      //   batch.set(empRef, createReviewObj(msnv, FullMonth, month, year));
      //   // tao danh gia
      //   // batch.set()
      //   if (index === chunk.length - 1) resolve({ End: true });
      // });
      const maNhanViens = chunk.map(m => m.EmployeeID);
      const isDone = (chunk.length + i) === employies.length;
      // console.log(maNhanViens, isDone);
      handleSearchReviewList(maNhanViens, isDone);
    }
  };


  const handleSearchReviewList = async (maNhanViens, isDone) => {
    try {
      // console.log(maNhanVien, getFullMonth(month, year));
      const q = query(collection(db, "hp-review"),
        where("EmployeeID", "in", maNhanViens),
        where("Year", "==", year.value)
      );
      // console.log(q);

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        // createReview(maNhanVien);
      } else {
        let count = 0;
        querySnapshot.forEach((doc) => {
          let empReview = Object.assign({ uid: doc.id }, doc.data());
          let emp = employies.find(f => f.EmployeeID === empReview.EmployeeID);
          if (emp) {
            emp.YearPoint = (emp.YearPoint || 0) + (empReview.Level2Point || 0);
          }
          emp['Month' + empReview.Month] = empReview.Level2Point || 0;
          // console.log(empReview);
          // setEmployiesReview((employiesReview) => [...employiesReview, empReview]);
          count++;
          if (count === querySnapshot.size && isDone) {
            setEmployiesReview(employies);
            setTimeout(() => {
              if (localStorage.getItem('isexport') === 'true') {
                writeExcel("table-report-year", "report-year-" + year.value);
              }
              else {
                alert('Lấy dữ liệu hoàn tất');
              }
            }, 5000);
            // if (isView) {

            // }
            // console.log('done search review', employies.filter(e => e.YearPoint > 0).slice(0, 10));

          }
          return;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleSearchReview = async (maNhanVien) => {
    try {
      // console.log(maNhanVien, getFullMonth(month, year));
      const q = query(collection(db, "hp-review"),
        where("EmployeeID", "==", maNhanVien),
        where("Year", "==", year.value)
      );
      // console.log(q);

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        // createReview(maNhanVien);
      } else {
        querySnapshot.forEach((doc) => {
          let empReview = Object.assign({ uid: doc.id }, doc.data());
          // console.log(empReview);
          setEmployiesReview((employiesReview) => [...employiesReview, empReview]);
          return;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  // const getEmployeeByTeam = () => {
  //   // console.log(getTeams());
  //   return getTeams().map(team => {
  //     return {
  //       Name: team.Name,
  //       Code: team.Code,
  //       List: getEmployeeList().filter(emp => emp.TeamID === team.uid)
  //     }
  //   }).filter(f => f.List.length > 0);
  // }



  // const getEmployeeList = () => {
  //   return [...employies].sort((a, b) =>
  //     a.WorkPlace > b.WorkPlace ? -1 : 1,
  //   );;
  // }


  const getEmployeeReview = (employee, thang) => {
    return employiesReview.find(f => f.EmployeeID == employee.EmployeeID && f.Month == thang);
  }

  const getEmployeesReview = (employee) => {
    return employiesReview.filter(f => f.EmployeeID == employee.EmployeeID);
  }

  const getPoint = (employee, level, group) => {
    try {
      let empReview = getEmployeeReview(employee);
      if (!empReview) return "";
      // console.log(employee, "Points" + level, group, empReview);
      if (!group) return empReview["Level" + level + "Point"];
      return sumArray(empReview["Points" + level].filter(w => w.Group == group), "Point");
    }
    catch (e) {
      console.log(e);
      return "";
    }
  }

  const getPointMonth = (employee, thang) => {
    // return 0;
    try {
      if (thang < 0) {
        // let empsReview = getEmployeesReview(employee).map(m => m.Level2Approved ? m.Level2Point : 0);
        // if (!empsReview || empsReview.length == 0) return 0;
        // return parseInt(empsReview.reduce((a, b) => a + b, 0) / empsReview.length);
        // round get 1 number after dot 
        return employee.YearPoint ? (employee.YearPoint / 12).toFixed(1) : 0;
        // return Math.round((employee.YearPoint || 0) / 12, 1);
      }
      // let empReview = getEmployeeReview(employee, thang);
      // if (!empReview) return 0;
      // // chi lay diem duyet cap 2
      // if (!empReview.Level2Approved) return 0;

      // return empReview.Level2Point || 0;
      return employee['Month' + thang] || 0;
    }
    catch (e) {
      return 0;
    }
  }

  return (
    <div className="py-6">
      {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900"></h1>
    </div> */}
      <div className="max-w-7xl mx-auto sm:px-6 md:px-8  min-w-full w-full">
        <div className="sm:flex sm:items-center">
          {/* <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Báo cáo đánh giá nhân viên</h1> */}
          {/* <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their name, title, email and role.
                </p> */}
          {/* </div> */}
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Import nhân viên
            </button>
          </div> */}
        </div>

        <div className="mt-8 flex flex-col">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              <li>
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="w-full sm:max-w-xs pr-4">
                      <label htmlFor="email" className="sr-only">
                        Năm
                      </label>
                      <select
                        id="location"
                        name="location"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                        value={year.value}
                        onChange={changeYear}
                      >
                        {getYears().map(y => { return <option value={y.value} key={y.value}>{y.label}</option> })}
                      </select>
                    </div>
                  </div>
                  <div>
                    {/* <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    {user && user.hpUser && user.hpUser.Role &&
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                          <button onClick={() => exportYear(false)}
                            type="button" disabled={isSearch}
                            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-500"
                          >
                            Xem
                          </button>
                          <button onClick={() => exportYear(true)}
                            type="button" disabled={isSearch}
                            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-500"
                          >
                            Xuất file
                          </button>
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 flex flex-col" style={{ display: isView ? "block" : "none" }}>
          {/* <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> */}
          <table className="min-w-full" id="table-report-year">
            <thead className="bg-white">
              <tr>
                <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Mã NV
                </th>
                <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Họ
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tên
                </th>
                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Chức danh
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Nơi công tác
                </th>
                <th colSpan={12} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Tổng điểm
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Điểm bình quân
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Xếp loại
                </th>
              </tr>
              <tr>
                {getMonths().map(m => {
                  return <th key={m.value} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {m.value}
                  </th>
                })}
              </tr>
            </thead>
            <tbody className="bg-white">
              {employiesReview && employiesReview.length > 0 && getSorttEmployeeByTeam(getTeams(), employiesReview).map((team) => (
                <Fragment key={team.Code}>
                  <tr className="border-t border-gray-200">
                    <th
                      colSpan={8}
                      scope="colgroup"
                      className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                    >
                      {team.Name}
                    </th>
                  </tr>
                  {team.List.map((employee, idx) => (
                    <tr
                      key={idx + team.Code}
                      className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {employee.EmployeeID}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {employee.FirstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                      {getMonths().map(m => {
                        return <td key={m.value} className={classNames(getColorByPoint(getPointMonth(employee, m.value)), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>
                          {getPointMonth(employee, m.value)}
                        </td>
                      })}
                      <td className={classNames(getColorByPoint(getPointMonth(employee, -1)), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{getPointMonth(employee, -1)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500">{getTitleByPoint(getPointMonth(employee, -1))}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}

            </tbody>
          </table>
          {employies && employies.length == 0 && <NoData />}
        </div>
      </div>
      {/* </div>
        </div>


      </div> */}
    </div>
  )
}