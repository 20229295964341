import React from "react";

const stats = [
    { name: 'Tổng người dùng', stat: '' },
    { name: 'Tổng nhân viên', stat: '' },
    { name: 'Người dùng mới', stat: '' },
]

const Dashboard = () => {
    return <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            <h1 className="text-2xl font-semibold text-gray-900">Trang chủ  </h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">30 ngày gần nhất</h3>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                        <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            {/* /End replace */}
        </div>
    </div>;
};
export default Dashboard;