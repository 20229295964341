import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./app/store";

import Auth from "./Auth";
import NotFound from "./routes/NotFound";
import Login from "./routes/auth/Login";
import Main from "./routes/Main";

import Dashboard from "./routes/Dashboard";

import Logout from "./routes/auth/Logout";
import EmployeeList from "./routes/employies/EmployeeList";
import DepartmentList from "./routes/departments/DepartmentList";
import TeamList from "./routes/teams/TeamList";
import MainReport from "./routes/reports/MainReport";
import GeneralReviewDefine from "./routes/configs/GeneralReviewDefine";
import UserProfile from "./routes/users/UserProfile";
import ReviewDefine from "./routes/configs/ReviewDefine";

import MainReview from "./routes/reviews/MainReview";
import ReviewOne from "./routes/reviews/ReviewOne";
import ReviewMulti from "./routes/reviews/ReviewMulti";
import ReviewMonthReport from "./routes/reports/ReviewMonthReport";
import ReviewYearReport from "./routes/reports/ReviewYearReport";
import ReviewApproved from "./routes/reviews/ReviewApproved";
import MainConfig from "./routes/configs/MainConfig";
import ReviewConfig from "./routes/configs/ReviewConfig";
import UserList from "./routes/users/UserList";
import Reset from "./routes/auth/Reset";
import { UserShortInfo } from "./routes/users/UserShortInfo";
import { UserChangePassword } from "./routes/users/UserChangePassword";
import WorkPlaceList from "./routes/workplace/WorkPlaceList";
import NoData from "./components/NoData";
import NoRole from "./routes/NoRole";
import ReviewStatusReport from "./routes/reports/ReviewStatusReport";
// import MainChat from './routes/chats/MainChat';
// import LightLayout from './layouts/LightLayout';
// import your route components too

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />

          <Route path="app" element={<App />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="employee-list" element={<EmployeeList />} />
            <Route path="department-list" element={<DepartmentList />} />
            <Route path="team-list" element={<TeamList />} />
            <Route path="workplace-list" element={<WorkPlaceList />} />
            <Route path="reports/review" element={<MainReport />}>
              <Route path="month/" element={<ReviewMonthReport />} />
              <Route path="year/" element={<ReviewYearReport />} />
              <Route path="status/" element={<ReviewStatusReport />} />
            </Route>
            <Route
              path="configs/review-define"
              element={<GeneralReviewDefine />}
            >
              <Route path=":teamID" element={<ReviewDefine />} />
            </Route>
            <Route path="configs/general-config" element={<MainConfig />}>
              <Route path="review" element={<ReviewConfig />} />
            </Route>
            <Route path="reviews" element={<MainReview />}>
              <Route path="one" element={<ReviewOne />} />
              <Route path="one/:level" element={<ReviewOne />} />
              <Route path="multi" element={<ReviewMulti />} />
              <Route path="multi/:level" element={<ReviewMulti />} />
              <Route path="approved" element={<ReviewApproved />} />
              <Route path="approved/:level" element={<ReviewApproved />} />
            </Route>
            <Route path="user" element={<UserProfile />}>
              <Route path="profile" element={<UserShortInfo />} />
              <Route path="password" element={<UserChangePassword />} />
            </Route>
            <Route path="user-list" element={<UserList />} />
            {/* <Route path="chats" element={<MainChat />} /> */}
          </Route>

          <Route path="auth" element={<Auth />}>
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="reset" element={<Reset />} />
          </Route>

          <Route path="no-permission" element={<NoRole />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
