import { doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { checkPageRole, checkUserRoleLoad } from "../../../helpers";
import { getStatusName } from "../../../helpers/uiHelper";
import { db } from "../../../services/firebase";

export const UserSecurity = ({ userInfo, reloadData }) => {

    const user = useSelector((state) => state.user);
    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["quantri", "nhansu"]);
        // console.log(appConfigs);
    }, [user]);

    const appConfigs = useSelector(state => state.config).appConfigs;

    const calledOnce = useRef(false);

    useEffect(() => {
        if (!appConfigs || !appConfigs.Review || !appConfigs.Review.ApprovedLimitStart) return;

        if (calledOnce.current) return;
        calledOnce.current = true;

        // loadConfig();
    }, [appConfigs]);

    const changeUserStatus = async () => {

        if (showUnlock() == false) {
            alert("Quá ngày mở duyệt. Vui lòng kiểm tra lại");
            return;
        }

        if (window.confirm("Bạn muốn " + (userInfo.Status === 0 ? "mở " : "") + "khoá tài khoản [" + userInfo.FirstName + " " + userInfo.LastName + "]?")) {
            doChangeUserStatus();
        }
    }

    const showUnlock = () => {
        if (appConfigs.Review.ApprovedLimitUnLock < (moment(new Date()).date())) return false;

        return true;
    }

    const resetUserPassword = async () => {
        try {
            if (window.confirm("Bạn muốn reset mật khẩu cho người dùng : " + userInfo.FirstName + " " + userInfo.LastName + "?")) {
                const useRef = doc(db, "hp-user", userInfo.EmployeeID);
                const userSnap = await getDoc(useRef);

                if (userSnap.exists()) {
                    const userData = userSnap.data();


                    await updateDoc(useRef, {
                        RestPassCount: userData.RestPassCount ? userData.RestPassCount : 1
                    })

                    alert("Reset mật khẩu thành công thành công. Vui lòng chờ 30 giây trước khi đăng nhập với mật khẩu mới.");
                }
            }
        } catch (err) {
            console.error(err);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
        }
    }

    const doChangeUserStatus = async () => {
        try {
            const useRef = doc(db, "hp-user", userInfo.EmployeeID);
            const userSnap = await getDoc(useRef);

            if (userSnap.exists() && userSnap.data().Status == userInfo.Status) {

                console.log(userSnap.data(), userInfo);

                await updateDoc(useRef, {
                    Status: userInfo.Status == 1 ? 0 : 1
                })

                alert("Cập nhật trạng thái người dùng thành công");
                reloadData(userInfo.EmployeeID);
            }
        } catch (err) {
            console.error(err);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
        }

    }

    return <>
        <div className="mt-4 divide-y divide-gray-200">
            {/* <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Lưu ý:</h3>
                <p className="max-w-2xl text-sm text-gray-500">
                    <ul>
                        <li>- Kiểm tra đung</li>
                    </ul>
                </p>
            </div> */}
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Trạng thái</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow py-4">
                                <b>{getStatusName(userInfo.Status)}</b>
                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                {<button
                                    type="button" onClick={() => changeUserStatus()}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    {userInfo.Status == 1 ? "Khoá tài khoản" : "Mở khoá tài khoản"}
                                </button>}
                            </span>
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Reset mật khẩu</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">

                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                <button
                                    type="button" onClick={() => resetUserPassword()}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Reset mật khẩu
                                </button>
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </>;
}
