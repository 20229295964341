import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux'
import { db, auth } from '../../services/firebase';
import { query, collection, getDocs, where, limit, writeBatch, doc, orderBy, getDoc } from "firebase/firestore";

import NoData from "../../components/NoData"

import axios from "axios";
import { LIMIT_GET_DATA } from "../../constant/config";
import { getObjImportEmployeee } from "../../app/services";
import { checkPageRole, checkUserRoleLoad, classNames, hasRole, removeAccAndLower, writeExcel } from "../../helpers";
import EditUser from "./EditUser";
import UserImport from "./UserImport";
import UserTable from "./UserTable";
import UserCreateDialog from "./UserCreateDialog";
import { async } from "@firebase/util";


const UserList = () => {
    // const [teams, setTeams] = useState([]);

    const [openImport, setOpenImport] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [users, setUsers] = useState(null);
    const [loading, error] = useAuthState(auth);
    const user = useSelector((state) => state.user);
    const [employeeID, setEmployeeID] = useState("");
    const [userName, setUserName] = useState("");

    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["quantri", "nhansu"]);
        // console.log(appConfigs);
    }, [user]);

    const navigate = useNavigate();

    useEffect(() => {
        // console.log("users", users);
    }, [users]);

    const fetchUsers = async (isGetMore) => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            let q = query(collection(db, "hp-user"));
            // console.log(userName);

            if (userName && userName.length > 0) {
                let nameSearch = removeAccAndLower(userName).trim();
                // console.log(nameSearch);
                q = query(q, where("SearchName", "array-contains", nameSearch));
            }

            if (currStatus != "-1") {
                q = query(q, where("Status", "==", currStatus * 1));
            }
            if (isGetMore && users.length > 0) {
                // console.log(employies[employies.length - 1]);
                q = query(q, where("EmployeeID", ">", users[users.length - 1].EmployeeID));
            }
            if (employeeID && employeeID.length > 0) {
                q = query(q, where("EmployeeID", "==", employeeID));
            } else {
                q = query(q, orderBy("EmployeeID"));
            }
            // q = q
            q = query(q, limit(10));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                setUsers((users) => [...users, Object.assign({ uid: doc.id }, doc.data())]);
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    const reloadData = async (employeeID) => {
        const userRef = doc(db, "hp-user", employeeID);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists) {
            requestUpdate({ uid: userSnap.id, ...userSnap.data() });
        }
    }

    const requestUpdate = (usr) => {
        setUsers(users.map(m => m.EmployeeID == usr.EmployeeID ? usr : m));
        // setShowEmployee(false);
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    const changeUserName = (event) => {
        setUserName(event.target.value);
    }

    const searchUser = () => {
        setUsers([]);
        fetchUsers(false);
    }
    const getMoreData = () => { fetchUsers(true) }


    const setOpenCreateUser = (openCreate) => {
        setOpenCreate(true);
    }

    // const editUserInfo = (event, userInfo) => {
    //     event.preventDefault();
    //     setOpen(true);
    //     console.log(userInfo);
    //     setEditUser(userInfo);
    // }

    // const exportExcel = () => {
    //     writeExcel("user-list", "danh-sach-nguoi-dung");
    // }

    const setOpenImportUser = () => {
        setOpenImport(true);
    }

    const [currStatus, setCurrStatus] = useState("1");

    const changeStatus = (event) => {
        setCurrStatus(event.target.value);
    }

    const showImport = () => { return false; }

    return <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div className="sm:flex sm:items-center py-4">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Quản lý người dùng</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    {hasRole(["quantri"], user.hpUser.Role) && <button
                        onClick={() => setOpenCreateUser(true)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Thêm người dùng
                    </button>
                    }
                    {hasRole(["quantri"], user.hpUser.Role) && showImport() && <button
                        onClick={() => setOpenImportUser(true)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Import
                    </button>
                    }
                </div>
            </div>
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 sm:rounded-lg">
                <div className="-ml-4 -mt-2 flex sm:flex-row flex-col">

                    <div className="min-w-0 px-4">
                        <label htmlFor="employeeID" className="block text-left text-sm font-medium text-gray-700">
                            Mã nhân viên
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="employeeID"
                                id="employeeID"
                                value={employeeID}
                                onChange={(event) => setEmployeeID(event.target.value)}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder=""
                            />
                        </div>
                    </div>

                    <div className="min-w-0 px-4">
                        <label htmlFor="userName" className="block text-left text-sm font-medium text-gray-700">
                            Tên
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="userName"
                                id="userName"
                                value={userName}
                                onChange={changeUserName}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder=""
                            />
                        </div>
                    </div>

                    <div className="min-w-0 px-4">
                        <label htmlFor="status" className="block text-left text-sm font-medium text-gray-700">
                            Trạng thái
                        </label>
                        <select
                            id="status"
                            value={currStatus}
                            name="status" onChange={changeStatus}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        // defaultValue={"1"}
                        >
                            <option value="-1">-- Tất cả --</option>
                            <option value="1">Hoạt động</option>
                            <option value="0">Khoá</option>
                        </select>
                    </div>
                    <div className="ml-4 mt-2 pt-4 flex-shrink-0">
                        {/* <label htmlFor="location" style={{ color: "white" }} className="block text-left text-sm font-medium text-gray-700">
                            .sdf
                        </label> */}
                        <button
                            type="button" onClick={searchUser}
                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Tìm
                        </button>
                    </div>
                </div>
            </div>

            {users && <UserTable users={users} getMoreData={getMoreData} reloadData={reloadData} />}
            {/* <EditUser open={open} setOpen={setOpen} userInfo={editUser} /> */}
            <UserImport open={openImport} setOpen={setOpenImport} />

            <UserCreateDialog open={openCreate} setOpen={setOpenCreate} />
        </div>
    </div >;
};
export default UserList;