/* This example requires Tailwind CSS v2.0+ */
import { async } from '@firebase/util';
import { collection, getDocs, limit, query } from 'firebase/firestore';
import { Fragment, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { classNames } from '../../helpers'
import { auth, db } from '../../services/firebase';


export default function ReviewDefine() {
    const [user, loading, error] = useAuthState(auth);
    const [defines, setDefines] = useState([]);
    const navigate = useNavigate();
    let params = useParams();
    const calledOnce = useRef(false);


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;
        fetchReviewDefine("hoanthanh");
        fetchReviewDefine("nghiemtuc");
        fetchReviewDefine("tichcuc");
    }, [user, loading, params.teamID]);

    const fetchReviewDefine = async (group) => {
        try {
            if (!params.teamID) return;
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = query(collection(db, "hp-revew-define", params.teamID, group));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                // console.log(doc.data())
                if (!defines.find(f => f.uid === doc.id)) {
                    setDefines((defines) => [...defines, Object.assign({ uid: doc.id, group, teamID: params.teamID }, doc.data())]);
                }
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    const getDefines = (group) => {
        return defines.filter(f => f.teamID === params.teamID && f.group === group);
    }

    const getGroup = () => {
        // console.log(defines);
        return [
            { Name: "Điểm hoàn thành công việc", Data: getDefines("hoanthanh") },
            { Name: "Thái độ Nghiêm túc", Data: getDefines("nghiemtuc") },
            { Name: "Thái độ tích cực", Data: getDefines("tichcuc") },
        ]
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {/* <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Users</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all the users in your account including their name, title, email and role.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add user
                    </button>
                </div>
            </div> */}
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full">
                                <thead className="bg-white">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            #
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Tên
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Điểm
                                        </th>
                                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Sửa</span>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {getGroup().map((group, idx) => (
                                        <Fragment key={idx}>
                                            <tr className="border-t border-gray-200">
                                                <th
                                                    scope="colgroup"
                                                    className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                                >
                                                    {idx + 1}
                                                </th>
                                                <th
                                                    scope="colgroup"
                                                    className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                                >
                                                    {group.Name}
                                                </th>
                                                <th className="bg-gray-50 text-left whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 ">{group.Data.map((m) => m.Point).reduce((a, b) => a + b, 0)}</th>
                                                {/* <th className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900"></th> */}
                                            </tr>
                                            {group.Data.map((def, defIdx) => (
                                                <tr
                                                    key={def.uid}
                                                    className={classNames(defIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        {(idx + 1) + "." + (defIdx + 1)}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                        {def.Name}
                                                    </td>
                                                    <td className="text-left whitespace-nowrap px-3 py-4 text-sm text-gray-500">{def.Point}</td>
                                                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                            Sửa<span className="sr-only">, {def.Name}</span>
                                                        </a>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
