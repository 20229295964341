import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { classNames } from "../../helpers";
import { auth, db } from "../../services/firebase";

const GeneralReviewDefine = () => {
    let params = useParams();
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    // const [teams, setTeams] = useState([]);

    const teamReducer = useSelector((state) => state.team);


    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }
    

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        // fetchTeams();
    }, [user, loading]);

    useEffect(() => {
        // console.log(getTeams());
        if (getTeams().length == 0) return;
        if (!params.teamID) navigate("/app/configs/review-define/" + getTeams()[0].uid);

        // console.log(getTeams());
    }, [getTeams()])

    // const fetchTeams = async () => {
    //     try {
    //         // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
    //         const q = query(collection(db, "hp-team"));
    //         const docs = await getDocs(q);
    //         docs.forEach(async (doc) => {
    //             setTeams((teams) => [...teams, Object.assign({ uid: doc.id }, doc.data())]);
    //         });
    //         // const data = doc.docs[0].data();
    //         // setName(data.name);
    //     } catch (err) {
    //         console.error(err);
    //         alert("An error occured while fetching user data");
    //     }
    // };

    // const navigate = useNavigate();
    const changeTab = (event) => {
        navigate(event.target.value);
    }

    const getTeamName = (teamID) => {
        // console.log(teams, defines);
        let team = getTeams().find(f => f.uid == teamID.trim());
        if (team) return team.Name;
        return "";
    }

    const getCurrentTab = () => {
        let tab = getTabs().find((tab) => tab.current);
        if (tab) return tab.name;
        return "";
    }

    const getTabs = () => {
        // console.log(getTeams(), params.teamID);
        if (!params.teamID || getTeams().length == 0) return [];

        // console.log(getTeams());

        return getTeams().map((team) => {
            return {
                name: team.Name,
                href: '/app/configs/review-define/' + team.uid,
                current: team.uid === params.teamID
            }
        });
    }

    // console.log(getTabs());

    return <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            <h1 className="text-2xl font-semibold text-gray-900">Cấu hình Tiêu chí</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={changeTab}
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        defaultValue={getCurrentTab}
                    >
                        {getTabs().map((tab) => (
                            <option key={tab.name} value={tab.href}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200 ">
                        <nav className="-mb-px flex space-x-8 " aria-label="Tabs">
                            {getTabs().map((tab) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        tab.current
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>

            </div>
            {/* /End replace */}
        </div>
        {/* <ReviewDefine /> */}
        <Outlet />
    </div>;
};
export default GeneralReviewDefine;