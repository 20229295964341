import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import employeeReducer from '../features/employee/employeeSlice'
import useReducer from '../features/user/userSlice';
import teamReducer from '../features/team/teamSlice';
import configReducer from '../features/config/configSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

export default configureStore({
  reducer: {
    employeeList: employeeReducer,
    user: useReducer,
    team: teamReducer,
    config: configReducer,
  },
  middleware: customizedMiddleware
})