import { async } from "@firebase/util";
import { Switch } from "@headlessui/react";
import { update } from "firebase/database";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { checkPageRole, checkUserRoleLoad, classNames, getDays, getMonthAddMonth, getYearAddMonth } from "../../../helpers";
import { db } from "../../../services/firebase";

export const UserAutoApproved = ({ userInfo }) => {

    const user = useSelector((state) => state.user);
    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["quantri", "nhansu"]);
        // console.log(appConfigs);
    }, [user]);


    const [autoByConfig, setAutoByConfig] = useState(false);
    const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false)

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));

    const appConfigs = useSelector(state => state.config).appConfigs;

    const days = getDays();

    const [dayLevel1, setDayLevel1] = useState(days[0]);
    const [dayLimitStart, setDayLimitStart] = useState(days[0]);
    const [dayLimitEnd, setDayLimitEnd] = useState(days[0]);
    const [dayLevel2, setDayLevel2] = useState(days[0]);

    const getConfigReview = () => { return appConfigs.Review }

    const getDaysLevel1 = () => {
        return days.filter(f => f.value >= dayLimitStart.value && f.value <= dayLimitEnd.value);
    }

    const calledOnce = useRef(false);
    useEffect(() => {
        if (!appConfigs || !appConfigs.Review || !appConfigs.Review.ApprovedLimitStart) return;

        if (calledOnce.current) return;
        calledOnce.current = true;

        setDayLimitStart(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitStart));
        setDayLimitEnd(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitUnLock));
        setDayLevel1(getDays().find(f => f.value === appConfigs.Review.ApprovedLevel1));
        setDayLevel2(getDays().find(f => f.value === appConfigs.Review.ApprovedLevel2));

        loadConfig();
    }, [appConfigs]);

    const loadConfig = async () => {
        const docRef = doc(db, "hp-user-config", userInfo.EmployeeID);
        const snapDoc = await getDoc(docRef);

        if (snapDoc.exists()) {
            const userConfig = { id: snapDoc.id, ...snapDoc.data() };
            // console.log(userConfig);

            if (userConfig.AutoLevel1 && userConfig.AutoLevel1 > 0) {
                setDayLevel1(getDays().find(f => f.value === userConfig.AutoLevel1));
            } else {
                setDayLevel1(getDays().find(f => f.value === appConfigs.Review.ApprovedLevel1));
            }

            if (userConfig.AutoLevel2 && userConfig.AutoLevel2 > 0) {
                setDayLevel2(getDays().find(f => f.value === userConfig.AutoLevel2));
            } else {
                setDayLevel2(getDays().find(f => f.value === appConfigs.Review.ApprovedLevel2));
            }
        }

    }


    const saveConfig = async (level, value) => {
        // console.log(level, value);
        if (dayLevel2.value <= dayLevel1.value) {
            alert("Vui lòng chọn ngày duyệt tự động cấp 2 lớn hơn cấp 1");
            return;
        }

        if (value.value < dayLimitStart.value) {
            alert("Vui lòng chọn ngày duyệt tự động lớn hơn hoặc bằng " + dayLimitStart.label);
            return;
        }

        if (value.value > dayLimitEnd.value) {
            alert("Vui lòng chọn ngày duyệt tự động nhỏ hơn " + dayLimitEnd.label);
            return;
        }

        if (window.confirm("Bạn muốn cập nhật ngày duyệt tự động cấp " + level + "?")) {
            const docRef = doc(db, "hp-user-config", userInfo.EmployeeID);
            let obj = {};
            obj["AutoLevel" + level] = value ? value.value : -1;

            if ((await (await getDoc(docRef)).exists() == false)) {
                obj.EmployeeID = userInfo.EmployeeID;
                await setDoc(docRef, obj);
            } else {
                await updateDoc(docRef, obj);
            }

            loadConfig();
            alert("Cập nhật thành công");
        }
    }


    return <>
        <div className="mt-4 divide-y divide-gray-200">
            <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Lưu ý ngày duyệt tự động:</h3>
                <p className="max-w-2xl text-sm text-gray-500">
                    Giới hạn: tối thiểu <b>{dayLimitStart.label}</b>, tối đa <b>{dayLimitEnd.label}</b><br />
                    Hệ thống: cấp 1 <b>Ngày {getConfigReview().ApprovedLevel1}</b>, cấp 2 <b>Ngày {getConfigReview().ApprovedLevel2}</b><br />
                </p>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">Language</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">English</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Update
                                </button>
                            </span>
                        </dd>
                    </div> */}
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Duyệt tự động cấp 1</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                <select
                                    id="daylevel1"
                                    value={dayLevel1.value}
                                    name="daylevel1" onChange={(event) => setDayLevel1(getDays().find(f => f.value === event.target.value * 1))}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                // defaultValue={"1"}
                                >
                                    {getDaysLevel1().map(m => {
                                        return <option key={m.value} value={m.value}>{m.label}</option>
                                    })}
                                    {/* <option value="-1">-- Tất cả --</option> */}
                                    {/* <option value="1">Hoạt động</option> */}
                                    {/* <option value="0">Khoá</option> */}
                                </select>
                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                <button
                                    type="button" onClick={() => saveConfig(1, dayLevel1)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Cập nhật
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button
                                    type="button" onClick={() => saveConfig(1, null)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Hệ thống
                                </button>
                            </span>
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Duyệt tự động cấp 2</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                <select
                                    id="daylevel1"
                                    value={dayLevel2.value}
                                    name="daylevel1" onChange={(event) => setDayLevel2(getDays().find(f => f.value === event.target.value * 1))}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                // defaultValue={"1"}
                                >
                                    {getDaysLevel1().map(m => {
                                        return <option key={m.value} value={m.value}>{m.label}</option>
                                    })}
                                    {/* <option value="-1">-- Tất cả --</option> */}
                                    {/* <option value="1">Hoạt động</option> */}
                                    {/* <option value="0">Khoá</option> */}
                                </select>
                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                <button
                                    type="button" onClick={() => saveConfig(2, dayLevel2)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Cập nhật
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button
                                    type="button" onClick={() => saveConfig(2, null)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Hệ thống
                                </button>
                            </span>
                        </dd>
                    </div>
                    {/* <Switch.Group as="div" className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                        <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                            Theo cấu hình riêng
                        </Switch.Label>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <Switch
                                checked={autoByConfig}
                                onChange={setAutoByConfig}
                                className={classNames(
                                    autoByConfig ? 'bg-purple-600' : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-auto'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        autoByConfig ? 'translate-x-5' : 'translate-x-0',
                                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </dd>
                    </Switch.Group> */}
                    {/* <Switch.Group
                        as="div"
                        className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5"
                    >
                        <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                            Auto-update applicant data
                        </Switch.Label>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <Switch
                                checked={autoUpdateApplicantDataEnabled}
                                onChange={setAutoUpdateApplicantDataEnabled}
                                className={classNames(
                                    autoUpdateApplicantDataEnabled ? 'bg-purple-600' : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-auto'
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        autoUpdateApplicantDataEnabled ? 'translate-x-5' : 'translate-x-0',
                                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </dd>
                    </Switch.Group> */}
                </dl>
            </div>
        </div>
    </>;
}
