import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reviewDefines: [],
    appConfigs: {}
};

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        AddReviewDefine: (state, action) => {
            if (state.reviewDefines.find(f => f.uid === action.payload.reviewDefine.uid) == null) {
                state.reviewDefines.push(action.payload.reviewDefine);
            }
        },
        AddConfig: (state, action) => {
            state.appConfigs[action.payload.configName] = action.payload.config;
        },
    },
});

export const { AddReviewDefine, AddConfig } = configSlice.actions;

export default configSlice.reducer;