/* This example requires Tailwind CSS v2.0+ */
export default function ImportReviewShort({employiesFile}) {
    const getStats = () => {

        return [
            { name: 'Tổng nhân viên', stat: employiesFile.length},
            { name: 'Tạo mới', stat: employiesFile.filter(f => f.ImportType == 1).length},
            { name: 'Cập nhật', stat: employiesFile.filter(f => f.ImportType == 2).length},
            { name: 'Khoá', stat: employiesFile.filter(f => f.ImportType == 0).length},
            { name: 'Không Import', stat: employiesFile.filter(f => f.ImportType == -1).length},
        ];
    }
    return (
        <div className="py-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Tóm tắt thông tin</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
                {getStats().map((item) => (
                    <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
