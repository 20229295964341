import { useState } from "react";
import NoData from "../../components/NoData";
import { classNames } from "../../helpers";
import { getRoleNames, getStatusName } from "../../helpers/uiHelper";
import UserDetailDialog from "./UserDetailDialog";

export default function UserTable({ users, getMoreData, reloadData }) {



    const [isShow, setIsShow] = useState(false);
    const doShow = (value) => {

        setIsShow(value);
    }

    const [currUser, setCurrUser] = useState(null);
    const editUserInfo = (event, userItem) => {
        event.preventDefault();

        setCurrUser(userItem);
        doShow(true);
    }


    const reqReloadData = (emp) => {
        reloadData(emp);
        doShow(false);
    }

    return <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block py-2 align-middle md:px-6 lg:px-8  min-w-full w-full">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full" id="user-list">
                        <thead className="bg-white">
                            <tr>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Mã NV
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Họ
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tên
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Email
                                </th>
                                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Email
                            </th> */}
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Quyền
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Trạng thái
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Chức năng</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {users.map((item, idx) => (
                                <tr
                                    key={item.Email + idx}
                                    className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.EmployeeID}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.FirstName}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.LastName}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {item.Email}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{getRoleNames(item.Role)}</td>
                                    <td className={classNames(item.Status == 1 ? "text-green-600" : "text-gray-900", "whitespace-nowrap px-3 py-4 text-sm ")}>{getStatusName(item.Status)}</td>
                                    <td className={classNames("relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6")}>
                                        <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(event) => editUserInfo(event, item)}>
                                            Chi tiết<span className="sr-only">, {item.Name}</span>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {users && users.length > 0 && <tfoot>
                            <tr>
                                <td colSpan={8} className="px-4 py-4">
                                    <button
                                        type="button" onClick={getMoreData}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Lấy thêm dữ liệu
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                        }
                    </table>
                    {users && users.length == 0 && <NoData />}

                    <UserDetailDialog open={isShow} userInfo={currUser} setOpen={doShow} reloadData={reqReloadData} />
                </div>
            </div>
        </div>
    </div>
}