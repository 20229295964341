import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logout } from "../../services/firebase"

export default function Logout() {

    const navigate = useNavigate();

    useEffect(() => {
        logout();
        setTimeout(() => {
            navigate("/auth/login");
        }, 200);

    }, [])
    return (
        <></>
    );
}