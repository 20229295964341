import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { addEmployee } from "../../features/employee/employeeSlice";

import { db, auth } from '../../services/firebase';
import { query, collection, getDocs, where, limit, writeBatch, doc, orderBy, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { async } from "@firebase/util";

import { checkEditReviewDate, classNames, getColorByPoint, getDefines, getEmployeeReviewBg, getFullMonth, getMonthAddMonth, getMonths, getSorttEmployeeByTeam, getTitleByPoint, getYearAddMonth, getYears, hasRole, sumArray } from "../../helpers";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

import NoData from "../../components/NoData"

import axios from "axios";
import ReviewDetail from "./ReviewDetail";
import ReviewOne from "./ReviewOne";
import { approveReview, createReview, createReviewLock } from "../../app/services";
import moment from "moment";

const ReviewMulti = () => {
    const reviewDefines = useSelector((state) => state.config.reviewDefines);
    const appConfigs = useSelector(state => state.config).appConfigs;


    let params = useParams();
    const [employies, setEmployies] = useState(null);
    const [employiesReview, setEmployiesReview] = useState([]);

    const [employeeReview, setEmployeeReview] = useState({});
    const [employeeData, setEmployeeData] = useState({});
    // const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);

    const [showImport, setShowImport] = useState(false);

    const [loading, error] = useAuthState(auth);
    const user = useSelector((state) => state.user);

    const [name, setName] = useState("");

    const [open, setOpen] = useState(false);

    const [selectedFile, setSelectedFile] = useState("");
    const [departments, setDepartments] = useState([]);


    const [searchTeam, setSearchTeam] = useState(-1);

    const cancelButtonRef = useRef(null);

    const navigate = useNavigate();

    const [mnv, setMNV] = useState("");
    const [tenNV, setTenNV] = useState("");

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));

    const [reviewStatus, setReviewStatus] = useState("-1");

    const teamReducer = useSelector((state) => state.team);

    // const changeMonth = (event) => {
    //     setMonth(getMonths().find(f => f.value === parseInt(event.target.value)));
    // };

    // const changeYear = (event) => {
    //     // setYear(event);
    //     setYear(getYears().find(f => f.value === parseInt(event.target.value)));
    // };

    const [areas, setAreas] = useState([]);
    const [currArea, setCurrArea] = useState("-1");
    const calledOnce = useRef(false);


    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }
    const getQuery = (isGetMore) => {
        let res = true;
        if (!user.hpUser || !user.hpUser.Role) return null;

        let q = query(collection(db, "hp-employee"), where("Level" + params.level, "==", user.hpUser.EmployeeID));

        q = query(q, where("Status", "==", 1));

        if (tenNV) {
            console.log(tenNV.trim());
            q = query(q, where("LastName", "==", tenNV.trim()));
        }

        if (mnv) {
            // console.log(mnv);
            q = query(q, where("EmployeeID", "==", mnv));
        } else {
            q = query(q, orderBy("EmployeeID"));
        }

        let indexArea = areas.find(f => f.uid == currArea);

        // console.log(indexArea);

        if (indexArea) {
            q = query(q, where("Area", "==", indexArea.Name));
        }

        if (searchTeam != "-1" && getTeams().find(f => f.uid == searchTeam) != null) {
            q = (query(q, where("TeamID", "==", searchTeam)));
        }

        // switch (reviewStatus) {
        //     case "0": q = query(q, where(`Level${params.level}Approved`, "==", false)); break;
        //     case "1": q = query(q, where(`Level${params.level}Approved`, "==", true)); break;
        //     case "-1":
        //     default: break;
        // }

        // q = query9
        // if (hasRole(["nhansu", "quantri"], user.hpUser.Role)) {
        //     // khong can bo sung gi
        //     res = true;
        // }
        // else if (hasRole(["level1", "level2"], user.hpUser.Role)) {
        //     // console.log("Level", user.uid);
        //     q = query(q, where("Level", "array-contains", user.uid));
        //     res = true;
        // }

        if (isGetMore && employies.length > 0) {
            console.log(employies[employies.length - 1]);
            q = query(q, where("EmployeeID", ">", employies[employies.length - 1].EmployeeID));
        }

        // tam bo limit
        // sort bang javascript
        // q = query(q, orderBy("EmployeeID"));
        // console.log(queres);
        if (res) return q;
        return null;

    }
    const fetchEmployee = async (isGetMore) => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = getQuery(isGetMore);
            // console.log('fetch employee q: ', q);

            if (!q) return;

            const docs = await getDocs(q);
            let count = 0;

            docs.forEach((doc) => {
                count++;
                setEmployies((employies) => [...employies, doc.data()]);

                handleSearchReview(doc.data().EmployeeID);
                if (docs.size - 1 == count) {
                    // cai cuoi thi cho 1 giay set lai de render
                    setTimeout(() => setEmployies((employies) => [...employies]), 1000);
                }
                // console.log(doc.id, doc.data());
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    const fetchUsers = async () => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = query(collection(db, "hp-user"));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                setUsers((users) => [...users, Object.assign({ uid: doc.id }, doc.data())]);
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;

        fetchUsers();
        // fetchAreas();
    }, [user, loading]);

    useEffect(() => {
        // console.log("users", users.length);
        if (users && users.length > 0) {
            // console.log(user);
            if (areas.length == 0) {
                fetchAreas();
            }
        }
    }, [users]);

    const fetchAreas = async () => {
        try {
            let q = query(collection(db, "hp-area"));

            if (hasRole(["level1", "level2"], user.hpUser.Role)) {
                q = query(q, where("Level", "array-contains", user.hpUser.EmployeeID + ""));
            }

            const docs = await getDocs(q);
            docs.forEach((doc) => {
                setAreas((areas) => [...areas, Object.assign({ uid: doc.id }, doc.data())]);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const changeArea = (event) => {
        // console.log(event.target.value);
        setCurrArea(event.target.value);
    }

    const getAreas = () => {
        if (!areas) return [];
        return [...areas].sort((a, b) => a.Name > b.Name ? 1 : -1);
    }


    const findDep = (depName) => {
        let dep = departments.find(d => d.ShortName === depName);
        // console.log(dep);
        if (dep) return dep.id;
        return "";
    }
    const searchEmployee = () => {
        // console.log('search');
        // console.log(getTeams());
        setEmployies([]);
        setEmployiesReview([]);
        fetchEmployee(false);
    }

    const getMoreData = () => {
        fetchEmployee(true);
    }

    const handleSearchReview = async (maNhanVien) => {
        try {
            // console.log(maNhanVien, getFullMonth(month, year));
            const q = query(collection(db, "hp-review"),
                where("EmployeeID", "==", maNhanVien),
                where("FullMonth", "==", getFullMonth(month, year))
            );
            // console.log(q);

            const querySnapshot = await getDocs(q);
            if (querySnapshot.size == 0) {
                // cap 1 hoac cap 2 deu tao duoc
                // const lockReview = appConfigs.Review.ApprovedLevel1 <= (moment(new Date()).date());// && params.level == 1;
                // console.log(lockReview, appConfigs.Review.ApprovedLevel1, moment(new Date()).date());
                if (appConfigs.Review.ApprovedLimitUnLock > (moment(new Date()).date())) {
                    if (createReview(maNhanVien, month, year)) {
                        // vẫn đang còn cho mở tạo thì duyệt
                        handleSearchReview(maNhanVien);
                    }
                }
            } else {
                querySnapshot.forEach((doc) => {
                    let empReview = Object.assign({ uid: doc.id }, doc.data());
                    // console.log(empReview);
                    setEmployiesReview((employiesReview) => [...employiesReview, empReview]);
                    return;
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const getEmployeeReview = (employee) => {
        return employiesReview.find(f => f.EmployeeID == employee.EmployeeID);
    }

    const getPoint = (employee, level, group) => {
        try {
            let empReview = getEmployeeReview(employee);
            if (!empReview) return "";
            // console.log(employee, "Points" + level, group, empReview);
            if (!group) return empReview["Level" + level + "Point"];
            return sumArray(empReview["Points" + level].filter(w => w.Group == group), "Point");
        }
        catch (e) {
            console.log(e);
            return "";
        }
    }

    const reviewEmployee = (event, employee) => {
        event.preventDefault();
        // console.log(employee);
        // console.log(getEmployeeReview(employee));
        setEmployeeData(employee);
        setEmployeeReview(getEmployeeReview(employee));
        setOpen(true);
    }

    const setOpenReview = async (isOpen) => {
        // console.log(isOpen);
        setOpen(isOpen);
        // if(isOpen == false)
        // searchEmployee();
        // search theo ma nha vien 
        if (isOpen == false) {
            updateItemReview(employeeReview);
        }
    }
    const updateItemReview = async (employeeReviewU) => {
        if (employeeReviewU && employeeReviewU.EmployeeID) {
            try {
                // console.log(employeeReviewU);
                // const empRef = doc(db, "hp-review", employeeReview.uid);
                // const snapReview = getDoc(empRef);
                // if(snapReview.e)

                const docRef = doc(db, "hp-review", employeeReviewU.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // console.log("Document data:", docSnap.data());
                    let empReview = Object.assign({ uid: docSnap.id }, docSnap.data());
                    // console.log(empReview);
                    setEmployiesReview(employiesReview.map(item => item.uid == empReview.uid ? empReview : item));
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
    }

    const ApprovedEmp = (event, emp, empReview) => {
        event.preventDefault();
        if (window.confirm(`Bạn muốn duyệt nhân viên [${emp.EmployeeID}-${emp.FirstName} ${emp.LastName}]`)) {
            // let points = params.level == 1 ? employeeReview.Points1 : employeeReview.Points2;
            // console.log((params.level * 1), (params.level * 1) ==  2);
            if ((params.level * 1) == 2) {

                // kiem tra neu level 1 chua duyet thi khong duyet cap 2
                if (!empReview.Level1Approved) {
                    alert("Vui lòng duyệt cấp 1 trước khi duyệt cấp 2");
                    return false;
                }
            }
            if (approveReview(user, empReview, params.level)) {
                alert("Duyệt thành công");
            }
            updateItemReview(empReview);
        }
    }


    const showReviewApproved = (empRev) => {
        if (!empRev) return false;
        if (checkEditReviewDate(appConfigs) == true) return true;
        // console.log(empRev);
        // neu cap 1 thi cung khoa luon
        if (params.level == 1 && empRev.Level1ApprovedLock) return false;

        // console.log(empRev, params.level);

        // neu ngoai thoi gian ma da duyet, thi neu mo danh gia thi cung mo ra
        if (empRev.Level2ApprovedLock == false) return true;
        return false;
    }

    const getEmployeeList = () => {
        let res = [];
        if (!employiesReview) return [];
        switch (reviewStatus) {
            case "0": res = employies.filter(f => findReviewByStaus(f.EmployeeID, false)); break;
            case "1": res = employies.filter(f => findReviewByStaus(f.EmployeeID, true)); break;
            case "-1":
            default: res = employies; break;
        }
        // console.log(res);
        return res;
    }

    const findReviewByStaus = (empID, isApproved) => {
        let emp = employiesReview.find(f => f.EmployeeID == empID);
        // console.log(emp);
        if (!emp && isApproved == true) return false;
        if (!emp && isApproved == false) return true;

        return emp[`Level${params.level}Approved`] == isApproved;
    }

    const changeStatus = (status) => {
        setEmployies(null);
        setEmployiesReview(null);
        setReviewStatus(status);
    }

    return <div className="py-6">
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
        </div> */}
        <div className="max-w-7xl mx-auto  min-w-full w-full">
            {/* Replace with your content */}
            <div className="bg-white shadow sm:rounded-lg ">
                <div className="px-4 py-3 sm:p-6">
                    <div className="mt-2 flex flex-col">
                        <div className="bg-white px-4 sm:px-6">
                            <div className="-ml-4 -mt-2 flex sm:flex-row flex-col items-center justify-between flex-wrap sm:flex-nowrap">
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                                    <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                                        Mã nhân viên
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="manv"
                                            id="manv"
                                            value={mnv}
                                            onChange={(event) => setMNV(event.target.value)}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                                    <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                                        Tên nhân viên
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="tennv"
                                            id="tennv"
                                            valeu={tenNV}
                                            onChange={(event) => setTenNV(event.target.value)}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                                    <label htmlFor="location" className="block text-left text-sm font-medium text-gray-700">
                                        Nhóm
                                    </label>
                                    <select
                                        id="location"
                                        name="location"
                                        onChange={(event) => setSearchTeam(event.target.value + "")}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={searchTeam + ""}
                                    >
                                        <option value="-1">-- Tất cả --</option>
                                        {getTeams().map(team => <option key={team.uid} value={team.uid}>{team.Name}</option>)}
                                    </select>
                                </div>

                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                                    <label htmlFor="area" className="block text-left text-sm font-medium text-gray-700">
                                        Đội/ Cụm
                                    </label>
                                    <select
                                        id="area"
                                        name="area"
                                        onChange={changeArea}
                                        defaultValue="-1"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    // value={searchTeam + ""}
                                    >
                                        <option value="-1">-- Tất cả --</option>
                                        {areas && areas.length > 0 && getAreas().map(are => {
                                            return <option key={are.uid} value={are.uid}>{are.Name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1">
                                    <label htmlFor="status" className="block text-left text-sm font-medium text-gray-700">
                                        Trạng thái
                                    </label>
                                    <select
                                        id="status"
                                        name="status" onChange={(event) => changeStatus(event.target.value + "")}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        defaultValue="-1"
                                    >
                                        <option value="-1">-- Tất cả --</option>
                                        <option value="1">Đã duyệt</option>
                                        <option value="0">Chưa duyệt</option>
                                    </select>
                                </div>
                                <div className="ml-4 mt-6 flex-shrink-0">
                                    {/* <label htmlFor="location" style={{ color: "white" }} className="block text-left text-sm font-medium text-gray-700">
                                        .sdf
                                    </label> */}
                                    <button
                                        type="button" onClick={searchEmployee}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Tìm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex flex-col">

                {/* <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> */}
                <table className="min-w-full">
                    <thead className="bg-white">
                        <tr>
                            <th colSpan={14}>

                            </th>
                        </tr>
                    </thead>
                    <thead className="bg-white">
                        <tr>
                            <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Mã NV
                            </th>
                            <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Họ
                            </th>
                            <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Tên
                            </th>
                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                            <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Chức danh
                            </th>
                            <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Nơi công tác
                            </th>
                            <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Đội/ Cụm
                            </th>
                            <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Quản lý trực tiếp
                            </th>
                            {params.level == 2 && <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Quản lý cao hơn
                            </th>}
                            <th rowSpan={2} scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Đánh giá</span>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Hoàn thành công việc
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Thái độ nghiêm túc
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Thái độ tích cực
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Tổng điểm
                            </th>
                            {params.level == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Hoàn thành công việc
                            </th>}
                            {params.level == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Thái độ nghiêm túc
                            </th>}
                            {params.level == 2 &&
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Thái độ tích cực
                                </th>}
                            {params.level == 2 &&
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tổng điểm
                                </th>}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {getEmployeeList() && getEmployeeList().length > 0 && getSorttEmployeeByTeam(getTeams(), getEmployeeList()).map((team) => (
                            <Fragment key={team.Code}>
                                <tr className="border-t border-gray-200">
                                    <th
                                        colSpan={9}
                                        scope="colgroup"
                                        className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                    >
                                        {team.Name}
                                    </th>
                                </tr>
                                {team.List.map((employee, idx) => (
                                    <tr
                                        key={idx}
                                        className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                    >
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {employee.EmployeeID}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {employee.FirstName}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Area}</td>
                                        <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 1, "hoanthanh")}</td>
                                        <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 1, "nghiemtuc")}</td>
                                        <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 1, "tichcuc")}</td>
                                        <td title={getTitleByPoint(getPoint(employee, 1, ""))} className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 1), getColorByPoint(getPoint(employee, 1, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>
                                            {getPoint(employee, 1, "hoanthanh") + getPoint(employee, 1, "nghiemtuc") + getPoint(employee, 1, "tichcuc")}
                                            {/* {getPoint(employee, 1, "")} */}
                                        </td>
                                        {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 2, "hoanthanh")}</td>
                                        }
                                        {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 2, "nghiemtuc")}</td>
                                        }
                                        {params.level == 2 && <td className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>{getPoint(employee, 2, "tichcuc")}</td>
                                        }
                                        {params.level == 2 && <td title={getTitleByPoint(getPoint(employee, 2, ""))} className={classNames(getEmployeeReviewBg(getEmployeeReview(employee), 2), getColorByPoint(getPoint(employee, 2, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-500")}>
                                            {getPoint(employee, 2, "hoanthanh") + getPoint(employee, 2, "nghiemtuc") + getPoint(employee, 2, "tichcuc")}
                                            {/* {getPoint(employee, 2, "")} */}
                                        </td>
                                        }
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            {showReviewApproved(getEmployeeReview(employee)) &&
                                                <a href="#" onClick={(event) => reviewEmployee(event, employee, getEmployeeReview(employee))} className="text-indigo-600 hover:text-indigo-900">
                                                    Đánh giá<span className="sr-only">, {employee.Name}</span>
                                                </a>}
                                            {showReviewApproved(getEmployeeReview(employee)) && " | "}
                                            {showReviewApproved(getEmployeeReview(employee)) &&
                                                <a href="#" onClick={(event) => ApprovedEmp(event, employee, getEmployeeReview(employee))} className="text-indigo-600 hover:text-indigo-900">
                                                    Duyệt<span className="sr-only">, {employee.Name}</span>
                                                </a>}
                                        </td>
                                    </tr>
                                ))}
                            </Fragment>
                        ))}

                    </tbody>
                </table>
                {employies && employies.length == 0 && <NoData />}
                {/* </div>
                    </div>
                </div> */}
            </div>
            {/* /End replace */}


            <Transition.Root show={open} as={Fragment} >
                <Dialog as="div" className="relative z-10 max-w-md" initialFocus={cancelButtonRef} onClose={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end md:items-center justify-center min-h-full p-4 text-center md:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 md:scale-95"
                                enterTo="opacity-100 translate-y-0 md:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            >
                                {/* <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:w-95 md:p-6">
                                    
                                </Dialog.Panel> */}

                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-gray-900"> Đánh giá nhân viên</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() => setOpenReview(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex-1 px-4 sm:px-6">
                                        {/* Replace with your content */}
                                        {employeeData && <ReviewOne employeeData={employeeData} employeeReviewData={employeeReview} setOpen={setOpenReview} />
                                        }

                                        {/* /End replace */}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    </div >;
};
export default ReviewMulti;