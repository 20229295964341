import { Component } from "react";



export class FirebaseService extends Component {
    static myInstance = null;

    static getInstance() {
        return new FirebaseService();
    }

    async requestAllEmpolyee() {
        
    }
}
export default FirebaseService
