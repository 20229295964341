/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { collection, doc, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../components/NoData';
import { classNames, getColorByPoint, getFullMonth, getMonthAddMonth, getMonths, getSorttEmployeeByTeam, getTitleByPoint, getYearAddMonth, getYears, hasRole, sumArray, writeExcel } from '../../helpers';
import { db } from '../../services/firebase';

export default function ReviewMonthReport() {

  const [employies, setEmployies] = useState(null);
  const [employiesReview, setEmployiesReview] = useState([]);

  const [isSearch, setIsSearch] = useState(true);

  const reviewDefines = useSelector((state) => state.config.reviewDefines);


  const user = useSelector((state) => state.user);


  const [month, setMonth] = useState(getMonthAddMonth(-1));
  // const [year, setYear] = useState(getYearAddMonth(-1));

  const [yearMonth, setYearMonth] = useState(getYearAddMonth(-1));

  const [isView, setIsView] = useState(false);

  const teamReducer = useSelector((state) => state.team);
  const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }

  const changeMonth = (event) => {
    setMonth(getMonths().find(f => f.value === parseInt(event.target.value)));
  };

  useEffect(() => {
    if (employies && employies.length > 0) {
      fetchEmployee(true);
    }
  }, [employies]);

  useEffect(() => {
    // phai co nhan vien roi moi search tiep
    if (employies && employies.length > 0) {
      console.log(employiesReview.length);
      fetchEmployeeReview(true);
    }
  }, [employiesReview])

  // const changeYear = (event) => {
  //   // setYear(event);
  //   setYear(getYears().find(f => f.value === parseInt(event.target.value)));
  // };

  const changeYearMonth = (event) => {
    // setYear(event);
    setYearMonth(getYears().find(f => f.value === parseInt(event.target.value)));
  };

  useEffect(() => {
    setEmployies([]);
    setEmployiesReview([]);
    // fetchEmployee(false);
    setIsSearch(false);
  }, [user])

  const exportMonth = (file) => {
    setEmployies([]);
    setEmployiesReview([]);
    fetchEmployee(false);

    if (!file) {
      setIsView(!file);
    }

    if (file) {
      // setTimeout(() => {
      writeExcel("table-report-month", `report-thang-${month.value}-${yearMonth.value}`);
    }
    // }, 500);
  }

  const getQuery = (isGetMore) => {
    let q = query(collection(db, "hp-employee"));

    if (isGetMore) {
      // console.log(employies.length, employies[employies.length - 1]);
      q = query(q, where("EmployeeID", ">", employies[employies.length - 1].EmployeeID));
    }

    // console.log(user.hpUser);

    if (hasRole(["nhansu"], user.hpUser.Role)) {
      // khong filter
    }
    else if (hasRole(["level1", "level2"], user.hpUser.Role)) {
      // console.log(user.hpUser.Role, user.hpUser.EmployeeID);
      q = query(q, where("Level", "array-contains", user.hpUser.EmployeeID));
      // console.log(q);
    }

    // if (user)
    q = query(q, orderBy("EmployeeID"), limit(500));
    return q;
  }
  const fetchEmployee = async (isGetMore) => {
    try {
      setIsSearch(true);
      // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
      const q = getQuery(isGetMore);

      if (!q) return;
      const docs = await getDocs(q);
      console.log("fetchEmployee", docs.size);

      if (docs.size == 0) {
        // lay du lieu review
        fetchEmployeeReview(false);
        //lay diem xonng moi hien len
        // setIsSearch(false);
      }

      // var bar = new Promise((resolve, reject) => {
      docs.forEach((doc) => {
        setEmployies((employies) => [...employies, doc.data()]);


        // handleSearchReview(doc.data().EmployeeID);
        // console.log(docs.size, index, index === docs.size - 1);
        // if (index === docs.size - 1) {
        //   setTimeout(() => { fetchEmployee(true); }, 2000);
        // }
      });
      // });
    } catch (err) {
      console.error(err);
    } finally {

    }
  };

  const fetchEmployeeReview = (isGetMore) => {
    // if(isGetMore) return;
    // da co day du thong tin nguoi dung
    if (hasRole(["nhansu"], user.hpUser.Role)) {
      // lay het
      // employeeListID = [];
      handleSearchReview([], isGetMore);

    } else if (hasRole(["level1", "level2"], user.hpUser.Role)) {
      if (isGetMore) return;

      let employeeListID = [];

      employies.forEach((emp, idx) => {
        employeeListID.push(emp.EmployeeID);
        if ((idx + 1) % 10 == 0 || (idx + 1) == employies.length) {
          let searchEmp = [...employeeListID];
          employeeListID = [];
          // console.log(searchEmp);
          handleSearchReview(searchEmp, false);
        }
        if ((idx + 1) == employies.length) {
          setIsSearch(false);
        }
      });
      return;
      // lay theo 
    } else {
      return;
    }

    // console.log(q);
    // khi nao het danh sach thi lay ra
    // setIsSearch(false);
  }

  const handleSearchReview = async (employeeListID, isGetMore) => {
    try {
      // console.log(employeeListID, "more", isGetMore);
      let q = query(collection(db, "hp-review"),
        where("FullMonth", "==", getFullMonth(month, yearMonth))
      );

      if (employeeListID && employeeListID.length > 0) {
        q = query(q, where("EmployeeID", "in", employeeListID));
      } else {
        q = query(q, orderBy("EmployeeID"), limit(200));
      }

      if (isGetMore) {
        // console.log(employies.length, employies[employies.length - 1]);
        q = query(q, where("EmployeeID", ">", employiesReview[employiesReview.length - 1].EmployeeID));
      }


      const querySnapshot = await getDocs(q);

      // console.log("fetchEmployeeReview", querySnapshot.size);

      if (querySnapshot.size === 0) {
        // createReview(maNhanVien);
        setIsSearch(false);
      } else {
        querySnapshot.forEach((doc) => {
          let empReview = Object.assign({ uid: doc.id }, doc.data());
          // console.log(empReview);
          setEmployiesReview((employiesReview) => [...employiesReview, empReview]);
          return;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  // const getEmployeeByTeam = () => {
  //   // console.log(getTeams());
  //   return getTeams().map(team => {
  //     return {
  //       Name: team.Name,
  //       Code: team.Code,
  //       List: getEmployeeList().filter(emp => emp.TeamID === team.uid)
  //     }
  //   }).filter(f => f.List.length > 0);
  // }

  // const getEmployeeList = () => {
  //   return [...employies].sort((a, b) =>
  //     a.WorkPlace > b.WorkPlace ? -1 : 1,
  //   );;
  // }

  const getEmployeeReview = (employee) => {
    // console.log(employee);
    return employiesReview.find(f => f.EmployeeID === employee.EmployeeID);
  }

  const getPoint = (employee, level, group) => {
    try {
      let empReview = getEmployeeReview(employee);
      if (!empReview) return "";
      // console.log(employee, "Points" + level, group, empReview);
      if (!group) return empReview["Level" + level + "Point"];

      // chi tinh neu diem da approved
      // switch (level) {
      //   case 1:
      //     if (!empReview.Level1Approved) return 0;
      //     break;
      //   case 2:
      //     if (!empReview.Level2Approved) return 0;
      //     break;
      //   default: return 0;
      // }
      return sumArray(empReview["Points" + level].filter(w => w.Group == group), "Point");
    }
    catch (e) {
      console.log(e);
      return "";
    }
  }

  const getPointSum = (employee, level) => {
    return getPoint(employee, level, "hoanthanh") + getPoint(employee, level, "nghiemtuc") + getPoint(employee, level, "tichcuc");
  }

  const getIndexDefine = (point) => {
    // console.log(point);
    if (!point) return "";
    let def = reviewDefines.find(f => f.uid == point.PointID);
    if (!def) return "";
    return def.Index;
  }



  const getNotes = (employee) => {
    try {
      let empReview = getEmployeeReview(employee);
      if (!empReview.Level2Approved) return "";
      return empReview.Points2
        .filter(f => f.Note && f.Note.trim().length > 0)
        .map(m => `[${getIndexDefine(m)}] ${m.Note}`).join(', ');
    }
    catch (e) {
      return "";
    }
  }

  return (
    <div className="py-6">
      {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900"></h1>
    </div> */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
        <div className="sm:flex sm:items-center">
          {/* <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Báo cáo đánh giá nhân viên</h1> */}
          {/* <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their name, title, email and role.
                </p> */}
          {/* </div> */}
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Import nhân viên
            </button>
          </div> */}
        </div>

        <div className="mt-8 flex flex-col ">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              <li>
                <div className="flex sm:flex-row flex-col  px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="w-full sm:max-w-xs pr-4">
                      <label htmlFor="email" className="sr-only">
                        Tháng
                      </label>
                      <select
                        id="location"
                        name="location"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                        value={month.value + ""}
                        onChange={changeMonth}
                      >
                        {getMonths().map(m => {
                          return <option key={m.value} value={m.value}>
                            {m.label}
                          </option>
                        })}
                      </select>
                    </div>
                    <div className="w-full sm:max-w-xs pr-4">
                      <label htmlFor="email" className="sr-only">
                        Năm
                      </label>
                      <select
                        id="location"
                        name="location"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                        value={yearMonth.value}
                        onChange={changeYearMonth}
                      >
                        {getYears().map(y => { return <option value={y.value} key={y.value}>{y.label}</option> })}
                      </select>
                    </div>
                  </div>
                  <div className="sm:py-4">
                    {/* <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    {user && user.hpUser && user.hpUser.Role &&
                      <div className={classNames("min-w-0 flex-1 px-4 md:grid md:grid-cols-1")}>

                        <span className={classNames("relative z-0 inline-flex shadow-sm rounded-md")}>
                          <button onClick={() => exportMonth(false)}
                            type="button" disabled={isSearch}
                            className="disabled:bg-slate-100 relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          >
                            Xem
                          </button>
                          <button onClick={() => exportMonth(true)}
                            type="button" disabled={isSearch}
                            className="disabled:bg-slate-100 -ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          >
                            Xuất file
                          </button>
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>


        <div className="mt-8 flex flex-col" style={{ display: isView ? "block" : "none" }}>
          {/* <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> */}
          <table className="min-w-full divide-y divide-gray-300" id="table-report-month">
            <thead className="bg-white">
              <tr>
                <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Mã NV
                </th>
                <th rowSpan={2} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Họ
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tên
                </th>
                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Chức danh
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Nơi công tác
                </th>
                <th rowSpan={2} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Đội/ Cụm
                </th>
                <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Quản lý trực tiếp
                </th>
                <th colSpan={4} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Quản lý cao hơn
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                </th>
              </tr>
              <tr>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Hoàn thành công việc
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Thái độ nghiêm túc
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Thái độ tích cực
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tổng điểm
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Hoàn thành công việc
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Thái độ nghiêm túc
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Thái độ tích cực
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tổng điểm
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Xếp loại
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Ghi chú
                </th>

              </tr>
            </thead>
            <tbody className="bg-white">
              {employies && employies.length > 0 && getSorttEmployeeByTeam(getTeams(), employies).map((team) => (
                <Fragment key={team.Code}>
                  <tr className="border-t border-gray-200">
                    <th
                      colSpan={8}
                      scope="colgroup"
                      className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                    >
                      {team.Name}
                    </th>
                  </tr>
                  {team.List.map((employee, idx) => (
                    <tr
                      key={idx}
                      className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {employee.EmployeeID}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {employee.FirstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Area}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 1, "hoanthanh")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 1, "nghiemtuc")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 1, "tichcuc")}</td>
                      <td className={classNames(getColorByPoint(getPoint(employee, 1, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold")}>{getPointSum(employee, 1)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 2, "hoanthanh")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 2, "nghiemtuc")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getPoint(employee, 2, "tichcuc")}</td>
                      <td className={classNames(getColorByPoint(getPoint(employee, 1, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold")}>{getPointSum(employee, 2)}</td>
                      <td className={classNames(getColorByPoint(getPoint(employee, 1, "")), "whitespace-nowrap px-3 py-4 text-sm font-semibold")}>{getTitleByPoint(getPointSum(employee, 2))}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNotes(employee)}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}

            </tbody>
          </table>
          {employies && employies.length == 0 && <NoData />}
        </div>
        {/* </div>
          </div>
        </div> */}
      </div>
    </div >
  )
}