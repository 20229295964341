/* This example requires Tailwind CSS v2.0+ */
import { async } from '@firebase/util';
import { collection, getDocs, limit, query } from 'firebase/firestore';
import { Fragment, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { checkPageRole, checkUserRoleLoad, classNames, getDays } from '../../helpers'
import { auth, db } from '../../services/firebase';


export default function ReviewConfig() {
    const [user, loading, error] = useAuthState(auth);
    const [defines, setDefines] = useState([]);
    // const [day, setDay] = useState(getDays()[0]);
    const [dayEdit, setDayEdit] = useState(getDays()[0]);
    const [dayStart, setDayStart] = useState(getDays()[0]);
    const [dayHRUnlock, setDayHRUnlock] = useState(getDays()[0]);
    const navigate = useNavigate();
    let params = useParams();

    let appConfigs = useSelector(state => state.config).appConfigs;
    // console.log(appConfigs);

    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["quantri", "nhansu"]);
        // console.log(appConfigs);
    }, [user]);

    useEffect(() => {
        if (!appConfigs || !appConfigs.Review || !appConfigs.Review.ApprovedLimitEdit) return;
        setDayEdit(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitEdit));
        setDayStart(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitStart));
        setDayHRUnlock(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitUnLock));
    }, [appConfigs])


    // const [teams, setTeams] = useState([]);

    // const teamReducer = useSelector((state) => state.team);

    // const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        // if(defines.length > 0) return;

        fetchReviewDefine("hoanthanh");
        fetchReviewDefine("nghiemtuc");
        fetchReviewDefine("tichcuc");
    }, [user, loading, params.teamID]);

    const fetchReviewDefine = async (group) => {
        try {
            if (!params.teamID) return;
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = query(collection(db, "hp-revew-define", params.teamID, group));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                // console.log(doc.data())
                if (!defines.find(f => f.uid === doc.id)) {
                    setDefines((defines) => [...defines, Object.assign({ uid: doc.id, group, teamID: params.teamID }, doc.data())]);
                }
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    const getDefines = (group) => {
        return defines.filter(f => f.teamID === params.teamID && f.group === group);
    }

    const getGroup = () => {
        // console.log(defines);
        return [
            { Name: "Điểm hoàn thành công việc", Data: getDefines("hoanthanh") },
            { Name: "Thái độ Nghiêm túc", Data: getDefines("nghiemtuc") },
            { Name: "Thái độ tích cực", Data: getDefines("tichcuc") },
        ]
    }

    const changeDayEdit = (event) => {
        setDayEdit(getDays().find(f => f.value === parseInt(event.target.value)));
    };

    const changeDayStart = (event) => {
        setDayStart(getDays().find(f => f.value === parseInt(event.target.value)));
    };

    const changeHRDayUnlock = (event) => {
        setDayHRUnlock(getDays().find(f => f.value === parseInt(event.target.value)));
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8 ">
            <div className="mt-10 divide-y divide-gray-200  bg-white rounded-md px-4 py-4">
                <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Thời gian</h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        Vui lòng cẩn thận. Những cấu hình này sẽ ảnh hưởng đến quá trình đánh giá nhân viên.
                    </p>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Ngày mở khoá tối đa (tháng sau)</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                    <select disabled={true}
                                        id="location"
                                        name="location"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md "
                                        value={dayHRUnlock.value + ""}
                                        onChange={changeHRDayUnlock}
                                    >
                                        {getDays().map(m => {
                                            return <option key={m.value} value={m.value}>
                                                {m.label}
                                            </option>
                                        })}
                                    </select>
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    {/* <button 
                                        type="button"
                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        Cập nhật
                                    </button> */}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Ngày sửa duyệt tối đa (tháng sau)</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                    <select disabled={true}
                                        id="location"
                                        name="location"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md "
                                        value={dayEdit.value + ""}
                                        onChange={changeDayEdit}
                                    >
                                        {getDays().map(m => {
                                            return <option key={m.value} value={m.value}>
                                                {m.label}
                                            </option>
                                        })}
                                    </select>
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    {/* <button 
                                        type="button"
                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        Cập nhật
                                    </button> */}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Ngày bắt đầu đánh giá (tháng sau)</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                    {appConfigs.Review && appConfigs.Review.ApprovedLimitStart > 0 && <select disabled={true}
                                        id="location"
                                        name="location"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md "
                                        value={dayStart.value + ""}
                                        onChange={changeDayStart}
                                    >
                                        {getDays().map(m => {
                                            return <option key={m.value} value={m.value}>
                                                {m.label}
                                            </option>
                                        })}
                                    </select>
                                    }
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    {/* <button 
                                        type="button"
                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        Cập nhật
                                    </button> */}
                                </span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}
