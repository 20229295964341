// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";

import { getDatabase } from "firebase/database";

import {
  getAuth,
  // signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { appCheckConfig, firebaseConfig } from "../constant/firebase";

import { getAnalytics } from "firebase/analytics";


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const analytics = getAnalytics(app);

const realDb = getDatabase(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(appCheckConfig),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
// dev only
// remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 15;
remoteConfig.settings.minimumFetchIntervalMillis = 1000;
// lay du lieu ve
fetchAndActivate(remoteConfig);

const getConfigJson = (configName) => {
  try {
    return getValue(remoteConfig, configName);
  } catch (e) {
    console.log(e);
    return null;
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    const errorCode = err.code;
    let errorMessage =
      "Có lỗi trong quá trình sử lý. Vui lòng thử lại hoặc liên hệ quản trị.";
    switch (errorCode) {
      case "auth/user-not-found":
      case "auth/wrong-password":
      case "auth/invalid-credential":
        errorMessage =
          "Không tìm thấy thông tin đăng nhập. Vui lòng kiểm tra lại Email và Mật khẩu.";
        break;
      case "auth/network-request-failed":
        errorMessage =
          "Lỗi kết nối đến hệ thống. Vui lòng kiểm tra lại.";
        break;
      default:
        errorMessage += `(${errorCode})`;
        break;
    }
    alert(errorMessage);
  }
};

const logout = () => {
  signOut(auth);
  // dispatch(setLogOut({ name: null, uid: null, img: null, email: null }));
  // setTimeout(() => {
  //     navigate("/auth/login");
  // }, 200);
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export {
  auth,
  db,
  realDb,
  analytics,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  // registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getConfigJson,
  // getAllConfig
};
