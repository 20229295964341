import { ChartBarIcon, ChatIcon, CogIcon, FolderIcon, HomeIcon, InboxIcon, SupportIcon, UsersIcon } from "@heroicons/react/outline";

export const navigation = [
    { name: 'Dashboard', href: '/app/dashboard', icon: HomeIcon, current: true, Role: [] },
    { name: 'Nhân viên', href: '/app/employee-list', icon: FolderIcon, current: false, Role: ["quantri", "nhansu", "level1", "level2"] },
    { name: 'Đánh giá cấp 1', href: '/app/reviews/multi/1', icon: FolderIcon, current: false, Role: ["level1", "level2"] },
    { name: 'Đánh giá cấp 2', href: '/app/reviews/multi/2', icon: FolderIcon, current: false, Role: ["level1", "level2"] },
    { name: 'Đội nhóm', href: '/app/team-list', icon: UsersIcon, current: false, Role: ["quantri", "nhansu"] },
    // { name: 'Nơi công tác', href: '/app/workplace-list', icon: UsersIcon, current: false, Role: ["quantri", "nhansu"] },
    { name: 'Người dùng', href: '/app/user-list', icon: UsersIcon, current: false, Role: ["quantri", "nhansu"] },
    { name: 'Báo cáo', href: '/app/reports/review/month', icon: ChartBarIcon, current: false, Role: ["level1", "level2", "nhansu"] },
    { name: 'Tiêu chí', href: '/app/configs/review-define', icon: InboxIcon, current: false, Role: ["quantri", "nhansu"] },
    { name: 'Cấu hình', href: '/app/configs/general-config/review', icon: CogIcon, current: false, Role: ["quantri", "nhansu"] },
    // { name: 'Nhắn tin', href: '/app/chats', icon: ChatIcon, current: false, Role: ["quantri", "nhansu", "level1", "level2"] },
]


export const inputTextCss = `mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
invalid:border-pink-500 invalid:text-pink-600
focus:invalid:border-pink-500 focus:invalid:ring-pink-500`;



export const getStatusName = (status) => {
    switch (status) {
        case 1: return "Hoạt động";
        case 0: return "Khoá";
        default: return '';
    }
}

export const getRoleNames = (Role) => {
    if (!Role || Role.length == 0) return '';
    return Role.map(m => {
        switch (m) {
            case 'quantri': return 'Quản trị';
            case 'nhansu': return 'Nhân sự';
            case 'level1': return 'Đánh giá cấp 1';
            case 'level2': return 'Đánh giá cấp 2';
            default: return '';
        }
    }).join();
}
