import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import UserSelectSearch from "../../../components/User/UserSelectSearch";
import { classNames, getMonthAddMonth, getYearAddMonth, hasRole } from "../../../helpers";
import { getObjupdateEmployeeReviewLevel, updateEmployeeReviewLevel } from "../../../app/services"

export const EmployeeLevel = ({ employee, users, requestUpdate }) => {
    const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
    const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false)

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));
    const [currUser1, setCurrUser1] = useState("");
    const [currUser2, setCurrUser2] = useState("");

    useEffect(() => {
        setCurrUser1(employee.Level1);
        setCurrUser2(employee.Level2);

        // console.log(employee);
    }, [employee])

    const getUsers = () => {
        return users
            .filter(f => hasRole(['level1', 'level2'], f.Role))
            .filter(f => f.Status == 1)
            .sort((a, b) => (a.EmployeeID > b.EmployeeID ? 1 : -1));
    }


    const changeLevel = (empID, level) => {
        switch (level) {
            case 1: setCurrUser1(empID); break;
            case 2: setCurrUser2(empID); break;
            default: break;
        }
    }

    const updateEmpLevel = async (level) => {
        if (window.confirm(`Bạn muốn chuyển Người đánh giá cấp ${level} từ [${employee.Level1}] sang [${currUser1}]?`)) {
            let user = getUsers().find(f => f.EmployeeID == (level == 1 ? currUser1 : currUser2));
            // console.log(user);
            if (await updateEmployeeReviewLevel(employee, user, level)) {

                requestUpdate({ ...employee, ...getObjupdateEmployeeReviewLevel(employee, user, level) });
                alert("Cập nhật thành công");
            }
        }
    }

    const updateReady = (level) => {
        switch (level) {
            case 1: return employee.Level1 !== currUser1;
            case 2: return employee.Level2 !== currUser2;
            default: return false;
        }
    }

    const resetEmpLevel = (level) => {
        switch (level) {
            case 1: setCurrUser1(employee.Level1); break;
            case 2: setCurrUser2(employee.Level2); break;
            default: return;
        }
    }

    return <>
        <div className="mt-4 divide-y divide-gray-200">
            <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Người đánh giá</h3>
                <p className="max-w-2xl text-sm text-gray-500">
                    {/* Tháng đánh giá: [{month.label} / {year.label}] */}
                </p>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500 py-4">Cấp 1</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                {/* <UserSelectSearch users={users} /> */}
                                <select
                                    id="location"
                                    name="location"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md "
                                    value={currUser1}
                                    onChange={(event) => changeLevel(event.target.value, 1)}
                                >
                                    {getUsers().map(m => {
                                        return <option key={m.EmployeeID} value={m.EmployeeID}>
                                            {m.EmployeeID + ' - ' + m.FirstName + ' ' + m.LastName}
                                        </option>
                                    })}
                                </select>
                            </span>
                            <span className="ml-4 px-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                <button disabled={!updateReady(1)}
                                    type="button" onClick={() => updateEmpLevel(1)}
                                    className={classNames(updateReady(1) ? " text-purple-600" : "", "rounded-md bg-white font-medium hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                >
                                    Cập nhật
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button onClick={() => resetEmpLevel(1)}
                                    type="button"
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Làm lại
                                </button>
                            </span>
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500 py-4">Cấp 2</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                {/* <UserSelectSearch users={users} /> */}
                                <select
                                    id="location"
                                    name="location"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md "
                                    value={currUser2}
                                    onChange={(event) => changeLevel(event.target.value, 2)}
                                >
                                    {getUsers().map(m => {
                                        return <option key={m.EmployeeID} value={m.EmployeeID}>
                                            {m.EmployeeID + ' - ' + m.FirstName + ' ' + m.LastName}
                                        </option>
                                    })}
                                </select>
                            </span>
                            <span className="ml-4 px-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                <button disabled={!updateReady(2)}
                                    type="button" onClick={() => updateEmpLevel(2)}
                                    className={classNames(updateReady(2) ? " text-purple-600" : "", "rounded-md bg-white font-medium hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                >
                                    Cập nhật
                                </button>
                                <span className="text-gray-300" aria-hidden="true">
                                    |
                                </span>
                                <button onClick={() => resetEmpLevel(2)}
                                    type="button"
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Làm lại
                                </button>
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </>;
}
