import { collection, getDocs, query } from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { addEmployee } from "../../features/employee/employeeSlice";

import { classNames } from "../../helpers";
import { auth, db } from "../../services/firebase";

const TeamList = () => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    // const [teams, setTeams] = useState([]);

    // const  teams = useSelector((state) => state.teams);
    const teamReducer = useSelector((state) => state.team);


    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }
    
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        // fetchTeams();
    }, [user, loading]);

    // const fetchTeams = async () => {
    //     try {
    //         // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
    //         const q = query(collection(db, "hp-team"));
    //         const docs = await getDocs(q);
    //         docs.forEach(async (doc) => {
    //             setTeams((teams) => [...teams, Object.assign({ uid: doc.id }, doc.data())]);
    //         });
    //         // const data = doc.docs[0].data();
    //         // setName(data.name);
    //     } catch (err) {
    //         console.error(err);
    //         alert("An error occured while fetching user data");
    //     }
    // }


    return <div className="py-6">
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900"></h1>
    </div> */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Quản lý Đội nhóm</h1>
                    {/* <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their name, title, email and role.
                </p> */}
                </div>
                {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Import nhân viên
                    </button>
                </div> */}
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full">
                                <thead className="bg-white">
                                    <tr>
                                        {/* <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Mã NV
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Họ
                                        </th> */}
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tên
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Chức danh
                                        </th> */}
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Sửa</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {getTeams().map((team, idx) => (
                                        <tr
                                            key={team.uid}
                                            className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                        >
                                            {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {team.id}
                                            </td> */}
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {team.Name}
                                            </td>
                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.title}</td> */}
                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.email}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.role}</td> */}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                {/* <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                    Sửa<span className="sr-only">, {team.name}</span>
                                                </a> */}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* </Fragment> */}
                                    {/* // ))} */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* /End replace */}
        </div>
    </div>;
};
export default TeamList;