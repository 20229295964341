import { updatePassword } from "firebase/auth";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { auth } from "../../services/firebase";

export const UserChangePassword = () => {

    // const user = useSelector((state) => state.user);
    const [user, loading, error] = useAuthState(auth);

    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    const resetData = () => {
        setPassword("");
        setConfirm("");
    }

    const handleChangePassword = () => {
        if (!password || !confirm) {
            alert("Vui lòng nhập mật khẩu và xác nhận");
            return;
        }
        if (password !== confirm) {
            alert("Mật khẩu và xác nhận không khớp. Vui lòng kiểm tra lại");
            return;
        }
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (regex.test(password) === false) {
            alert("Mật khẩu tối thiểu 8 ký tự và có cả chữ và số");
            return;
        }
        // console.log
        updatePassword(user, password).then(() => {
            // Update successful.
            alert("Đổi mật khẩu thành công");
            resetData();
          }).catch((error) => {
            // An error ocurred
            // ...
            console.log(error);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
          });
    }

    return (
        <div className="mt-10 divide-y divide-gray-200 rounded-md text-gray-700 bg-white px-4">
            {/* <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you share.
                    </p>
                </div> */}
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Mật khẩu mới</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <input
                                type="password" onChange={(event) => setPassword(event.target.value)}
                                name="password" value={password}
                                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">Xác nhận</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <input
                                type="password" onChange={(event) => setConfirm(event.target.value)}
                                name="confirm" value={confirm}
                                className="block w-full min-w-0 flex-1  rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </dd>
                    </div>
                    <div className="py-5">
                        <div className="flex justify-end">
                            <button onClick={resetData}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Làm lại
                            </button>
                            <button onClick={handleChangePassword}
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Đổi mật khẩu
                            </button>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    );
};
