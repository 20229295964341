import { createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";

const initialState = {
    name: null,
    img: null,
    uid: null,
    email: null,
    hpUser: {},
};



const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setHPUser: (state, action) => {
            state.hpUser = action.payload.hpUser;
        },
        setLogin: (state, action) => {
            state.name = action.payload.name;
            state.img = action.payload.img;
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.hpUser = {}
        },
        setLogOut: (state, action) => {
            state.name = null;
            state.img = null;
            state.uid = null;
            state.email = null;
            state.hpUser = {};
        },
    },
});

export const { setLogin, setLogOut, setHPUser } = userSlice.actions;

// export const selectName = (state) => state.user.name;
export const selectImg = (state) => state.user.img;
export const selectUid = (state) => state.user.uid;
export const selectEmail = (state) => state.user.email;

export default userSlice.reducer;