/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    BellIcon,
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuAlt2Icon,
    UsersIcon,
    XIcon,
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import { matchRoutes, Outlet, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'


import { classNames, getLevel, hasRole } from '../helpers'

import { AppLogo } from '../constant/imagepath';

import Breadcrumbs from '../components/Breadcrumbs'
import Noties from '../components/Noties'
import { navigation } from '../helpers/uiHelper'
import { WEB_VERSION } from '../constant/config'




const userNavigation = [
    { name: 'Thông tin tài khoản', href: '/app/user/profile' },
    // { name: 'Cấu hình', href: '/app/user-config' },
    { name: 'Đăng xuất', href: '/auth/logout' },
]


export default function LightHeaderLayout() {
    const user = useSelector((state) => state.user);
    const [showNoties, openNoties] = useState(false);

    const [showNav, setShowNav] = useState(true);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const location = useLocation();
    // let pathname = props.location.pathname


    const getNoties = (isActive) => {
        if (!user.hpUser || !user.hpUser.noties) return [];

        return isActive ? user.hpUser.noties.filter(f => f.Status == 1) : user.hpUser.noties;
    }

    const getNavs = () => {
        // console.log("ok");
        if (!user || !user.hpUser) return navigation;
        return navigation.filter(f => f.current || (user.hpUser && hasRole(f.Role, user.hpUser.Role)));
    }

    return (
        // <>
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src={AppLogo}
                                        alt="Logo"
                                    />
                                    {/* <h1>HÂN PHÚC</h1> */}
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto relative">
                                    <nav className="px-2 space-y-1">
                                        {getNavs().map((item, index) => (
                                            <a
                                                key={item.name + index}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-4 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </a>
                                        ))}
                                    </nav>
                                    <div className="px-4 text-xs absolute bottom-0">Phiên bản {WEB_VERSION}</div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Noties open={showNoties} setOpen={openNoties} noites={getNoties(false)} />
            {/* Static sidebar for desktop */}
            {showNav && <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-40">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4 releative">
                        <img
                            className="h-8 w-auto"
                            src={AppLogo}
                            alt="Logo"
                        />
                        {/* <h1>HÂN PHÚC</h1> */}
                        {showNav && <button
                            type="button"
                            className="absolute right-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setShowNav(false)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                        </button>}
                    </div>
                    <div className="mt-5 flex-grow flex flex-col">
                        <nav className="flex-1 px-2 pb-4 space-y-1">
                            {getNavs().map((item, index) => (
                                <a
                                    key={item.name + index}
                                    href={item.href}
                                    className={classNames(
                                        location.pathname.includes(item.href) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            location.pathname.includes(item.href) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                    <div className="px-4 text-xs">Phiên bản {WEB_VERSION}</div>
                </div>
            </div>}
            <div className={classNames(showNav ? "md:pl-64" : "", "flex flex-col flex-1")}>
                <div className="top-0 left-0 right-0 z-10 flex-shrink-0 flex h-16 bg-white shadow fixed">
                    {showNav && <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>}
                    {showNav == false && <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setShowNav(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    }
                    <div className="flex-1 px-4 flex justify-between">
                        {showNav == false && <div className="flex items-center flex-shrink-0 px-4">
                            <img
                                className="h-8 w-auto"
                                src={AppLogo}
                                alt="Logo"
                            />
                            {/* <h1>HÂN PHÚC</h1> */}
                        </div>
                        }
                        <div className="flex-1 flex">
                            {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                                    <label htmlFor="search-field" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                            <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search-field"
                                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                        />
                                    </div>
                                </form> */}
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">

                            <button
                                type="button" onClick={() => openNoties(true)}
                                className="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="sr-only">Xem thông báo</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                {getNoties(true).length > 0 && <span className="text-xs text-white absolute top-0 right-0 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-red-400">
                                    {getNoties(true).length}
                                </span>
                                }
                            </button>

                            {/* Profile dropdown */}
                            <Menu as="div" className="ml-3 relative">
                                <div>

                                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Open user menu</span>
                                        {/* <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            /> */}
                                        {user && user.hpUser && user.hpUser.FirstName && <span>Xin chào <b>{user?.hpUser?.FirstName + ' ' + user?.hpUser?.LastName}</b></span>}
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map((item, index) => (
                                            <Menu.Item key={item.name + index}>
                                                {({ active }) => (
                                                    <a
                                                        href={item.href}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <Breadcrumbs pathname={location.pathname} navigation={navigation} />
                <main className="flex-1">
                    <Outlet />
                </main>
            </div>
        </div>
        // </>
    )
}
