/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ImportStep from '../../components/ImportStep';
import ImportReviewShort from '../../components/ImportReviewShort';
import axios from 'axios';
import { RECORD_BO_API } from '../../constant/config';
import { db } from '../../services/firebase';
import { collection, doc, getDocs, writeBatch } from 'firebase/firestore';
import { createAreas, createWorkplaces, getObjImportEmployeee } from '../../app/services';
import { getMonthAddMonth, getMSNV, getYearAddMonth, groupBy, simpleCompare } from '../../helpers';
import { async } from '@firebase/util';
import { confirmPasswordReset } from 'firebase/auth';
import { serverTimestamp } from 'firebase/database';

export default function EmployeeImport({ open, setOpen, getNguoiDanhGia, findTeamByKey, findDep }) {
    const cancelButtonRef = useRef(null);

    const [step, setStep] = useState("01");
    const [isImport, setIsImport] = useState(false);

    const [msnvList, setMsnvList] = useState([]);

    const [employiesFile, setEmployiesFile] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");

    // const [countImport, setCountImport] = useState(0);

    const [month, setMonth] = useState(getMonthAddMonth(0));
    const [year, setYear] = useState(getYearAddMonth(0));


    const handleInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        if (employiesFile.length == 0) {
            // setStep("01");
        }
    }, [employiesFile]);

    const calledOnce = useRef(false);

    useEffect(() => {
        // setIsImport(msnvList.length == 0)
        if (calledOnce.current) return;
        calledOnce.current = true;

        fetchMSNV();
    }, [])

    const fetchMSNV = async () => {
        // setMsnvList([]);
        const querySnapshot = await getDocs(collection(db, "hp-employee"));
        querySnapshot.forEach((doc) => {
            // console.log(doc.id);
            setMsnvList((msnvList) => [...msnvList, doc.id]);
        });
    }


    const doReadImportFile = async () => {

        let res = await readImportFile();
        // console.log('ket qua check', res);
        // if (res) {
        //     setStep('02');
        // }
    }

    const doImportFile = async () => {
        if (await ImportFile()) {
            setStep('03');
        }
    }

    const readImportFile = async () => {
        const data = new FormData();
        data.append("file", selectedFile);
        // console.warn(selectedFile);
        setEmployiesFile([]);

        // let msnvList = [];

        axios
            .post(`${RECORD_BO_API}/api/user/import`, data, {})
            .then((res) => {
                let employies = []
                ///
                const headerObj = {
                    Column0: "order_1",
                    Column1: "manv",
                    Column2: "ho",
                    Column3: "ten",
                    Column4: "Chức danh",
                    Column5: "chinhanh",
                    Column6: "Đội/ Cụm",
                    Column7: "Cap 1",
                    Column8: "Cap 1",
                    Column9: "Cap 1",
                    Column10: "Cap 2",
                    Column11: "Cap 2",
                    Column12: "Cap 2",
                    Column13: "Nhóm chức danh"
                };

                var bar = new Promise((resolve, reject) => {
                    res.data.forEach((row, index, array) => {
                        if (index == 0) {
                            // console.log(row);
                            if (simpleCompare(row, headerObj) == false) {
                                reject({ message: "Sai định dạng tiêu đề", row: index });
                            }
                            return false; // dong dau header bo qua
                        }
                        try {
                            employies.push({
                                MSNV: getMSNV(row.Column1),
                                Ho: row.Column2.trim(),
                                Ten: row.Column3.trim(),
                                ChucDanh: row.Column4.trim(),
                                NoiCongTac: row.Column5.trim(),
                                Doi: row.Column6 ? row.Column6.trim() : "",
                                NguoiDanhGia1: getMSNV(row.Column7),
                                TenNguoiDanhGia1: `${row.Column8} ${row.Column9}`,
                                NguoiDanhGia2: getMSNV(row.Column10),
                                TenNguoiDanhGia2: `${row.Column11} ${row.Column12}`,
                                NhomDanhGia: row.Column13.trim(),
                                PhongBan: "",
                            });
                        } catch (e) {
                            console.log(e);
                            // console.log(row);
                        }

                        if (index === res.data.length - 1) resolve();
                    });
                });

                bar.then(() => {
                    checkImportEmployies(employies, msnvList);
                    // console.log('ok');
                    setIsImport(false);
                    setStep('02');
                    return true;
                    // setOpen(false);
                }, (reject) => {
                    alert(`${reject.message} dòng ${reject.row}. Vui lòng kiểm tra lại`);
                    return false;
                })
                    .catch((e) => {
                        alert("Đọc file lỗi. Vui lòng kiểm tra lại hoặc liên hệ quản trị.");
                        // setOpen(false);
                        return false;
                    });
            })
            .catch((error) => console.log(error));
    };

    const ImportFile = async () => {

        // quy luat import file
        // - neu da co: thì update 
        // - chỉ update: chức danh, chi nhanh, đội/cụm, cấp 1, cấp 2, nhóm đánh giá.
        // 
        // - nếu chưa có thì tạo
        // 
        // Get a new write batch

        // employies = employies;

        let importEmpListQL = employiesFile.filter(f => f.ImportLevel1 && f.ImportLevel2);
        let importEmpList = employiesFile.filter(f => f.MSNV);
        if (window.confirm(`
                            Tìm thấy thông tin quản lý ${importEmpListQL.length}/${employiesFile.length} nhân viên.
                            Bạn muốn tạo/cập nhật ${employiesFile.filter(f => f.ImportStatus == 1).length},
                            Khoá ${employiesFile.filter(f => f.ImportStatus == 0).length} nhân viên?
                            `,
        ) == false) return;

        setIsImport(true);
        // setCountImport(0);

        // tao cum doi
        const areas = groupBy(importEmpList, "Doi");
        createAreas(areas, importEmpList);
        // console.log(areas);
        // return;

        // tao noi cong tac
        const workplaces = groupBy(importEmpList, "NoiCongTac");
        createWorkplaces(workplaces, importEmpList);
        // console.log(workplaces);
        // return;


        const chunkSize = 200;
        // console.log(chunkSize, importEmpList);

        for (let i = 0; i < importEmpList.length; i += chunkSize) {
            const chunk = importEmpList.slice(i, i + chunkSize);

            // console.log("chunk", chunk.length, i);

            let batch = writeBatch(db);
            let bar = new Promise((resolve, reject) => {
                chunk.forEach((emp, index, array) => {
                    let msnv = emp.MSNV;
                    const nvRef = doc(db, "hp-employee", msnv);



                    if (emp.ImportStatus == 0) {
                        console.log(emp);
                        batch.update(nvRef, {
                            Status: 0,
                            UpdatedAt: serverTimestamp(),
                        });
                    }
                    else {
                        const empObj = getObjImportEmployeee(emp, findDep(emp.NoiCongTac), findTeamByKey(emp.NhomDanhGia));
                        batch.set(nvRef, empObj);
                    }
                    // tao danh gia
                    // batch.set()
                    if (index === chunk.length - 1) resolve({ End: i + chunk.length == importEmpList.length, curr: chunk.length, i });
                });
            });

            bar.then(async (data) => {
                // Commit the batch
                // console.log(data);
                // setCountImport((countImport) => countImport + chunk.length);
                await batch.commit();
                // console.log(countImport, chunk.length, importEmpList.length);
                if (data.End) {
                    alert("Import dữ liệu thành công");
                    setStep('03');
                    setIsImport(false);
                }

            }, () => {
                alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại.");
                setIsImport(false);
            })
                .catch((e) => {
                    alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại.");
                    setIsImport(false);
                });
        }
    };

    const checkImportEmployies = (employies, msnvList) => {
        // console.log(msnvList);
        let empList = employies.map(m => Object.assign(m, {
            ImportStatus: 1,
            ImportLevel1: m.NguoiDanhGia1,
            ImportLevel2: m.NguoiDanhGia2
        }));
        let msnvListNew = empList.map(m => m.MSNV);
        let missList = msnvList.filter(f => msnvListNew.includes(f) == false);
        console.log(missList);

        console.log(empList.length);
        empList = empList.concat(missList.map(m => {
            return {
                MSNV: m,
                ImportStatus: 0
            }
        }));
        console.log(empList.length);

        // console.log(empList.filter(f => f.ImportStatus == 0));
        empList = empList.map(m => Object.assign(m, {
            ImportType: getImportType(m)
        }));
        // console.log('employies', employies);
        // console.log('empList', empList);

        setEmployiesFile([...empList]);
    }


    const setOpenImport = async (isOpen) => {
        console.log(isOpen);
        if (isOpen == false) {
            setEmployiesFile([]);
            // setMsnvList([]);
            setStep("01");
        } else {
            // setMsnvList([]);
            const querySnapshot = await getDocs(collection(db, "hp-employee"));
            querySnapshot.forEach((doc) => {
                console.log(doc.id);
                setMsnvList((msnvList) => [...msnvList, doc.id]);
            });
        }
        setOpen(isOpen);
    }

    const getImportType = (emp) => {

        // if (emp.ImportLevel1 == "") return -1; // khong import
        // if (emp.ImportLevel2 == "") return -1; // khong import
        if (emp.TeamID == "") return -1; // khong import

        if (emp.ImportStatus == 0) return 0; // khoa
        if (emp.ImportStatus == 1) {
            // if (!exits) return 1; // tao moi
            return 2; // update 
            // if (emp.ImportLevel1)
        }
        return 0; // khoa
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={() => setOpenImport(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6 md:max-w-5xl">
                                <div>
                                    {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div> */}
                                    {/* <div className="mt-3 text-left sm:mt-5 py-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Import nhân viên [{month.label} - {year.label}]
                                        </Dialog.Title>
                                    </div> */}
                                    <div className="mt-3 text-left sm:mt-5 py-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Import nhân viên
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <ImportStep currStep={step} />

                                <div className="flex justify-center overflow-auto">
                                    {step == '01' && <div className="mb-3 w-96">
                                        <label htmlFor="formFile" className="form-label inline-block mb-2 text-gray-700">Chọn file</label>
                                        {/* <span className="sr-only">Chọn file Import</span> */}
                                        <input onChange={handleInputChange} accept=".xlsx,.xls"
                                            type="file"
                                            name="upload_file"
                                            id="formFile"
                                            className="form-control   block  w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                                        rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                                    </div>}
                                    {/* {step == '02' && <ImportReview employiesFile={employiesFile} />} */}
                                    {step == '02' && <ImportReviewShort employiesFile={employiesFile} />}
                                    {step == '03' && <p>Import thành công</p>}
                                    {/* {step == '04' && <p>Import thành công</p>} */}
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    {step === '01' && !isImport &&
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                            onClick={() => doReadImportFile()}
                                        >
                                            Tiếp tục
                                        </button>
                                    }
                                    {step === '02' && !isImport && <button
                                        type="button" disabled={isImport}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => doImportFile()}
                                    >
                                        Import
                                    </button>}

                                    {isImport && <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" disabled>
                                        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">

                                        </svg>
                                        Đang xử lý...
                                    </button>}


                                    <button
                                        type="button" disabled={isImport}
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => setOpenImport(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Đóng
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
