import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { hasRole } from "../../helpers";
// import EmployeeShortInfo from "./EmployeeShortInfo";
// import EmployeeTabs from "./EmpTabs/EmployeeTabs";
import { UserShortInfo } from "./UserShortInfo";
import UserDetailTabs from "./UserTabs/UserDetailTabs";

const UserDetailDialog = ({ open, setOpen, userInfo, reloadData }) => {
    const cancelButtonRef = useRef(null);
    const user = useSelector((state) => state.user);




    // const teamReducer = useSelector((state) => state.team);

    // const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }



    // const getEmployee = () => {
    //     if (!employeeData) return null;
    //     let res = { ...employeeData, Level1Name: getNguoiDanhGiaName(employeeData.Level1), Level2Name: getNguoiDanhGiaName(employeeData.Level2) };

    //     // console.log(employeeData, res, getNguoiDanhGiaName(employeeData.Level1));
    //     return res;
    // }

    return (
        <Transition.Root show={open} as={Fragment} >
            <Dialog as="div" className="relative z-10 max-w-md" initialFocus={cancelButtonRef} onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end md:items-center justify-center min-h-full p-4 text-center md:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            {/* <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:w-95 md:p-6">
                        
                    </Dialog.Panel> */}

                            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                <div className="px-4 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <Dialog.Title className="text-lg font-medium text-gray-900"> Chi tiết người dùng</Dialog.Title>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button" ref={cancelButtonRef}
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                onClick={() => setOpen(false)}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative flex-1 px-4 sm:px-6 text-left">
                                    {/* Replace with your content */}
                                    <UserShortInfo userInfo={userInfo} />
                                    {hasRole(["nhansu", "quantri"], user.hpUser.Role) && <UserDetailTabs userInfo={userInfo} reloadData={reloadData} />}
                                    {/* /End replace */}


                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default UserDetailDialog;