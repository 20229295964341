/* This example requires Tailwind CSS v2.0+ */
import { HomeIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'

// const pages = [
//     { name: 'Projects', href: '#', current: false },
//     { name: 'Project Nero', href: '#', current: true },
// ]

export default function Breadcrumbs({ pathname, navigation }) {
    const [pages, setPages] = useState([]);
    const getName = () => {
        let nav = navigation.find(f => pathname.includes(f.href));
        if (nav) return nav.name;
        if (pathname.includes("/app/user/profile")) return "Thông tin nhân viên";
        if (pathname.includes("/app/reviews/one/1")) return "Đánh giá cấp 1";
        if (pathname.includes("/app/reviews/one/2")) return "Đánh giá cấp 2";
        if (pathname.includes("/app/reviews/multi/1")) return "Đánh giá cấp 1";
        if (pathname.includes("/app/reviews/multi/2")) return "Đánh giá cấp 2";
        if (pathname.includes("/app/reviews/approved")) return "Duyệt tất cả";
        return "";
    }
    useEffect(() => {
        setPages([{ name: getName(), href: pathname, current: true }]);
    }, [])
    return (
        <nav className="bg-white border-b border-gray-200 flex" aria-label="Breadcrumb">
            <ol role="list" className="max-w-screen-xl min-w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
                <li className="flex">
                    <div className="flex items-center">
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Trang chủ</span>
                        </a>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="flex-shrink-0 w-6 h-full text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            <a
                                href={page.href}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
