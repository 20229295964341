import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux'

import { db, auth } from '../../services/firebase';
import { query, collection, getDocs, where, limit, orderBy, } from "firebase/firestore";
import { async } from "@firebase/util";

import EmployeeImport from './EmployeeImport';

import { classNames, getSorttEmployeeByTeam, hasRole, removeAccAndLower, removeAccents, writeExcel } from "../../helpers";

import NoData from "../../components/NoData"

import { LIMIT_GET_DATA } from "../../constant/config";
import { EmployeeDialog } from "./EmployeeDialog";


const EmployeeList = () => {
    // const employies = useSelector((state) => state.employeeList.value);
    const [employies, setEmployies] = useState(null);
    const [employiesExp, setEmployiesExp] = useState([]);


    // const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [areas, setAreas] = useState([]);
    const [currArea, setCurrArea] = useState("-1");

    const [showImport, setShowImport] = useState(false);
    const [showEmployee, setShowEmployee] = useState(false);
    const [empDetail, setEmpDetail] = useState(null);

    const [loading, error] = useAuthState(auth);
    const user = useSelector((state) => state.user);

    const [name, setName] = useState("");

    // const [open, setOpen] = useState(false);


    const [departments, setDepartments] = useState([]);


    const [searchTeam, setSearchTeam] = useState(-1);



    const navigate = useNavigate();

    const [mnv, setMNV] = useState("");
    const [tenNV, setTenNV] = useState("");

    useEffect(() => {
        // console.log("users", users.length);
        if (users && users.length > 0) {
            // console.log(user);
            if (areas.length == 0) {
                fetchAreas();
            }
        }
    }, [users]);

    const teamReducer = useSelector((state) => state.team);

    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }

    const getQuery = (employiesData, isGetMore, isFull) => {
        let res = false;
        if (!user.hpUser || !user.hpUser.Role) return null;

        let q = query(collection(db, "hp-employee"));

        let limitNumber = LIMIT_GET_DATA;


        if (tenNV) {
            console.log(tenNV.trim());
            let nameSearch = removeAccAndLower(tenNV).trim();
            // console.log(nameSearch, [nameSearch.split(' ')]);
            // q = query(q, where("LastName", "==", tenNV.trim()));
            // q = query(q, where("SearchName", "in", [nameSearch.split(' ')]));
            q = query(q, where("SearchName", "array-contains", nameSearch));
        }

        if (mnv) {
            console.log(mnv);
            q = query(q, where("EmployeeID", "==", mnv));
        } else {
            q = query(q, orderBy("EmployeeID"));
        }

        let indexArea = areas.find(f => f.uid == currArea);

        if (indexArea) {
            q = query(q, where("Area", "==", indexArea.Name));
        }

        // console.log(currStatus);

        if (currStatus != "-1") {

            q = query(q, where("Status", "==", currStatus * 1));
        }

        if (searchTeam != "-1" && getTeams().find(f => f.uid == searchTeam) != null) {
            // q = (query(q, where("TeamID", "==", searchTeam)));
        }


        if (hasRole(["nhansu", "quantri"], user.hpUser.Role)) {
            // khong can bo sung gi
            res = true;
        }
        else if (hasRole(["level1", "level2"], user.hpUser.Role)) {
            // console.log("Level", user.uid, user.hpUser.Role);
            if (tenNV) {
                // q = query(q, where("Level2", "==", user.uid));
                limitNumber = LIMIT_GET_DATA + 100;
            } else {
                q = query(q, where("Level", "array-contains", user.hpUser.EmployeeID));
            }
            res = true;
        }

        if (isGetMore && employiesData.length > 0) {
            q = query(q, where("EmployeeID", ">", employiesData[employiesData.length - 1].EmployeeID));
        }

        q = query(q, limit(isFull ? 10000 : limitNumber));
        // console.log(queres);
        if (res) return q;
        return null;

    }
    const fetchEmployee = async (isGetMore) => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = getQuery(employies, isGetMore, false);
            // console.log('fetch employee q: ', q);

            if (!q) return;



            const docs = await getDocs(q);
            docs.forEach((doc) => {
                if (hasRole(["level1", "level2"], user.hpUser.Role)) {
                    if (!doc.data().Level.includes(user.hpUser.EmployeeID)) return;
                }
                setEmployies((employies) => [...employies, doc.data()]);
                // console.log(doc.id, doc.data());
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
            // alert("An error occured while fetching user data");
        }
    };

    const fetchUsers = async () => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            const q = query(collection(db, "hp-user"));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                // console.log(doc.data());
                setUsers((users) => [...users, Object.assign({ uid: doc.id }, doc.data())]);
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    const fetchAreas = async () => {
        try {
            // console.log(user.hpUser, user.hpUser.Role);
            let q = query(collection(db, "hp-area"));
            if (hasRole(["level1", "level2"], user.hpUser.Role)) {
                q = query(q, where("Level", "array-contains", user.hpUser.EmployeeID + ""));
            }
            // console.log(q);
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                setAreas((areas) => [...areas, Object.assign({ uid: doc.id }, doc.data())]);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const calledOnce = useRef(false);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");


        // if (!user || !user.hpUser || !user.hpUser.Role) return null;
        // setEmployies([]);
        // fetchEmployee();

        // fetchTeams();
        if (calledOnce.current) {
            return;
        }
        // if (user) {
        // console.log('I run only once if toggle is false.');

        calledOnce.current = true;
        fetchUsers();
        // fetchAreas();
        // }
    }, [user, loading]);

    const findDep = (depName) => {
        let dep = departments.find(d => d.ShortName === depName);
        // console.log(dep);
        if (dep) return dep.id;
        return "";
    }

    const findTeamByKey = (teamStr) => {
        let team = getTeams().find(f => teamStr.includes(f.KeyName));
        // console.log(team);
        if (team) return team.uid;
        return "";
    }
    const searchEmployee = () => {
        setEmployies([]);
        fetchEmployee(false);
    }

    const exportEmployee = async () => {
        setEmployiesExp([]);

        try {

            let q = getQuery(employiesExp, false, true);
            let docs = await getDocs(q);
            let count = 0;

            docs.forEach((doc) => {
                count++;
                if (hasRole(["level1", "level2"], user.hpUser.Role)) {
                    if (!doc.data().Level.includes(user.hpUser.EmployeeID)) return;
                }
                setEmployiesExp((employiesExp) => [...employiesExp, doc.data()]);
                if (count >= docs.size) {
                    alert("Xuất file thành công");
                    setTimeout(() => {
                        writeExcel("danh-sach-nhan-vien-exp", "danh-sach-nhan-vien");
                    }, 500);
                }
            });
        } catch (err) {
            console.error(err);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
            // alert("An error occured while fetching user data");
        }
    }

    const getMoreData = () => {
        fetchEmployee(true);
    }

    const getNguoiDanhGia = (FullName) => {
        if (!FullName) return "";
        let user = users.find(f => f.FullName === FullName);
        if (user) return user.uid;
        return "";
    }

    const getNguoiDanhGias = (FullName1, FullName2) => {
        let res = [];
        if (getNguoiDanhGia(FullName1)) {
            res.push(getNguoiDanhGia(FullName1));
        }
        if (getNguoiDanhGia(FullName2)) {
            res.push(getNguoiDanhGia(FullName2));
        }
        return res;
    }

    const getNguoiDanhGiaName = (LevelID) => {
        if (!LevelID) return "";
        let userRev = users.find(f => f.uid === LevelID);
        // console.log(LevelID, users, userRev);
        if (userRev && userRev.FullName) return userRev.FullName;
        if (userRev && userRev.FirstName && userRev.LastName) return (userRev.FirstName + " " + userRev.LastName);

        // da chuyen sang ma nhan vien

        return LevelID;
    }

    const getEmployeeStatusName = (emp) => {
        switch (emp.Status) {
            case 1: return 'Hoạt động';
            case 0: return 'Không hoạt động';
            default: return '';
        }
    }

    const showEmpDetail = (event, employee) => {
        event.preventDefault();
        // console.log(employee);
        setShowEmployee(true);
        setEmpDetail(employee);
    }

    const getAreas = () => {
        if (!areas) return [];
        return [...areas].sort((a, b) => a.Name > b.Name ? 1 : -1);
    }

    const changeArea = (event) => {
        // console.log(event.target.value);
        setCurrArea(event.target.value);
    }

    const [currStatus, setCurrStatus] = useState("-1");

    const changeStatus = (event) => {
        setCurrStatus(event.target.value);
    }

    const requestUpdate = (emp) => {
        setEmployies(employies.map(m => m.EmployeeID == emp.EmployeeID ? emp : m));
        setShowEmployee(false);
    }

    return <div className="py-6">
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
        </div> */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div className="sm:flex sm:items-center py-4">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Quản lý nhân viên</h1>
                    {/* <p className="mt-2 text-sm text-gray-700">
                        A list of all the users in your account including their name, title, email and role.
                    </p> */}
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    {hasRole(["nhansu"], user.hpUser.Role) && <button
                        onClick={() => setShowImport(true)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Import nhân viên
                    </button>
                    }
                </div>
            </div>
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 sm:rounded-lg">
                <div className="-ml-4 -mt-2 flex sm:flex-row flex-col">
                    <div className="min-w-0 px-4">
                        <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                            Mã nhân viên
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="manv"
                                id="manv"
                                value={mnv}
                                onChange={(event) => setMNV(event.target.value)}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="min-w-0 px-4">
                        <label htmlFor="email" className="block text-left text-sm font-medium text-gray-700">
                            Tên nhân viên
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="tennv"
                                id="tennv"
                                valeu={tenNV}
                                onChange={(event) => setTenNV(event.target.value)}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="min-w-0 px-4">
                        <label htmlFor="location" className="block text-left text-sm font-medium text-gray-700">
                            Nhóm
                        </label>
                        <select
                            id="location"
                            name="location"
                            onChange={(event) => setSearchTeam(event.target.value + "")}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            value={searchTeam + ""}
                        >
                            <option value="-1">-- Tất cả --</option>
                            {getTeams().map(team => <option key={team.uid} value={team.uid}>{team.Name}</option>)}
                        </select>
                    </div>

                    <div className="min-w-0 px-4">
                        <label htmlFor="location" className="block text-left text-sm font-medium text-gray-700">
                            Đội/ Cụm
                        </label>
                        <select
                            id="location"
                            name="location" onChange={changeArea}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue={"-1"}
                        >
                            <option value="-1">-- Tất cả --</option>
                            {areas && areas.length > 0 && getAreas().map(are => {
                                return <option key={are.uid} value={are.uid}>{are.Name}</option>
                            })}
                        </select>
                    </div>
                    <div className="min-w-0 px-4">
                        <label htmlFor="status" className="block text-left text-sm font-medium text-gray-700">
                            Trạng thái
                        </label>
                        <select
                            id="status"
                            name="status" onChange={changeStatus}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue={"-1"}
                        >
                            <option value="-1">-- Tất cả --</option>
                            <option value="1">Hoạt động</option>
                            <option value="0">Khoá</option>
                        </select>
                    </div>
                    <div className="ml-4 mt-6 flex-shrink-0">
                        {/* <label htmlFor="location" style={{ color: "white" }} className="block text-left text-sm font-medium text-gray-700">
                            .sdf
                        </label> */}

                        {users && users.length > 0 && <button
                            type="button" onClick={exportEmployee}
                            className="inline-flex items-center px-4 py-2 mr-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Xuất Excel
                        </button>}

                        {users && users.length > 0 && <button
                            type="button" onClick={searchEmployee}
                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Tìm
                        </button>}
                    </div>
                </div>
            </div>

            {employies && <div className="mt-8 flex flex-col">
                {/* <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 align-middle md:px-6 lg:px-8  min-w-full w-full">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"> */}
                <table className="min-w-full">
                    <thead className="bg-white">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Mã NV
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Họ
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Tên
                            </th>
                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Chức danh
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Nơi công tác
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Đội/ Cụm
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Người đánh giá cấp 1
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Người đánh giá cấp 2
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Trạng thái
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Chức năng</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {employies && employies.length > 0 && getSorttEmployeeByTeam(getTeams(), employies).map((team) => (
                            <Fragment key={team.Code}>
                                <tr className="border-t border-gray-200">
                                    <th
                                        colSpan={8}
                                        scope="colgroup"
                                        className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                    >
                                        {team.Name}
                                    </th>
                                </tr>
                                {team.List.map((employee, idx) => (
                                    <tr
                                        key={idx}
                                        className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                    >
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {employee.EmployeeID}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {employee.FirstName}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Email}</td> */}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Area}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNguoiDanhGiaName(employee.Level1)}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNguoiDanhGiaName(employee.Level2)}</td>
                                        <td className={classNames(employee.Status == 1 ? "text-green-500" : "text-gray-500", "whitespace-nowrap px-3 py-4 text-sm ")}>{getEmployeeStatusName(employee)}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <a href="!#" className="text-indigo-600 hover:text-indigo-900" onClick={(event) => showEmpDetail(event, employee)}>
                                                Chi tiết<span className="sr-only">, {employee.Name}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </Fragment>
                        ))}
                    </tbody>
                    {employies && employies.length > 0 && <tfoot>
                        <tr>
                            <td colSpan={8} className="px-4 py-4">
                                <button
                                    type="button" onClick={getMoreData}
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Lấy thêm dữ liệu
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                    }
                </table>
                {employies && employies.length == 0 && <NoData />}
                {/* </div>
                    </div>
                </div> */}
            </div>
            }


            <table className="min-w-full" id="danh-sach-nhan-vien-exp" style={{ display: "none" }}>
                <thead className="bg-white">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Mã NV
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Họ
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Tên
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th> */}
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Chức danh
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Nơi công tác
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Đội/ Cụm
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Người đánh giá cấp 1
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Người đánh giá cấp 2
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Trạng thái
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {employiesExp && employiesExp.length > 0 && getSorttEmployeeByTeam(getTeams(), employiesExp).map((team) => (
                        <Fragment key={team.Code}>
                            <tr className="border-t border-gray-200">
                                <th
                                    colSpan={8}
                                    scope="colgroup"
                                    className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                >
                                    {team.Name}
                                </th>
                            </tr>
                            {team.List.map((employee, idx) => (
                                <tr
                                    key={idx}
                                    className={classNames(idx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {employee.EmployeeID}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {employee.FirstName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{employee.LastName}</td>
                                    {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Email}</td> */}
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Title}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.WorkPlace}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employee.Area}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNguoiDanhGiaName(employee.Level1)}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNguoiDanhGiaName(employee.Level2)}</td>
                                    <td className={classNames(employee.Status == 1 ? "text-green-500" : "text-gray-500", "whitespace-nowrap px-3 py-4 text-sm ")}>{getEmployeeStatusName(employee)}</td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </table>

            {/* /End replace */}
            <EmployeeDialog requestUpdate={requestUpdate} open={showEmployee} setOpen={setShowEmployee} employeeData={empDetail} users={users} getNguoiDanhGiaName={getNguoiDanhGiaName} />
            <EmployeeImport open={showImport} setOpen={setShowImport} getNguoiDanhGia={getNguoiDanhGia} findTeamByKey={findTeamByKey} findDep={findDep} />
        </div>
    </div >;
};
export default EmployeeList;