
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, } from "react-router-dom"
import { classNames, hasRole } from "../../helpers"

import { matchRoutes, useLocation } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, doc, getDoc, query, where } from "firebase/firestore";
import { auth, db } from "../../services/firebase";
import { useSelector } from "react-redux";



export default function MainReport() {
    const user = useSelector((state) => state.user);

    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const [loading, error] = useAuthState(auth);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        // console.log('user', user);
        // fetchUser();
    }, [user, loading]);


    const ReportTabs = [
        { name: 'Báo cáo tháng', href: '/app/reports/review/month/', current: location.pathname.includes("/app/reports/review/month"), Role: ["level1", "level2", "nhansu"] },
        { name: 'Báo cáo năm', href: '/app/reports/review/year/', current: location.pathname.includes("/app/reports/review/year"), Role: ["level1", "level2", "nhansu"] },
        // { name: 'Trạng thái', href: '/app/reports/review/status/', current: location.pathname.includes("/app/reports/review/status"), Role: ["nhansu"] },
    ];

    const getTabs = () => {
        // console.log(user);
        if (!user || !user.hpUser) return [];
        return ReportTabs.filter(f => hasRole(f.Role, user.hpUser.Role));
    }

    const changeTab = (event) => {
        navigate(event.target.value);
    }

    return (
        <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
                <h1 className="text-2xl font-semibold text-gray-900">Báo cáo đánh giá nhân viên</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 min-w-full w-full">
                <div>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={changeTab}
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        // defaultValue={getTabs().find((tab) => tab.current).name}
                        >
                            {getTabs().map((tab) => (
                                <option key={tab.name} value={tab.href}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200 px-4">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {getTabs().map((tab) => (
                                    <a
                                        key={tab.name}
                                        href={tab.href}
                                        className={classNames(
                                            tab.current
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={tab.current ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <ReviewDefine /> */}
                {user && user.uid && <Outlet />}
            </div>
        </div>
    )
}
