import { Fragment, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { NOTE_MAX_LENGTH } from "../../constant/config";

import { checkEditReviewDate, classNames, getLevel, hasRole, sumArray } from "../../helpers"

import moment from 'moment';
import { inputTextCss } from "../../helpers/uiHelper";

export default function ReviewDetail({ month, year, defines, hpUser, employeeReview, reviewLevel, updateReview, approveReview, employee, setOpen, unLockApproved }) {

    const calledOnce = useRef(false);
    const [points1, setPoints1] = useState([]);
    const [points2, setPoints2] = useState([]);

    const config = useSelector((state) => state.config);

    const [editApproved, setEditApproved] = useState(true);


    let appConfigs = useSelector(state => state.config).appConfigs;

    useEffect(() => {
        // console.log(employies.length);
        // if (!appConfigs.Review) return;
        // console.log(appConfigs.Review.ApprovedLimitEdit, moment(new Date()).date());
        setEditApproved(appConfigs.Review.ApprovedLimitEdit > moment(new Date()).date());
    }, [appConfigs]);

    // const NOTE_MAX_LENGTH = config.appConfigs.Li

    useEffect(() => {
        // console.log(employeeReview);
        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;
        setPoints1(employeeReview.Points1 || []);
        setPoints2(employeeReview.Points2 || []);

        // console.log("employeeReview", employeeReview, reviewLevel, reviewLevel == 2, employeeReview.Level1Approved);
    }, [])

    const getPointItemIndex = (Points, define, pointItem) => {
        if (!Points || Points.length == 0) return -1;

        let itemPoint = Points.findIndex(f => f.PointID === pointItem.uid);

        return itemPoint;
    }

    const getPoint = (define, pointItem, Level, isPoint) => {
        let points = Level == 1 ? points1 : points2;

        if(!define || !pointItem || !points) return 0;


        let itemIdx = getPointItemIndex(points, define, pointItem);
        // console.log(isPoint, itemIdx, points[itemIdx], points[itemIdx]?.Point, define, pointItem, points);
        // console.log('-------------------')
        if (itemIdx >= 0) {
            if (isPoint) return points[itemIdx].Point;
            return points[itemIdx].Note;
        }
        return isPoint ? 0 : "";
    }

    const setPoint = (event, define, pointItem, Level, isPoint) => {
        // console.log(event.target.value, define, Order, employeeReview, Level);
        let newPoint = 0;
        try {
            newPoint = parseInt(event.target.value);
            if (newPoint > pointItem.Point || newPoint < 0) return;
        } catch (e) {
            console.log(e);
            newPoint = 0;
            return;
        }

        let points = Level == 1 ? points1 : points2;
        let indexPoint = getPointItemIndex(points, define, pointItem);
        // console.log(define);
        if (indexPoint < 0) {
            points.push({
                Group: define.Group,
                TeamID: define.TeamID,
                Point: 0,
                Note: "",
                PointID: pointItem.uid,
                Level: Level,
            });
            indexPoint = points.length - 1;
        }

        let point = points[indexPoint];
        // console.log(indexPoint, point, points);
        if (isPoint) {
            point.Point = newPoint;
            // console.log(point, pointItem);
            // if (point.Point > pointItem.Point || point.Point < 0) return;
        }
        else {
            point.Note = event.target.value;
        }

        points[indexPoint] = point;

        if (Level == 1) {
            setPoints1([...points]);
        } else {
            setPoints2([...points]);
        }
    }

    const savePoint = async () => {

        if (reviewLevel == 1) {
            if (!checkGhiChu(points1)) return;
            if (employeeReview.Level1Approved && employeeReview.Level1ApprovedLock) {
                alert("Đã duyệt");
                return;
            }
            if (!checkPoint(points1)) return;
            await updateReview(points1);
        } else {
            if (!checkGhiChu(points2)) return;
            if (employeeReview.Level2Approved && employeeReview.Level2ApprovedLock) {
                alert("Đã duyệt");
                return;
            }
            if (!checkPoint(points2)) return;
            await updateReview(points2);
        }
        if (setOpen) setOpen(false);
    }


    const isApproved = () => {
        // console.log(employeeReview);
        if (reviewLevel == 1) {
            return employeeReview.Level1Approved;
        }

        if (reviewLevel == 2) {
            return employeeReview.Level2ApprovedLock;
        }
        return true;
    }
    const checkPoint = (points) => {
        if (sumArray(points, "Point") > 100) {
            alert("Vui lòng kiểm tra lại điểm");
            return false;
        }
        return true;
    }

    const checkGhiChu = (points) => {
        if (points.filter(p => p.Group === 'tichcuc' &&
            (!p.Note || p.Note.trim().length == 0) && p.Point > 0).length > 0) {
            alert("Vui lòng nhập ghi chú đánh giá thái độ tích cực");
            // return;
            return false;
        }
        return true;
    }

    const approvePoint = () => {

        if (window.confirm("Bạn chắc chắn muốn duyệt?")) {
            if (reviewLevel == 1) {
                if (checkGhiChu(points1) == false || checkPoint(points1) == false) {
                    return;
                }
                if (employeeReview.Level1Approved) {
                    alert("Đã duyệt");
                    return;
                }
                approveReview(points1);
            } else {
                if (checkGhiChu(points2) == false || checkPoint(points2) == false) {
                    return;
                }
                if (employeeReview.Level2Approved && employeeReview.Level2ApprovedLock) {
                    alert("Đã duyệt");
                    return;
                }
                approveReview(points2);
            }
            if (setOpen) setOpen(false);
        }
    }

    const requestUnLockApproved = () => {
        // console.log(employee)
        if (window.confirm(`Bạn chắc chắn mở duyệt cho nhân viên [${employee.EmployeeID}-${employee.FirstName} ${employee.LastName}] ?`)) {
            unLockApproved(employeeReview);
        }
    }

    const showReviewApproved = (empRev) => {
        if (checkEditReviewDate(appConfigs) == true) return true;
        // console.log(empRev);
        // neu cap 1 thi cung khoa luon
        if (reviewLevel == 1 && empRev.Level1ApprovedLock) return false;

        // neu ngoai thoi gian ma da duyet, thi neu mo danh gia thi cung mo ra
        if (empRev && empRev.Level2ApprovedLock == false) return true;
        return false;
    }

    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 sm:px-6 lg:px-8 py-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Đánh giá nhân viên [{employee.EmployeeID}-{employee.FirstName} {employee.LastName}] [{month.label} / {year.label}]</h1>
                        {/* <p className="mt-2 text-sm text-gray-700">
                            {month.label} / {year.label}
                        </p> */}
                    </div>
                </div>
                <div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0 pb-8">
                    <table className="min-w-full">
                        <thead className="bg-white">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    #
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tiêu chí
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Điểm tối đa
                                </th>
                                {reviewLevel >= 1 &&
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Điểm cấp 1
                                    </th>}
                                {reviewLevel >= 1 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Ghi chú cấp 1
                                </th>}
                                {reviewLevel == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Điểm cấp 2
                                </th>}
                                {reviewLevel == 2 && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Ghi chú cấp 2
                                </th>}
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {defines.map((def, idx) => (
                                <Fragment key={idx}>
                                    <tr className="border-t border-gray-200">
                                        <th
                                            scope="colgroup"
                                            className="bg-gray-50 px-4 py-1 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                        >
                                            {(idx + 1)}
                                        </th>
                                        <th
                                            colSpan={4}
                                            scope="colgroup"
                                            className="bg-gray-50 px-4 py-1 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                        >
                                            {def.Name}
                                        </th>
                                    </tr>
                                    {def.Data.map((item, itemIdx) => (
                                        <tr
                                            key={item.uid}
                                            className={classNames(itemIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                        >
                                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {(idx + 1) + "." + (itemIdx + 1)}
                                            </td>
                                            <td className="whitespace-nowrap text-left px-3 py-2 text-sm text-gray-500">{item.Name}</td>
                                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item.Point}</td>
                                            {reviewLevel >= 1 && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                <input type="number" min={0} max={item.Point} value={getPoint(def, item, 1, true)}
                                                    readOnly={reviewLevel == 2 || (employeeReview.Level1Approved && employeeReview.Level1ApprovedLock)} onChange={(event) => setPoint(event, def, item, 1, true)}
                                                    className={inputTextCss} />
                                            </td>}
                                            {reviewLevel >= 1 && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                <input
                                                    readOnly={reviewLevel == 2 || (employeeReview.Level1Approved && employeeReview.Level1ApprovedLock)} value={getPoint(def, item, 1, false)}
                                                    onChange={(event) => setPoint(event, def, item, 1, false)}
                                                    required={def.Group == 'tichcuc' && getPoint(def, item, 1, true) > 0}
                                                    className={inputTextCss} type="text" minLength={0} maxLength={NOTE_MAX_LENGTH} />
                                            </td>}
                                            {reviewLevel == 2 && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                <input type="number" min={0} max={item.Point} value={getPoint(def, item, 2, true)}
                                                    onChange={(event) => setPoint(event, def, item, 2, true)}
                                                    readOnly={isApproved()}
                                                    className={inputTextCss} />
                                            </td>}
                                            {reviewLevel == 2 && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                <input onChange={(event) => setPoint(event, def, item, 2, false)} value={getPoint(def, item, 2, false)}
                                                    readOnly={isApproved()}

                                                    required={def.Group == 'tichcuc'}
                                                    className={inputTextCss} type="text" minLength={0} maxLength={NOTE_MAX_LENGTH} />
                                            </td>}
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                        </tbody>
                        <tbody>
                            <tr>
                                <td className="text-right font-semibold" colSpan={3}>Tổng</td>
                                <td className="text-right px-4 font-semibold" >{sumArray(points1, "Point")}</td>
                                <td className="text-center px-4 font-semibold" >{employeeReview.Level1Approved ? "Đã duyệt" : "Chưa duyệt"}</td>
                                {reviewLevel == 2 && <td className="text-center px-4 font-semibold">{sumArray(points2, "Point")}</td>}
                                {reviewLevel == 2 && <td>{employeeReview.Level2Approved ? "Đã duyệt" : "Chưa duyệt"}</td>}
                            </tr>
                        </tbody>
                        <tfoot>
                            {(reviewLevel == 1 || (reviewLevel == 2 && employeeReview.Level1Approved)) && <tr className="py-4">
                                <td colSpan={7} className="relative">
                                    {/* {hasRole(["level1", "level2"], hpUser.Role) && reviewLevel == 1 &&
                                        <button
                                            type="button" onClick={approvePoint}
                                            disabled={reviewLevel == 1 ? employeeReview.Level1Approved : (!employeeReview.Level1Approved || employeeReview.Level2Approved)}
                                            className=" absolute left-0 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            Duyệt
                                        </button>
                                    } */}
                                    {/* {hasRole(["level1", "level2"], hpUser.Role) && reviewLevel == 2 && !isApproved() &&
                                        <button
                                            type="button" onClick={approvePoint}
                                            className=" absolute left-0 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            Duyệt
                                        </button>
                                    } */}
                                    {/* // cap 2 hoac cap 1 ma cap 2 chua duyet */}
                                    {showReviewApproved(employeeReview) && hasRole(["level1", "level2"], hpUser.Role) && (reviewLevel == 2 || (reviewLevel == 1 && !employeeReview.Level2Approved)) && editApproved && isApproved() &&
                                        <button
                                            type="button" onClick={requestUnLockApproved}
                                            className=" absolute left-0 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                        >
                                            Mở duyệt
                                        </button>
                                    }
                                    {/* // cap 2 hoac cap 1 ma cap 2 chua duyet */}
                                    {showReviewApproved(employeeReview) && reviewLevel == 2 && (!employeeReview.Level2Approved || !employeeReview.Level2ApprovedLock) &&
                                        <span className="z-0 inline-flex shadow-sm rounded-md px-4 absolute right-0">

                                            {/* <button
                                                type="button" onClick={copyPoints}
                                                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                Chép từ cấp 1
                                            </button> */}

                                            <button onClick={savePoint} type="button"
                                                className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                Lưu
                                            </button>
                                        </span>
                                    }
                                    {showReviewApproved(employeeReview) && reviewLevel == 1 && (!employeeReview.Level1Approved || !employeeReview.Level1ApprovedLock) && <span className=" z-0 inline-flex  px-4  absolute right-0">
                                        <button
                                            type="button" onClick={savePoint} // disabled={employeeReview.Level1Approved}
                                            className="px-4 inline-flex items-center justify-center bg-white text-sm  rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700  shadow-sm hover:bg-gray-50  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                            Lưu
                                        </button></span>
                                    }
                                </td>
                            </tr>
                            }
                        </tfoot>
                    </table>
                </div >
            </div >
        </div >
    )
}
