import { Switch } from "@headlessui/react";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { classNames } from "../../helpers";
import { auth } from "../../services/firebase";

import { useSelector, useDispatch } from "react-redux";
import { UserShortInfo } from "./UserShortInfo";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const tabs = [
  { name: "Thông tin cá nhân", href: "/app/user/profile", current: true },
  { name: "Mật khẩu", href: "/app/user/password", current: false },
  // { name: 'Notifications', href: '#', current: false },
  // { name: 'Plan', href: '#', current: false },
  // { name: 'Billing', href: '#', current: false },
  // { name: 'Team Members', href: '#', current: false },
];

const UserProfile = () => {
  // const [user, loading, error] = useAuthState(auth);

  const user = useSelector((state) => state.user);

  // console.log(user);
  const location = useLocation();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false);

  const changeTab = (event) => {
    navigate(event.target.value);
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
        <div className="px-4 sm:px-6 md:px-0">
          <h1 className="text-3xl font-extrabold text-gray-900">
            Thông tin tài khoản
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-0 ">
          <div className="py-6">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                onChange={changeTab}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      className={classNames(
                        location.pathname.includes(tab.href)
                          ? "border-purple-500 text-purple-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>

            {/* Description list with inline editing */}
            {/* <UserShortInfo user={user} /> */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
