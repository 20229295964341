import { async } from "@firebase/util";
import { query } from "firebase/database";
import { collection, getDocs, limit, orderBy, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import NoData from "../../../components/NoData";
import { classNames, getColorByPoint, getDays, getEmployeeReviewBg, getFullMonth, getMonthAddMonth, getYearAddMonth } from "../../../helpers";
import { db } from "../../../services/firebase";

import { alertUnLockApprover2User, createReviewLock, unLockAroveReviewAdmin } from "../../../app/services"
import { useSelector } from "react-redux";
import moment from "moment";

/* This example requires Tailwind CSS v2.0+ */
// const people = [
//     {
//         name: 'Lindsay Walton',
//         title: 'Front-end Developer',
//         department: 'Optimization',
//         email: 'lindsay.walton@example.com',
//         role: 'Member',
//         image:
//             'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     // More people...
// ]

export default function EmployeeReviews({ employee }) {

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));

    const [dayUnlock, setDayUnlock] = useState(getDays()[0]);
    let appConfigs = useSelector(state => state.config).appConfigs;
    // const [dayStart, setDayStart] = useState(getDays()[0]);
    useEffect(() => {
        if (!appConfigs || !appConfigs.Review || !appConfigs.Review.ApprovedLimitUnLock) return;
        setDayUnlock(getDays().find(f => f.value === appConfigs.Review.ApprovedLimitUnLock));
    }, [appConfigs])

    const calledOnce = useRef(false);

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        if (calledOnce.current) return;
        calledOnce.current = true;

        // fetchReviews();
        checkReviewExits();
    }, []);

    useEffect(() => {
        // console.log(reviews.length);
    }, [reviews]);

    const fetchReviews = async () => {
        // lay 12 thang gan nhat
        // setReviews([]);
        const q = query(collection(db, "hp-review"),
            where("EmployeeID", "==", employee.EmployeeID),
            orderBy("FullMonth", "desc"),
            limit(12));

        const docs = await getDocs(q);

        // console.log(docs.size);
        // if(docs.size == 0){

        // }
        docs.forEach(doc => {
            let review = { ...doc.data(), uid: doc.id };
            // setReviews([...reviews, review]);
            // if (reviews.find(item => item.uid == review.uid)) {
            //     setReviews(reviews.map(item => item.uid == review.uid ? review : item));
            // } else {
            setReviews((reviews) => [...reviews, review]);
        });
    }
    const checkReviewExits = async () => {
        // lay 12 thang gan nhat
        // setReviews([]);
        const q = query(collection(db, "hp-review"),
            where("EmployeeID", "==", employee.EmployeeID),
            where("FullMonth", "==", getFullMonth(month, year)));

        const docs = await getDocs(q);

        // console.log(docs.size);
        if (docs.size == 0) {
            await createReviewLock(employee.EmployeeID, month, year);
        }
        fetchReviews();
    }
    const requestUnLock = async (review, event, level) => {
        event.preventDefault();

        if (await unLockAroveReviewAdmin(review, level)) {
            alert("Mở duyệt cấp " + (level == 1 ? level : ("1 và 2")) + " thành công.");

            if (level == 2) {
                alertUnLockApprover2User(employee.Level2, employee.EmployeeID, 2)
            }

            if (level == 1) {
                alertUnLockApprover2User(employee.Level1, employee.EmployeeID, 1)
                alertUnLockApprover2User(employee.Level2, employee.EmployeeID, 2)
            }
            setReviews([]);
            fetchReviews();
        }
    }

    const showUnlock = (review, level) => {
        // console.log(review, getFullMonth(month, year), appConfigs.Review.ApprovedLimitUnLock);
        // ngay hien tai lon hon cau hinh thi ko hien
        if (appConfigs.Review.ApprovedLimitUnLock < moment(new Date()).date()) return false;
        if (review.FullMonth != getFullMonth(month, year)) return false;
        // if (review.FullMonth != getFullMonth(month, year)) return false;
        // khoa thi hien, khong khoa thi khonng hien
        // console.log(l'ok');
        if (level == 2) {
            return review.Level2ApprovedLock;
        }
        return review.Level1ApprovedLock;
    }

    const getReviews = () => {
        return reviews;
    }

    return (
        <div className=" py-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    {/* <h1 className="text-xl font-semibold text-gray-900">Users</h1> */}
                    <p className="mt-2 text-sm text-gray-700">
                        Lưu ý ngày cuối mở duyệt: <b>{dayUnlock.label}</b>
                    </p>
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            {!getReviews() || getReviews().length == 0 && <NoData message={"Không có dữ liệu"} />}
                            {getReviews() && getReviews().length > 0 && <table className=" divide-y divide-gray-300 w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Tháng
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Điểm cấp 1
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Trạng thái
                                        </th> */}
                                        <th></th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Điểm cấp 2
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Trạng thái
                                        </th> */}
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Role
                                        </th> */}
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Chức năng</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {getReviews().map((review) => (
                                        <tr key={review.uid}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                {`Tháng ${review.Month} - ${review.Year}`}
                                            </td>
                                            <td className={classNames(getEmployeeReviewBg(review, 1), getColorByPoint(review.Level1Point), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>
                                                {review.Level1Point}
                                            </td>
                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                    Đã duyệt
                                                </span>
                                            </td> */}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                {showUnlock(review, 1) && <a href="!#" onClick={(event) => requestUnLock(review, event, 1)}
                                                    className="text-indigo-600 hover:text-indigo-900">
                                                    Mở duyệt cấp 1 <span className="sr-only">, {review.EmployeeID}</span>
                                                </a>}
                                            </td>
                                            <td className={classNames(getEmployeeReviewBg(review, 2), getColorByPoint(review.Level2Point), "whitespace-nowrap px-3 py-4 text-sm text-gray-500")}>{review.Level2Point}</td>
                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                    Đã duyệt
                                                </span>
                                            </td> */}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                {showUnlock(review, 2) && <a href="!#" onClick={(event) => requestUnLock(review, event, 2)}
                                                    className="text-indigo-600 hover:text-indigo-900">
                                                    Mở duyệt cấp 2 <span className="sr-only">, {review.EmployeeID}</span>
                                                </a>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
