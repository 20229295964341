import { async } from "@firebase/util";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useState } from "react"
import { getNameSearch, removeAccAndLower } from "../../helpers";
import { db } from "../../services/firebase";


export default function UserCreateForm({ setOpen }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    // const [email, setEmail] = useState("");
    const [employeeID, setEmployeeID] = useState("");
    const [password, setPassword] = useState("");

    const changeLastName = (event) => {
        // generateEmail();
        setLastName(event.target.value.replace(" ", ""));
    }

    const changeEmployeeID = (event) => {

        setEmployeeID(event.target.value.replace(" ", "").toUpperCase().trim());
        // generateEmail();
    }

    const generateEmail = () => {
        return removeAccAndLower(firstName.replace(" ", "").trim() + lastName.trim() + employeeID.trim() + "@record.vn");
    }

    const requestCreateUser = async (event) => {
        event.preventDefault();
        console.log("ok");
        if (!lastName || lastName.trim().length == 0) {
            alert("Vui lòng nhập Tên");
            return;
        }
        if (!firstName || firstName.trim().length == 0) {
            alert("Vui lòng nhập Họ");
            return;
        }
        if (!employeeID || employeeID.trim().length == 0) {
            alert("Vui lòng nhập mã nhân viên");
            return;
        }


        // if (!password || password.length == 0) {
        //     alert("Vui lòng nhập Mật khẩu");
        //     return;
        // }

        // if (password.length < 6) {
        //     alert("Mật khẩu tối thiểu 6 ký tự");
        //     return;
        // }

        // kiem tra user da co roi hay chua?
        const userRef = doc(db, "hp-user", employeeID);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            console.log(userSnap.data());
            alert("Người dùng đã tồn tại. Vui lòng kiểm tra lại");
            return;
        } else {
            const userObj = {
                FirstName: firstName.trim(),
                LastName: lastName.trim(),
                // Password: password,
                Email: generateEmail(),
                EmployeeID: employeeID,
                ImportHistory: [],
                Status: 1,
                Role: ["level1", "level2"],
                SearchName: getNameSearch(firstName.trim(), lastName.trim()),
                UpdatedAt: serverTimestamp(),
                CreatedAt: serverTimestamp(),
            };

            await setDoc(userRef, userObj);
            alert("Tạo người dùng thành công");
            setOpen(false);
        }
    }

    return (
        <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                    {/* <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
                    </div> */}
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Họ
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    value={firstName} onChange={(event) => setFirstName(event.target.value)}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Tên
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    value={lastName} onChange={changeLastName}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Mã nhân viên
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="msnv-name"
                                    id="msnv-name"
                                    value={employeeID} onChange={changeEmployeeID}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Mật khẩu
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="msnv-name"
                                    id="msnv-name"
                                    placeholder={"Tối thiểu 6 ký tự"}
                                    value={password} onChange={(event) => setPassword(event.target.value)}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div> */}

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Email
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input readOnly={true}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={generateEmail()}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                    <button
                        type="button" onClick={() => setOpen(false)}
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Đóng
                    </button>
                    <button
                        type="submit" onClick={(event) => requestCreateUser(event)}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Tạo
                    </button>
                </div>
            </div>
        </form>
    )
}
