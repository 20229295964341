import { arrayUnion, doc, serverTimestamp, setDoc, Timestamp, updateDoc, writeBatch } from "firebase/firestore";
import { date2DateTimeStr, getFullMonth, getNameSearch, hashWithLength, removeDuplicate, sumArray } from "../helpers";
import { db } from "../services/firebase";



export const approveReview = async (user, employeeReview, levelReview) => {
    try {
        levelReview = levelReview * 1;
        // console.log(user, employeeReview, levelReview);
        // console.log(levelReview)
        if (levelReview === 2) {

            // kiem tra neu level 1 chua duyet thi khong duyet cap 2
            if (employeeReview.Level1Approved == false) {
                // alert("Vui lòng duyệt cấp 1 trước khi duyệt cấp 2");
                return false;
            }
        }
        const empRef = doc(db, "hp-review", employeeReview.uid);
        await updateDoc(empRef, getApproveReviewObj(user, employeeReview, levelReview));
        return true;
    } catch (ex) {
        console.log(ex);
        alert("Có lỗi trong quá trình xử lý. Vui lòng liên hệ quản trị");
        return false;
    }
}

export const getApproveReviewObj = (user, employeeReview, levelReview) => {
    if (levelReview == 1) {
        return {
            // Points1: points,
            Level1Approved: true,
            Level1User: user.hpUser.EmployeeID,
            Level1ApprovedLock: true,
            // Level1Point: sumArray(points, "Point"),
            // approved va chep point sang cap 2 luon
            Points2: [...employeeReview.Points1],
            //.map(m => { return Object.assign({}, m, { Note: "" }) })], // copy ca note luon
            Level2Point: sumArray(employeeReview.Points1, "Point"),

        };
    } else if (levelReview == 2) {
        return {
            // Points2: points,
            Level2Approved: true,
            Level2User: user.hpUser.EmployeeID,
            Level2ApprovedLock: true,
            // Level2Point: sumArray(points, "Point"),
        };
    }

    return {};
}

export const updateReview = async (user, employeeReview, points, levelReview) => {
    const empRef = doc(db, "hp-review", employeeReview.uid);
    levelReview = levelReview * 1;
    if (levelReview == 1) {
        await updateDoc(empRef, {
            Points1: points,
            // Level1Approved: false,
            Level1Save: user.hpUser.EmployeeID,
            Level1Point: sumArray(points, "Point"),
        });
        return true;
    } else if (levelReview == 2) {
        await updateDoc(empRef, {
            Points2: points,
            // Level2Approved: false,
            Level2Save: user.hpUser.EmployeeID,
            Level2Point: sumArray(points, "Point"),
        });
        return true;
    }
    return false;
    // alert("Cập nhật dữ liệu thành công");
    // search(msnv);
}

export const unLockAroveReview = async (employeeReview, levelReview) => {
    const empRef = doc(db, "hp-review", employeeReview.uid);
    levelReview = levelReview * 1;
    // console.log(employeeReview, levelReview);
    // cap 2 hoac cap 1 ma cap 2 chua duyet
    if (levelReview == 1 && (employeeReview.Level2Approved)) {
        return false;
    }
    if (levelReview == 2) {
        await updateDoc(empRef, {
            Level2ApprovedLock: false,
        });
    }
    if (levelReview == 1) {
        // console.log('ok');
        await updateDoc(empRef, {
            Level1ApprovedLock: false,
        });
    }
    return true;
}

export const unLockAroveReviewAdmin = async (employeeReview, levelReview) => {
    const empRef = doc(db, "hp-review", employeeReview.uid);
    levelReview = levelReview * 1;

    if (levelReview == 2) {
        await updateDoc(empRef, {
            Level2ApprovedLock: false,
            Level2Approved: false,
        });
    }
    if (levelReview == 1) {
        // console.log('ok');
        await updateDoc(empRef, {
            Level1ApprovedLock: false,
            Level1Approved: false,
            Level2ApprovedLock: false,
            Level2Approved: false,
        });
    }
    return true;
}

export const unLockAroveReviewsAdmin = async (employeeReviews, levelReview, copyL1, copyL2) => {

    const chunkSize = 200;

    for (let i = 0; i < employeeReviews.length; i += chunkSize) {
        const chunk = employeeReviews.slice(i, i + chunkSize);

        console.log("chunk", chunk.length, i);

        let batch = writeBatch(db);
        // eslint-disable-next-line no-loop-func
        let bar = new Promise((resolve, reject) => {
            chunk.forEach((employeeReview, index, array) => {
                const empRef = doc(db, "hp-review", employeeReview.uid);
                // get emp
                levelReview = levelReview * 1;
                if (levelReview === 2) {
                    if (copyL2) {
                        // copy diem tu cap 1 sang cap 2
                        batch.update(empRef, {
                            Level2ApprovedLock: false,
                            Level2Approved: false,
                            Points2: [...employeeReview.Points1],
                            Level2Point: sumArray(employeeReview.Points1, "Point"),
                        })
                    }
                    else {
                        batch.update(empRef, {
                            Level2ApprovedLock: false,
                            Level2Approved: false,
                        })
                    }
                }
                if (levelReview === 1) {
                    // console.log('ok');
                    batch.update(empRef, {
                        Level1ApprovedLock: false,
                        Level1Approved: false,
                        Level2ApprovedLock: false,
                        Level2Approved: false,
                    });
                }
                // }
                if (index === chunk.length - 1) resolve();
            });
        });
        bar
            .then(
                async () => { // resolve
                    try {
                        await batch.commit();
                    }
                    catch (e) {
                        console.log(e);
                    };
                },
                () => { //reject

                })
            .catch((e) => {
            });
    }
}

export const createReview = async (msnv, month, year) => {
    // if (!msnv || parseInt(msnv) <= 0) return false;
    let Version = "001"
    const empRef = doc(db, "hp-review", getFullMonth(month, year) + "-" + msnv + "-" + Version);
    await setDoc(empRef, {
        EmployeeID: msnv,
        FullMonth: getFullMonth(month, year),
        Level1Point: 0,
        Level1Approved: false,
        Level1ApprovedLock: false,
        Level1Save: "",
        Level2Point: 0,
        Level2Approved: false,
        Level2ApprovedLock: false,
        Level2Save: "",
        Month: month.value,
        Year: year.value,
        Points1: [],
        Points2: [],
        Version: Version
    })
    return true;
}

export const createReviewLock = async (msnv, month, year) => {
    // if (!msnv || parseInt(msnv) <= 0) return false;
    let Version = "001"
    const empRef = doc(db, "hp-review", getFullMonth(month, year) + "-" + msnv + "-" + Version);
    await setDoc(empRef, {
        EmployeeID: msnv,
        FullMonth: getFullMonth(month, year),
        Level1Point: 0,
        Level1Approved: false,
        Level1ApprovedLock: true,
        Level1Save: "",
        Level2Point: 0,
        Level2Approved: false,
        Level2ApprovedLock: true,
        Level2Save: "",
        Month: month.value,
        Year: year.value,
        Points1: [],
        Points2: [],
        Version: Version
    })
    return true;
}

export const getOjbectReview = () => {

}


export const getObjImportEmployeee = (emp, deptID, teamID) => {
    return {
        FirstName: emp.Ho,
        LastName: emp.Ten,
        SearchName: getNameSearch(emp.Ho, emp.Ten),
        EmployeeID: emp.MSNV,
        DepartmentID: deptID,
        TeamID: teamID || "",
        Title: emp.ChucDanh,
        WorkPlace: emp.NoiCongTac,
        Level: [emp.ImportLevel1, emp.ImportLevel2],
        Level1: emp.ImportLevel1,
        Level1Name: emp.TenNguoiDanhGia1 || "",
        Level2: emp.ImportLevel2,
        Level2Name: emp.TenNguoiDanhGia2 || "",
        Status: emp.ImportStatus,
        UpdatedAt: serverTimestamp(),
        Area: emp.Doi || "",
        ImportHistory: arrayUnion({
            Level1: emp.ImportLevel1,
            Level2: emp.ImportLevel2,
            Title: emp.ChucDanh,
            WorkPlace: emp.NoiCongTac,
            TeamID: teamID,
            Area: emp.Doi || "",
            UpdatedAt: date2DateTimeStr(new Date()),
        })
    }
}

export const getObjImportUser = (user) => {
    return {
        FirstName: user.Ho,
        LastName: user.Ten,
        SearchName: getNameSearch(user.Ho, user.Ten),
        EmployeeID: user.MSNV,
        Email: user.Email,
        Status: 1,
        AuthId: "",
        Role: ["level1", "level2"],
        UpdatedAt: serverTimestamp(),
        ImportHistory: arrayUnion({
            UpdatedAt: date2DateTimeStr(new Date()),
        })
    }
}


export const alertUnLockApprover2User = async (userID, empID, Level) => {

    let userRef = doc(db, "hp-user", userID);

    let message = 'Nhân viên ' + empID + ' đã được mở duyệt. Vui lòng kiểm tra và duyệt lại.';
    let actionLink = '/app/reviews/multi/' + Level;

    await updateDoc(userRef, {
        noties: arrayUnion({
            CreatedAt: Timestamp.fromDate(new Date()),
            Message: message,
            ActionLink: actionLink,
            ActionType: "mo_duyet",
            Status: 1
        })
    });
};

export const createAreas = async (data, importList) => {
    // console.log(data);
    if (!data || data.length == 0) return;

    let batch = writeBatch(db);
    let bar = new Promise((resolve, reject) => {
        data.forEach((are, index, array) => {
            // console.log(are);
            let areaId = hashWithLength(are.toLocaleLowerCase());
            // console.log(areaId);
            const areaRef = doc(db, "hp-area", areaId);

            let arrLevel1 = importList.filter(f => f.Doi == are).map(m => m.NguoiDanhGia1) || [];
            let arrLevel2 = importList.filter(f => f.Doi == are).map(m => m.NguoiDanhGia2) || [];

            let objArea = { Name: are, Level: removeDuplicate([...arrLevel1, ...arrLevel2]) }

            // console.log(are, arrLevel1, arrLevel2, objArea);

            batch.set(areaRef, objArea);
            // }
            if (index === data.length - 1) resolve();
        });
    });

    bar
        .then(
            async () => { // resolve
                try {
                    await batch.commit();
                }
                catch (e) {
                    console.log(e);
                };
            },
            () => { //reject

            })
        .catch((e) => {
        });
}


export const createWorkplaces = async (data, importList) => {
    // console.log(data);
    if (!data || data.length == 0) return;

    const chunkSize = 200;

    for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize);

        // console.log("chunk", chunk.length, i);

        let batch = writeBatch(db);
        let bar = new Promise((resolve, reject) => {
            chunk.forEach((wpName, index, array) => {
                // console.log(wpName);
                let wpId = hashWithLength(wpName.toLocaleLowerCase());
                // console.log(wpId);
                const wpRef = doc(db, "hp-workplace", wpId);

                let arrLevel1 = importList.filter(f => f.NoiCongTac == wpName).map(m => m.NguoiDanhGia1) || [];
                let arrLevel2 = importList.filter(f => f.NoiCongTac == wpName).map(m => m.NguoiDanhGia2) || [];

                let objWP = { Name: wpName, Level: removeDuplicate([...arrLevel1, ...arrLevel2]) }

                batch.set(wpRef, objWP);
                // }
                if (index === chunk.length - 1) resolve();
            });
        });

        bar
            .then(
                async () => { // resolve
                    try {
                        await batch.commit();
                    }
                    catch (e) {
                        console.log(e);
                    };
                },
                () => { //reject

                })
            .catch((e) => {
            });
    }
}
export const getObjupdateEmployeeReviewLevel = (employee, user, levelReview) => {
    let objEmp = {};
    objEmp[`Level${levelReview}`] = user.EmployeeID;
    objEmp[`Level${levelReview}Name`] = user.FirstName + ' ' + user.LastName;
    objEmp.Level = employee.Level;
    objEmp.Level[levelReview == 1 ? 0 : 1] = user.EmployeeID;
    return objEmp;
}
export const updateEmployeeReviewLevel = async (employee, user, levelReview) => {
    try {
        // console.log(employee, user, levelReview);
        if (levelReview != 1 && levelReview != 2) return false;

        const empRef = doc(db, "hp-employee", employee.EmployeeID);
        levelReview = levelReview * 1;

        let objEmp = getObjupdateEmployeeReviewLevel(employee, user, levelReview);
        objEmp.UpdatedAt = serverTimestamp();
        await updateDoc(empRef, objEmp);

        return true;
    }
    catch (err) {
        return false;
    }
}