/* This example requires Tailwind CSS v2.0+ */
import { PaperClipIcon } from '@heroicons/react/solid'

export default function EmployeeShortInfo({ employee, teams, year, month }) {

    const getTeamName = (teamID) => {
        if (teamID) {
            let team = teams.find(f => f.uid === teamID);
            if (team) return team.Name;
        }
        return "";
    }
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{employee.FirstName + " " + employee.LastName}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{employee.Title}</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Nhóm đánh giá</dt>
                        <dd className="mt-1 text-sm font-medium text-red-900">{getTeamName(employee.TeamID)}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Người đánh giá cấp 1</dt>
                        <dd className="mt-1 text-sm text-gray-900">{employee.Level1Name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Nơi công tác</dt>
                        <dd className="mt-1 text-sm text-gray-900">{employee.WorkPlace}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Người đánh giá cấp 2</dt>
                        <dd className="mt-1 text-sm text-gray-900">{employee.Level2Name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Đội/ cụm</dt>
                        <dd className="mt-1 text-sm text-gray-900">{employee.Area}</dd>
                    </div>
                    {/* <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">About</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                            qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                            pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                        </dd>
                    </div> */}
                </dl>
            </div>
        </div>
    )
}
