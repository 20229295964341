/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import ImportStep from '../../components/ImportStep';
import ImportReview from '../../components/ImportReview';
import ImportReviewShort from '../../components/ImportReviewShort';
import UserImportReviewShort from '../../components/User/UserImportReviewShort';
import axios from 'axios';
import { RECORD_BO_API } from '../../constant/config';
import { doc, writeBatch } from 'firebase/firestore';
import { getObjImportUser } from '../../app/services';
import { db } from '../../services/firebase';

export default function UserImport({ open, setOpen }) {
    const cancelButtonRef = useRef(null);
    const [step, setStep] = useState("01");
    const [selectedFile, setSelectedFile] = useState("");
    const [usersFile, setUsersFile] = useState([]);

    useEffect(() => {
        if (usersFile.length == 0) {
            // setStep("01");
        }
    }, [usersFile]);

    const handleInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const doReadImportFile = () => {
        if (readImportFile()) {
            setStep('02');
        }
    }

    const doSetOpen = (isOpen) => {
        if (isOpen == false) {
            setStep("01");
        }
        setOpen(isOpen);
    }

    const readImportFile = async () => {
        const data = new FormData();
        data.append("file", selectedFile);

        axios
            .post(`${RECORD_BO_API}/api/user/import`, data, {})
            .then((res) => {
                let users = []

                var bar = new Promise((resolve, reject) => {
                    res.data.forEach((row, index, array) => {
                        if (index == 0) {
                            if (row.Column1 != "manv" || row.Column2 != "ho" || row.Column3 != "ten" || row.Column4 != "email") {
                                resolve();
                            }
                            return; // dong dau header bo qua
                        }
                        if (row.Column4) {
                            // Column0: "stt"
                            // Column1: "manv"
                            // Column2: "ho"
                            // Column3: "ten"
                            // Column4: "email"
                            // co du lieu
                            try {
                                users.push({
                                    MSNV: row.Column1 + "",
                                    Ten: row.Column3.trim(),
                                    Ho: row.Column2.trim(),
                                    Email: row.Column4.trim()
                                });
                            } catch (e) {
                                console.log(e);
                                console.log(row);
                            }
                        }
                        if (index === res.data.length - 1) resolve();
                    });
                });

                bar.then(() => {
                    checkImportUsers(users);
                    // setOpen(false);
                });
            })
            .catch((error) => console.log(error));
    };

    const checkImportUsers = async (users) => {
        console.log(users);
        setUsersFile(users);
    }

    const doImportFile = () => {
        if (ImportFile()) {
            setStep('03');
        }
    }

    const ImportFile = () => {
        // Get a new write batch
        

        let importUserList = [...usersFile];
        if (window.confirm(`Tìm thấy thông tin quản lý ${importUserList.length}/${usersFile.length} người dùng.?`) == false)
            return;

            // Set the value of 'NYC'
        const batch = writeBatch(db);
        var bar = new Promise((resolve, reject) => {
            importUserList.forEach((usr, index, array) => {
                let msnv = usr.MSNV;
                const nvRef = doc(db, "hp-user", msnv);

                batch.set(nvRef, getObjImportUser(usr));

                if (index === importUserList.length - 1) resolve();
            });
        });

        bar.then(async () => {
            // Commit the batch
            await batch.commit();
            alert("Nhập thành công");
            window.location.reload(false);
        });
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6 md:max-w-5xl">
                                <div>
                                    {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div> */}
                                    <div className="mt-3 text-left sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Import người dùng
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <ImportStep currStep={step} />

                                <div className="flex justify-center overflow-auto">
                                    {step == '01' && <div className="mb-3 w-96">
                                        <label htmlFor="formFile" className="form-label inline-block mb-2 text-gray-700">Chọn file</label>
                                        {/* <span className="sr-only">Chọn file Import</span> */}
                                        <input onChange={handleInputChange} accept=".xlsx,.xls"
                                            type="file"
                                            name="upload_file"
                                            id="formFile"
                                            className="form-control   block              w-full
                                        px-3
                                        py-1.5
                                        text-base
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                                    </div>}
                                    {/* {step == '02' && <ImportReview employiesFile={employiesFile} />} */}
                                    {step == '02' && <UserImportReviewShort usersFile={usersFile} />}
                                    {step == '03' && <p>Import thành công</p>}
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    {step === '01' &&
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                            onClick={() => doReadImportFile()}
                                        >
                                            Tiếp tục
                                        </button>
                                    }
                                    {step === '02' &&
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                            onClick={() => doImportFile()}
                                        >
                                            Import
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => doSetOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Đóng
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
