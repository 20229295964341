import { Fragment, useEffect, useState } from 'react'
// import { Dialog, Switch, Transition } from '@headlessui/react'

// import { BellIcon } from '@heroicons/react/solid'
import { checkPageRole, classNames, checkUserRoleLoad } from '../../../helpers'
import { EmployeeConfig } from './EmployeeConfig'
import { EmployeeHistory } from './EmployeeHistory'
import EmployeeReviews from './EmployeeReviews'

import { useSelector } from "react-redux";
import { EmployeeLevel } from './EmployeeLevel'

const tabs = [
    // { name: 'Cấu hình', href: '#', current: true, code: "config" },
    { name: 'Đánh giá', href: '#', current: true, code: "review" },
    { name: 'Người đánh giá', href: '#', current: true, code: "level" },
    // { name: 'Lịch sử', href: '#', current: false, code: "history" },
    // { name: 'Notifications', href: '#', current: false },
    // { name: 'Plan', href: '#', current: false },
    // { name: 'Billing', href: '#', current: false },
    // { name: 'Team Members', href: '#', current: false },
]

export default function EmployeeTabs({ employee, users, requestUpdate }) {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        checkPageRole(user.hpUser, ["nhansu", "quantri"]);
    }, [user]);

    // const [sidebarOpen, setSidebarOpen] = useState(false)
    const [currTab, setCurrTab] = useState(tabs[0].code);
    const changeTab = (tabCode, event) => {
        if (event) event.preventDefault();

        setCurrTab(tabCode);
    }
    return (
        <>

            {/* Content area */}

            {/* <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0"> */}
            {/* <div className="pt-10 pb-16"> */}
            {/* <div className="px-4 sm:px-6 md:px-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Settings</h1>
                    </div> */}
            {/* <div className="px-4 sm:px-6 md:px-0"> */}
            <div className="py-4">
                {/* Tabs */}
                <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="selected-tab"
                        name="selected-tab"
                        onChange={(event) => changeTab(event.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current).name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name} value={tab.code}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    onClick={(event) => changeTab(tab.code, event)}
                                    className={classNames(
                                        currTab == tab.code
                                            ? 'border-purple-500 text-purple-600'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>

                {/* Description list with inline editing */}
                {currTab === "config" && <EmployeeConfig />}
                {currTab === "level" && <EmployeeLevel users={users} employee={employee} requestUpdate={requestUpdate} />}
                {currTab === "history" && <EmployeeHistory employee={employee} />}
                {currTab === "review" && <EmployeeReviews employee={employee} />}
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}

        </>
    )
}
