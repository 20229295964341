import { Routes, Route, Link, Outlet } from 'react-router-dom';

import './App.css';

import Main from './routes/Main';
import Login from './routes/auth/Login';
import AuthLayout from './layouts/AuthLayout';

function Auth() {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}

export default Auth;
