/* This example requires Tailwind CSS v2.0+ */
import { async } from '@firebase/util';
import { ChatIcon, ClockIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { arrayRemove, arrayUnion, collection, doc, updateDoc, where } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { classNames, date2DateStr, date2DateTimeStr } from '../helpers'
import { db } from '../services/firebase';
import NoData from './NoData';

export default function ListNoti({ noites }) {

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    const removeNoti = async (noti) => {
        console.log(noti);
        if (!user) return;

        const docRef = doc(db, "hp-user", user.hpUser.EmployeeID);

        await updateDoc(docRef, {
            noties: arrayRemove(noti)
        });
        window.location.reload(false);
    }

    const readNoti = async (event, noti) => {
        event.preventDefault();
        console.log(noti);
        // if (!user) return;

        const docRef = doc(db, "hp-user", user.hpUser.EmployeeID);

        // xoa di
        await updateDoc(docRef, {
            noties: arrayRemove(noti)
        });
        // them vao lai
        await updateDoc(docRef, {
            noties: arrayUnion(Object.assign({}, noti, { Status: 2 }))
        });
        if (noti.ActionLink.startsWith("/app/")) {
            navigate(noti.ActionLink);
            window.location.reload(false);
        }
    }


    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
                {noites.map((noti) => (
                    <li key={noti.CreatedAt}>
                        {/* <div href={noti.ActionLink} className="block hover:bg-gray-50"> */}
                        <div className="flex items-center px-4 py-4 relative">
                            <div>
                                <a href={noti.ActionLink} onClick={(event => readNoti(event, noti))}>
                                    <p className={classNames(noti.Status == 1 ? " text-indigo-600" : "text-gray-600", "text-sm font-medium pr-4")}>{noti.Message}</p>
                                    <p className="text-sm text-gray-900">

                                    </p>
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                        <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        {/* <span className="truncate">{noti.ActionType}</span> */}
                                        <time dateTime={noti.CreatedAt.toDate()}>{date2DateTimeStr(noti.CreatedAt.toDate())}</time>
                                    </p>
                                </a>
                            </div>
                            <button
                                type="button" onClick={() => removeNoti(noti)}
                                className="absolute inset-y-0 right-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
            {noites && noites.length == 0 && <NoData message={"Không có thông báo"} />}
        </div>
    )
}
