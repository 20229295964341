import { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { analytics, auth, db, getConfigJson, logout } from "./services/firebase"

import { onAuthStateChanged } from "@firebase/auth";
import { useDispatch, useSelector } from "react-redux";


import { selectName, setHPUser, setLogin, setLogOut } from "./features/user/userSlice";

import LightHeaderLayout from './layouts/LightHeaderLayout';

import { collection, doc, FieldValue, getDoc, getDocs, limit, query, serverTimestamp, Timestamp, where } from 'firebase/firestore';
import { addTeam } from './features/team/teamSlice';
import { AddConfig, AddReviewDefine } from './features/config/configSlice';
import { fetchConfig } from 'firebase/remote-config';
import axios from 'axios';
import { RECORD_BO_API } from './constant/config';
import moment from 'moment';
import { logEvent, setUserId } from 'firebase/analytics';


function App() {
  const calledOnce = useRef(false);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const name = useSelector(selectName);

  const teamReducer = useSelector(state => state.teamReducer);

  useEffect(() => {
    // console.log("users", users);
    if (calledOnce.current) {
      return;
    }
    if (user) {
      // console.log('I run only once if toggle is false.');
      calledOnce.current = true;
      axios.get(`${RECORD_BO_API}/api/test/`, { withCredentials: false })
        .then((res) => {
          let clientDate = moment(new Date()).format("YYYYMMDD");
          // console.log(res.data, clientDate, parseInt(res.data.dateStr), parseInt(clientDate + ""));
          if (res.data && res.data.dateStr && (Math.abs(parseInt(res.data.dateStr) - parseInt(clientDate + "")) < 2)) {

          } else {
            setTimeout(() => {
              console.log(user);
              alert("Vui lòng kiểm tra lại thời gian máy");
              logout();
            }, 2000);
          }
        }).catch(e => {
          console.log(e);
        });
    }
  }, [user]);


  const getTeams = () => {
    if (!teamReducer) return [];
    return teamReducer.teams;
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user || !user.email) navigate("/auth/login");
    setUserId(analytics, user?.email);
    logEvent(analytics, 'dashboard-page', {
      method: 'email',
      user: user.email,
    });

    fetchUser(user);
    // console.log(auth, user);
    // if (getTeams().lenth == 0) {
    fetchTeams();
    // }

    fetchConfig();

    fetchNoties();

    // fetchReviewDefines("")
    // fetchUser();
  }, [user, loading]);

  const fetchConfig = () => {
    let ReviewStr = getConfigJson("Review").asString();
    let InputConfigStr = getConfigJson("InputConfig").asString();
    let LimitStr = getConfigJson("Limit").asString();

    let InputConfig = InputConfigStr ? JSON.parse(InputConfigStr) : {};
    let Limit = LimitStr ? JSON.parse(LimitStr) : {};
    let Review = ReviewStr ? JSON.parse(ReviewStr) : {};

    dispatch(AddConfig({ configName: "InputConfig", config: InputConfig }));
    dispatch(AddConfig({ configName: "Limit", config: Limit }));
    dispatch(AddConfig({ configName: "Review", config: Review }));

    // console.log(Review);

    // console.log(Timestamp.now().toDate().getMilliseconds(), new Date().getMilliseconds());
    // console.log(ServerValue.Timestamp, new Date());
  }

  const fetchNoties = () => {

  }

  useEffect(() => {
    // console.log(auth, user);
    onAuthStateChanged(auth, (user) => {
      // console.log(auth, user);

      if (user) {
        dispatch(
          setLogin({
            name: user.displayName,
            uid: user.uid,
            img: user.photoURL,
            email: user.email,
          })
        );

      } else {
        logout();
      }
    });
  }, []);

  const fetchTeams = async () => {
    // console.log('teams feach', );
    try {
      // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
      const q = query(collection(db, "hp-team"));
      const docs = await getDocs(q);
      docs.forEach(async (doc) => {
        // console.log()
        dispatch(addTeam({ team: Object.assign({ uid: doc.id }, doc.data()) }));

        ["hoanthanh", "nghiemtuc", "tichcuc"].map(Group => {
          fetchReviewDefines(doc.id, Group);
        });
      });
      // const data = doc.docs[0].data();
      // setName(data.name);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  const fetchReviewDefines = async (TeamID, Group) => {
    try {
      const q = query(collection(db, "hp-revew-define", TeamID, Group));
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        // console.log(doc.id, doc.data());
        let reviewDefine = Object.assign({ uid: doc.id, Group, TeamID }, doc.data());
        // console.log(reviewDefine);
        dispatch(AddReviewDefine({ reviewDefine }))
      });
    } catch (err) {
      console.error(err);
    }
  };


  const fetchUser = async (user) => {
    // if (!user) navigate("/auth/login");
    // const docRef = doc(db, "hp-user", user.uid);
    // console.log(user);
    const q = query(collection(db, "hp-user"), where("Email", "==", user.email), limit(1));
    const docSnap = await getDocs(q);

    if (docSnap.size > 0) {
      // console.log(docSnap.data());  

      docSnap.forEach((doc) => {
        // console.log(doc.id, doc.data());
        dispatch(setHPUser({ hpUser: doc.data() }));
        return;
      });


      // if (docSnap.data().Role || docSnap.data().Role.length == 0) {

    } else {
      // console.log("no user");  
      dispatch(setHPUser({}));
      logout();
    }
  };


  return (
    <LightHeaderLayout>
      {/* <InitData /> */}
      <Outlet />
    </LightHeaderLayout>
  );
}

export default App;
