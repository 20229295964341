import { collection, doc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";

import { Navigate, useParams } from "react-router-dom";
import NoData from "../../components/NoData";
import { getFullMonth, getMonthAddMonth, getUnique, getYearAddMonth, sumArray } from "../../helpers";
import { auth, db } from "../../services/firebase";
import EmployeeShortInfo from "../employies/EmployeeShortInfo";
import ReviewDetail from "./ReviewDetail";

import { getDefines, getMonths, getYears } from "../../helpers";
import { approveReview, createReview, unLockAroveReview, updateReview } from "../../app/services";
import SwitchLabel from "../../components/SwitchLabel";

const ReviewOne = ({ employeeData, employeeReviewData, sentLevelReview, setOpen }) => {
    let params = useParams();
    const [loading, error] = useAuthState(auth);
    const [msnv, setMSNV] = useState("");
    const [employee, setEmployee] = useState(employeeData);
    const [employeeReview, setEmployeeReview] = useState(employeeReviewData || {});

    const levelReview = sentLevelReview || params.level;

    const user = useSelector((state) => state.user);

    const teamReducer = useSelector((state) => state.team);

    const getTeams = () => { return teamReducer.teams ? teamReducer.teams : [] }

    const reviewDefines = useSelector((state) => state.config.reviewDefines);

    const [reviewStatus, setReviewStatus] = useState(false);

    useEffect(() => {
        if (loading) return;
        if (!user) return Navigate("/");
        if (!levelReview) return Navigate("/app/reviews/multi/1");
    }, [user, loading]);


    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));
    const changeMSNV = (event) => {
        setMSNV(event.target.value);
    }

    // const changeMonth = (event) => {
    //     setMonth(getMonths().find(f => f.value === parseInt(event.target.value)));
    // };

    // const changeYear = (event) => {
    //     // setYear(event);
    //     setYear(getYears().find(f => f.value === parseInt(event.target.value)));
    // };

    const selectType = async (strType) => {
        setEmployee(null);
        // console.log(strType, msnv);
        try {
            // let q = query(collection(db, "hp-employee"), where("Level", "array-contains", user.uid), limit(1));
            let q = query(collection(db, "hp-employee"), where("Level" + levelReview, "==", user.hpUser.EmployeeID), limit(1));
            switch (strType) {
                case "first": q = query(q, orderBy("EmployeeID")); break;
                case "last": q = query(q, orderBy("EmployeeID", "desc")); break;
                case "next": q = query(q, orderBy("EmployeeID"), where("EmployeeID", ">", msnv)); break;
                case "prev": q = query(q, orderBy("EmployeeID", "desc"), where("EmployeeID", "<", msnv)); break;
                default: return;
            }
            // q = query(q, orderBy("EmployeeID"));

            // where("EmployeeID", "==", parseInt(msnv)));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.size == 0) {
                setEmployee({});
            } else {
                querySnapshot.forEach((doc) => {
                    setEmployee(doc.data());
                    setMSNV(doc.data().EmployeeID);
                    handleSearchReview(doc.data().EmployeeID);
                    return;
                });
            }
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");

        }
    }


    const handleSearch = async (event) => {
        event.preventDefault();

        search(msnv);
    }

    const search = async (EmployeeID) => {
        setEmployee(null);
        try {
            // console.log(EmployeeID, levelReview);
            const q = query(collection(db, "hp-employee"),
                // where("Level", "array-contains", user.uid),
                where("Level" + levelReview, "==", user.hpUser.EmployeeID),
                where("EmployeeID", "==", EmployeeID));

            const querySnapshot = await getDocs(q);
            setEmployee({});
            // console.log(querySnapshot.size)
            querySnapshot.forEach((doc) => {
                // console.log(doc.data());
                setEmployee(doc.data());
                handleSearchReview(msnv);
                return;
            });
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");

        }


    }

    const handleSearchReview = async (maNhanVien) => {
        // event.preventDefault();
        setEmployeeReview({});
        try {
            // console.log(maNhanVien, getFullMonth(month, year));
            const q = query(collection(db, "hp-review"),
                where("EmployeeID", "==", maNhanVien),
                // where(`Level${params.level}Approved`, "==", reviewStatus),
                where("FullMonth", "==", getFullMonth(month, year))
            );
            // console.log(q);

            const querySnapshot = await getDocs(q);
            if (querySnapshot.size == 0) {
                if (createReview(msnv, month, year)) {
                    handleSearchReview(msnv);
                }
            } else {
                querySnapshot.forEach((doc) => {
                    let empReview = Object.assign({ uid: doc.id }, doc.data());
                    if (empReview[`Level${params.level}Approved`] == reviewStatus) {
                        setEmployeeReview(empReview);
                    } else {
                        setEmployee(null);
                        setMSNV("");
                    }
                    return;
                });
            }
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    }

    const requestUpdateReview = async (points) => {
        // console.log(params.level, points, employeeReview);
        if (await updateReview(user, employeeReview, points, levelReview)) {
            alert("Cập nhật dữ liệu thành công. Tổng điểm: " + sumArray(points, "Point"));
            await search(msnv);
        }
    }

    const doApproveReview = async (points) => {
        if (params.level == 2) {
            // kiem tra level 1 da approved chua
            if (employeeReview.Level1Approved == false) {
                alert("Quản lý cấp 1 chưa duyệt. Vui lòng báo duyệt sau khi cấp 1 đã duyệt");
                return;
            }
        }
        if (approveReview(user, employeeReview, levelReview)) {
            alert("Duyệt thành công");
            setOpen(false);
            search(employeeReview.EmployeeID + "");
        }
    }

    const requestUnLockApproved = (empReview) => {
        if (unLockAroveReview(empReview, levelReview)) {
            alert("Mở khoá duyệt thành công");
            // console.log(msnv);
            if (setOpen) {
                setOpen(false);
            }
            search(empReview.EmployeeID + "");
        }
    }

    return <div className="py-1">
        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Trang chủ  </h1>
        </div> */}
        <div className="flex flex-col space-y-4 max-w-7xl mx-auto  min-w-full w-full">
            {/* Replace with your content */}
            {!employeeData && <div className="bg-white shadow sm:rounded-lg ">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Chọn thông tin nhân viên</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Đánh giá nhanh theo vị trí</p>
                        <span className="relative z-0 inline-flex shadow-sm rounded-md">
                            <button
                                type="button" onClick={(event) => selectType("first")}
                                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Nhân viên đầu
                            </button>
                            {/* <button
                                type="button"
                                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Months
                            </button> */}
                            <button
                                type="button" onClick={(event) => selectType("last")}
                                className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Nhân viên cuối
                            </button>
                        </span>
                        <span className="relative z-0 inline-flex shadow-sm rounded-md px-4">
                            <button disabled={!msnv}
                                type="button" onClick={(event) => selectType("prev")}
                                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Trước
                            </button>
                            {/* <button
                                type="button"
                                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Months
                            </button> */}
                            <button disabled={!msnv}
                                type="button" onClick={(event) => selectType("next")}
                                className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                Tiếp theo
                            </button>
                        </span>
                        <span className="absolute mt-2 z-0 inline-flex shadow-sm px-4">
                            <SwitchLabel enabled={reviewStatus} setEnabled={setReviewStatus} LabelCheck={"Đã duyệt"} LabelUnCheck={"Chưa duyệt"} />
                        </span>
                    </div>
                    {/* <p>Chọn theo vị trí</p> */}
                    <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSearch}>

                        {/* <div className="w-full sm:max-w-xs pr-4">
                            <label htmlFor="email" className="sr-only">
                                Tháng
                            </label>
                            <select
                                id="location"
                                name="location"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                value={month.value + ""}
                                onChange={changeMonth}
                            >
                                {getMonths().map(m => {
                                    return <option key={m.value} value={m.value}>
                                        {m.label}
                                    </option>
                                })}
                            </select>
                        </div> */}
                        {/* <div className="w-full sm:max-w-xs pr-4">
                            <label htmlFor="email" className="sr-only">
                                Năm
                            </label>
                            <select
                                id="location"
                                name="location"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md "
                                value={year.value}
                                onChange={changeYear}
                            >
                                {getYears().map(y => { return <option value={y.value} key={y.value}>{y.label}</option> })}
                            </select>
                        </div> */}
                        <div className="w-full sm:max-w-xs">
                            <label htmlFor="email" className="sr-only">
                                Mã nhân viên
                            </label>
                            <input
                                type="text"
                                name="msnv"
                                id="msvn"
                                value={msnv}
                                onChange={changeMSNV}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Mã nhân viên"
                            />
                        </div>
                        <button
                            type="submit"
                            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Tìm
                        </button>
                    </form>
                </div>
            </div>
            }

            {employee && !employee.EmployeeID && <NoData message={"Không tìm thấy thông tin nhân viên. Vui lòng kiểm tra lại."} />
            }
            {!employeeData && employee && employee.EmployeeID && employeeReview && employeeReview.uid && <EmployeeShortInfo employee={employee} teams={getTeams()} />}
            {employee && employee.EmployeeID && employeeReview && employeeReview.uid &&
                <ReviewDetail setOpen={setOpen}
                    updateReview={requestUpdateReview}
                    approveReview={doApproveReview}
                    reviewLevel={levelReview} unLockApproved={requestUnLockApproved}
                    hpUser={user.hpUser} employeeReview={employeeReview}
                    defines={getDefines(reviewDefines, employee.TeamID)}
                    employee={employee} teams={getTeams()}
                    month={month} year={year} />}
            {/* /End replace */}
        </div>
    </div>
};
export default ReviewOne;