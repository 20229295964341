export const firebaseConfig = {
  apiKey: "AIzaSyA1BMbQowASM0UYG-DsBuqansh9j7eb57k",
  authDomain: "acbh-acbd.firebaseapp.com",
  projectId: "acbh-acbd",
  storageBucket: "acbh-acbd.appspot.com",
  messagingSenderId: "1087279037174",
  appId: "1:1087279037174:web:27a2ece0e468fccc89cad7",
  measurementId: "G-TMLRQ3C8N9",
};

export const appCheckConfig = "6Lcx2rYhAAAAABFFpHUtX8u6kxYO8YeCYdVwjB5f";
