import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../helpers";


const tabs = [
    // {
    //     name: 'Đánh giá',
    //     href: '/app/configs/general-config/general',
    // },
    {
        name: 'Đánh giá',
        href: '/app/configs/general-config/review',
    }
]

export default function MainConfig() {

    const navigate = useNavigate();
    const location = useLocation();

    const changeTab = (event) => {
        navigate(event.target.value);
    }
    const getCurrentTab = () => {
        let tab = tabs.find((tab) => tab.current);
        if (tab) return tab.name;
        return "";
    }

    return <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            <h1 className="text-2xl font-semibold text-gray-900">Cấu hình chung</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
            {/* Replace with your content */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={changeTab}
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        defaultValue={getCurrentTab}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name} value={tab.href}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200 ">
                        <nav className="-mb-px flex space-x-8 " aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        location.pathname.includes(tab.href)
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>

            </div>
            {/* /End replace */}
        </div>
        {/* <ReviewDefine /> */}
        <Outlet />
    </div>
}