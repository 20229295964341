import { query } from "firebase/database";
import { collection, getDocs, where } from "firebase/firestore";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { unLockAroveReviewsAdmin } from "../../../app/services";
import { checkPageRole, checkUserRoleLoad, getFullMonth, getMonthAddMonth, getYearAddMonth } from "../../../helpers";
import { analytics, db } from "../../../services/firebase";
import { logEvent } from "firebase/analytics";

export const UserApproved = ({ userInfo }) => {
    const [copyL2, setCopyL2] = useState(false);

    const user = useSelector((state) => state.user);
    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["quantri", "nhansu"]);
        // console.log(appConfigs);
    }, [user]);

    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));
    const [empReviews, setEmpReviews] = useState([]);

    const appConfigs = useSelector(state => state.config).appConfigs;

    const calledOnce = useRef(false);
    const isDoneSearch = useRef(false);
    const [levelRv, setLevelRv] = useState(0);

    useEffect(() => {
        if (!appConfigs || !appConfigs.Review || !appConfigs.Review.ApprovedLimitStart) return;

        if (calledOnce.current) return;
        calledOnce.current = true;

        // loadConfig();
    }, [appConfigs]);

    useEffect(() => {
        if (isDoneSearch.current) {
            isDoneSearch.current = false;
            // console.log(empReviews.length);
            doRequestUnlock();
        }
    }, [empReviews]);


    const unlock = async (level) => {
        setEmpReviews([]);
        setLevelRv(level);
        isDoneSearch.current = false;

        logEvent(analytics, 'unlock-click', {
            level: level,
            user: user.email,
            info: userInfo.FirstName + ' ' + userInfo.LastName + ' ' + userInfo.EmployeeID,
        });

        if (showUnlock() === false) {
            alert("Quá ngày mở duyệt. Vui lòng kiểm tra lại");
            return;
        }
        let msg = "Bạn muốn mở duyệt tất cả nhân viên cấp " + level + " của [" + userInfo.FirstName + " " + userInfo.LastName + "]?";
        if (level === 1) {
            msg = `Mở duyệt cấp 1 sẽ mở duyệt tất cả nhân viên cấp 2 của [${userInfo.FirstName} ${userInfo.LastName}].` + "\n" +
                `Bạn có chắc chắn muốn mở duyệt?`;
        }
        if (level === 2) {
            // alert('ok ' + copyPointL2 + ' ' + copyNoteL2);
            if (copyL2) {
                msg += "\n" + "Lưu ý: điểm và ghi chú sẽ được copy và chép đè từ cấp 1 sang cấp 2";
            }

        }

        if (window.confirm(msg)) {
            handleSearchEmp(level);

        }
    }

    const showUnlock = () => {
        if (appConfigs.Review.ApprovedLimitUnLock < (moment(new Date()).date())) return false;

        return true;
    }

    const handleSearchEmp = async (level) => {
        try {
            // const q = query(collection(db, "hp-employee"), where("uid", "==", user?.uid));
            let q = query(collection(db, "hp-employee"), where("Level" + level, "==", userInfo.EmployeeID));
            q = query(q, where("Status", "==", 1));

            const docs = await getDocs(q);

            let count = 0;
            // console.log(docs.size);
            docs.forEach((doc) => {
                count++;
                handleSearchReview(doc.data().EmployeeID, level, count >= docs.size);
                // console.log(doc.id, doc.data());

                // if (count >= docs.size) {
                //     alert("Mở duyệt thành công");
                // }
            });
            // const data = doc.docs[0].data();
            // setName(data.name);
        } catch (err) {
            console.error(err);
            alert("Có lỗi trong quá trình xử lý. Vui lòng thử lại hoặc liên hệ quản trị.");
        }
    }

    const handleSearchReview = async (EmployeeID, level, isEnd) => {
        try {
            // console.log(EmployeeID, level, isEnd);
            const q = query(collection(db, "hp-review"),
                where("EmployeeID", "==", EmployeeID),
                // where(`Level${level}ApprovedLock`, "==", true),
                where("FullMonth", "==", getFullMonth(month, year))
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                let empReview = Object.assign({ uid: doc.id }, doc.data());
                // unLockAroveReviewAdmin(empReview, level);
                // console.log(empReviews.length, doc.id);
                if (isEnd) {
                    // console.log("end", empReviews.length);
                    // isDoneSearch.current = true;
                    isDoneSearch.current = true;
                    // doRequestUnlock();
                }
                setEmpReviews((empReviews) => [...empReviews, empReview]);
                // requestUnLock(empReview, level)
            });
            if (querySnapshot.size === 0 && isEnd) {
                // console.log("end", 0, empReviews.length);
                // isDoneSearch.current = true;
                // doRequestUnlock();
                isDoneSearch.current = true;
            }

        } catch (err) {
            console.error(err);
        }
    }

    const doRequestUnlock = () => {
        // isDoneSearch.current = true;
        let empList = empReviews.map(m => m.EmployeeID).join(",");
        // if empList.length > 100, trim to 100 end with ...
        if (empList.length > 100) {
            empList = empList.substring(0, 100) + "...";
        }

        console.log("unlock", empReviews.length, empList);
        if (window.confirm("Mở duyệt cho: " + empReviews.length + " nhân viên. Mã nhân viên [" + empList + "]?")) {
            if (levelRv === 1) {
                unLockAroveReviewsAdmin(empReviews, levelRv, false, false);
            }
            if (levelRv === 2) {
                unLockAroveReviewsAdmin(empReviews, levelRv, false, setCopyL2);
            }
            alert("Mở duyệt thành công cấp [" + levelRv + "] nhân viên [" + userInfo.FirstName + " " + userInfo.LastName + "]");
        }
    }

    return <>
        <div className="mt-4 divide-y divide-gray-200">
            <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Lưu ý:</h3>
                <div className="max-w-2xl text-sm text-gray-500">
                    <ul>
                        <li>- Khi mở duyệt: sẽ mở cho tất cả nhân viên của {userInfo.FirstName + " " + userInfo.LastName}</li>
                        <li>- Ngày mở duyệt tối đa: <b>{appConfigs.Review.ApprovedLimitUnLock}</b></li>
                    </ul>
                </div>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Mở duyệt cấp 1</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                {showUnlock() && <button
                                    type="button" onClick={() => unlock(1)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Mở duyệt
                                </button>}
                            </span>
                        </dd>
                    </div>
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:pt-2">
                        <dt className="text-sm font-medium text-gray-500 py-4">Mở duyệt cấp 2</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                <div className="relative flex items-start mt-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                            checked={copyL2}
                                            onChange={() => setCopyL2(!copyL2)}
                                            aria-describedby="copy-note-description"
                                            name="copy-note"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="copy-note" className="font-medium text-gray-900">
                                            Copy điểm và ghi chú
                                        </label>
                                    </div>
                                </div>
                            </span>
                            <span className="ml-4 py-4 flex flex-shrink-0 items-start space-x-4">
                                {showUnlock() && <button
                                    type="button" onClick={() => unlock(2)}
                                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                >
                                    Mở duyệt
                                </button>}
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </>;
}
