import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    teams: []
};

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        setTeams: (state, action) => {
            state.teams = action.payload.teams;
        },
        addTeam: (state, action) => {
            // console.log(action.payload.team);
            if (state.teams.find(f => f.uid == action.payload.team.uid) == null) {
                state.teams.push(action.payload.team);
            }
        }
    },
});

export const { setTeams, addTeam } = teamSlice.actions;


export default teamSlice.reducer;