import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom"
import { checkPageRole, checkUserRoleLoad, classNames, getFullMonth, getMonthAddMonth, getYearAddMonth, hasRole } from "../../helpers"

import { matchRoutes, useLocation } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../services/firebase";
import { useSelector } from "react-redux";
import FirebaseService from "../../services/FirebaseService";

import moment from 'moment';

export default function MainReview() {
    const location = useLocation();
    const params = useParams();
    // const [user, loading, error] = useAuthState(auth);

    const appConfigs = useSelector(state => state.config).appConfigs;

    const user = useSelector((state) => state.user);
    const [month, setMonth] = useState(getMonthAddMonth(-1));
    const [year, setYear] = useState(getYearAddMonth(-1));

    useEffect(() => {
        if (checkUserRoleLoad(user)) return;
        // console.log(user, user.hpUser);
        checkPageRole(user.hpUser, ["level1", "level2"]);
        // console.log(appConfigs);
    }, [user, appConfigs]);

    const getTabs = () => {
        return [
            // { name: 'Từng nhân viên', href: '/app/reviews/one/' + params.level, current: location.pathname.includes("/app/reviews/one") },
            { name: 'Nhiều nhân viên', href: '/app/reviews/multi/' + params.level, current: location.pathname.includes("/app/reviews/multi") },
            { name: 'Duyệt', href: '/app/reviews/approved/' + params.level, current: location.pathname.includes("/app/reviews/approved") },
        ]
    }

    const navigate = useNavigate();

    const changeTab = (event) => {
        navigate(event.target.value);
    }

    return (
        <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8  min-w-full w-full">
                <div className="sm:flex sm:items-center py-4">
                    {/* <h1 className="text-2xl font-semibold text-gray-900">Đánh giá nhân viên cấp {params.level}</h1> */}

                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Đánh giá nhân viên cấp {params.level}. {`Tháng ${month.value}-${year.value}`}</h1>
                        {params.level == 1 && <p className="mt-2 text-sm text-gray-700">
                            Cấp 1 đánh giá từ ngày 1 đến ngày 5.
                        </p>}
                        {params.level == 2  && appConfigs && appConfigs.Review && <p className="mt-2 text-sm text-gray-700">
                            Cấp 2 đánh giá từ ngày 6 đến ngày 9, có thể mở duyệt để đánh giá lại đến ngày {appConfigs.Review.ApprovedLimitEdit}
                        </p>}
                    </div>
                    {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {hasRole(["level1", "level2"], user.hpUser.Role) && <button
                            onClick={ApprovedAll}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Duyệt tất cả
                        </button>
                        }
                    </div> */}
                </div>
                <div className="max-w-7xl mx-auto  min-w-full w-full">
                    {/* <div> */}
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            onChange={changeTab}
                            className="block w-full pl-3 pr-10 py-2 text-base 
                                border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue={getTabs().find((tab) => tab.current).name}
                        >
                            {getTabs().map((tab) => (
                                <option key={tab.name} value={tab.href}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {getTabs().map((tab) => (
                                    <a
                                        key={tab.name}
                                        href={tab.href}
                                        className={classNames(
                                            tab.current
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={tab.current ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <ReviewDefine /> */}
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
